.images-list {
    list-style: none;
    margin-bottom: 0;

    .image-list-item {
        border: 1px solid $borderLightColor;
        border-radius: $defaultBorderRadius;
        overflow: hidden;
        transition: border 0.3s ease;

        &:hover {
            border-color: $borderHoverColor;
        }

        > .gallery-image {
            background-color: $accentedBackgroundColor;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: $defaultBorderRadius;
            cursor: pointer;
            display: block;
            height: 100%;
            outline: none;
            overflow: hidden;
            padding: 0;
            text-align: center;
            width: 100%;
        }
    }
}
