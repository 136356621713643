.not-available-label {
    align-items: center;
    background-color: $accentedBackgroundColor;
    border-radius: $largeBorderRadius;
    color: $darkTextColor;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-height: 435px;
    min-height: 150px;
    padding: $defaultMargin;
    width: 100%;

    .title {
        font-size: $h2SmallScreenFontSize;
    }

    &.preparing {
        margin-top: $defaultMargin;
    }

    &.loading {
        height: 2rem;
        width: 2rem;
    }
}
