.terms-and-conditions {
    position: relative;

    .valid-title {
        border-bottom: 1px solid $borderLightColor;
        margin-bottom: $defaultMargin * 2;
        padding-bottom: $defaultMargin * 0.5;
    }

    &__section {
        margin-left: $defaultMargin;

        &:first-of-type {
            margin-bottom: $defaultMargin * 5;
        }
    }

    .car-rent-terms-conditions {
        margin-top: $defaultMargin * 2;
    }

    .terms-list {
        list-style: none;
    }

    .company-identification {
        margin-bottom: $defaultMargin;

        .company-name {
            color: $brandColor;
        }
    }

    .terms-section-heading {
        color: $brandColor;
        font-size: $biggerFontSize;
        margin-bottom: $defaultMargin * 0.5;
    }

    .terms-section {
        &:not(:last-of-type) {
            margin-bottom: $defaultMargin;

            ul {
                list-style: outside square;
            }
        }
    }

    ol {
        &.terms-section {
            counter-increment: section-counter;
            list-style-type: none;

            > li {
                counter-increment: step-counter;
                margin-left: $defaultMargin * 1.5;

                &::before {
                    content: counters(section-counter, ".") "." counters(step-counter, ".") ".";
                    font-weight: $boldFontWeight;
                    left: 0;
                    position: absolute;
                }
            }
        }

        &.alphabetically-ordered-list {
            list-style: lower-alpha outside;
        }
    }
}
