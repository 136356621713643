@use "sass:math";

.catalog-subscription-context-banner {
    background-color: $defaultBackgroundColor;
    border-radius: $defaultBorderRadius;
    margin-bottom: $smallScreenMargin;
    width: 100%;
    @include own-box-shadow(5px, 10px);
    @include hover-box-border;

    .column {
        padding: $defaultMargin;
        position: relative;

        &:nth-of-type(2) {
            border-top: 1px dashed $borderLightColor;
        }

        .main-title {
            margin-bottom: $defaultMargin;
            text-align: center;
        }

        .title {
            margin-bottom: $defaultMargin;
        }

        .label-icon {
            background-color: $iconBaseColor;
            border-radius: $defaultBorderRadius * 0.5;
            color: $inverseTextColor;
            font-size: $smallFontSize;
            left: 50%;
            padding: math.div($defaultMargin, 6) $defaultMargin * 0.5;
            position: absolute;
            text-transform: uppercase;
            top: -$defaultMargin * 0.5;
            transform: translateX(-50%);

            &::before {
                background: url("/assets/icon/happy.svg") 50% 50% no-repeat transparent;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 1.6rem;
                margin-right: math.div($defaultMargin, 6);
                margin-top: -0.2rem;
                vertical-align: middle;
                width: 1.6rem;
            }
        }

        .list {
            list-style: none;
            margin-bottom: 0;
        }

        .list-item {
            padding-left: $defaultMargin;

            &.yes {
                &::before {
                    @include yes-check(-$defaultMargin, 0.3rem, 1.2rem);
                }
            }

            &.plus {
                color: $checklistFontColor;
                font-weight: $boldFontWeight;
                padding-left: 0;
            }
        }

        .button-container {
            margin-top: $defaultMargin * 0.75;

            .button {
                width: 100%;
            }
        }

        .main-image-container {
            border-radius: $defaultBorderRadius;
            display: block;
            height: 180px;
            overflow: hidden;
            position: relative;
            width: 100%;
            @include hover-box-border;
        }

        .image-content {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include box-absolute-gradient;

            padding-bottom: $defaultMargin * 0.25;

            .sale-old-price {
                color: $inverseTextColor;
            }

            .car-title {
                color: $inverseTextColor;
                font-size: $offersListTitleFontSize;
                @include text-overflow-ellipsis;
            }

            .subtitle {
                color: $lighterTextColor;
                display: block;
                font-size: $offersListSubtitleFontSize;
                font-weight: $defaultFontWeight;
                @include text-overflow-ellipsis;
            }

            .price {
                color: $inverseTextColor;
                font-weight: $boldFontWeight;
                margin-bottom: math.div($defaultMargin, 3);
                margin-left: $defaultMargin * 0.5;
            }
        }

        .car-image-gallery {
            .car-gallery-images {
                list-style: none;
                margin-bottom: 0;
            }

            .image-list-item {
                background-position: 50%;
                background-size: cover;
                border-radius: $defaultBorderRadius;
                display: block;
                @include hover-box-border;
            }
        }
    }
}

@include mobile-tablet {
    .catalog-subscription-context-banner {
        .column {
            .main-image-container {
                margin-bottom: math.div($defaultMargin, 3);
            }

            .car-image-gallery {
                .car-gallery-images {
                    display: flex;
                }

                .image-list-item {
                    height: 60px;
                    @include boxes-per-row(3, 2%);
                }
            }
        }
    }
}

@include tablet {
    .catalog-subscription-context-banner {
        .column {
            .main-image-container {
                height: 280px;
            }

            .car-image-gallery {
                .image-list-item {
                    height: 130px;
                }
            }
        }
    }
}

@include desktop {
    .catalog-subscription-context-banner {
        margin-bottom: $largeScreenMargin;

        .columns {
            display: flex;
        }

        .column {
            &:nth-of-type(1) {
                width: 55%;
            }

            &:nth-of-type(2) {
                border-left: 1px dashed $borderLightColor;
                border-top: 0;
                width: 45%;
            }

            .main-image-container {
                height: 260px;
            }

            .car-image-gallery {
                display: flex;

                .main-image-container {
                    width: 73%;
                }

                .car-gallery-images {
                    margin-left: 2%;
                    width: 25%;
                }

                .image-list-item {
                    height: 80px;
                }
            }

            .image-content {
                .price {
                    font-size: $offersListTitleFontSize;
                }
            }

            .button-container {
                .button {
                    width: auto;
                }
            }
        }
    }
}

@include desktop-large {
    .catalog-subscription-context-banner {
        .column {
            padding: $defaultMargin * 2 $defaultMargin * 2 $defaultMargin * 1.5;
        }
    }
}
