.make-detail-header-container {
    background-color: $accentedBackgroundColor;

    &.model-line-detail {
        background-color: $defaultBackgroundColor;
    }

    .make-detail-header {
        align-items: center;
        display: flex;
        margin-bottom: $defaultMargin;
        margin-top: $defaultMargin;

        .make-logo {
            background-color: $defaultBackgroundColor;
            border: 1px solid $borderLightColor;
            border-radius: 50%;
            display: none;
            margin-right: $defaultMargin;
            padding: $defaultMargin;
        }

        .make-name {
            margin-bottom: $defaultMargin * 0.5;
        }

        .model-title {
            .car-subtitle {
                font-size: $h4SmallScreenFontSize;
            }
        }
    }
}

@include tablet-desktop {
    .make-detail-header-container {
        .make-detail-header {
            align-items: center;
            display: flex;

            .make-logo {
                display: block;
            }
        }
    }
}
