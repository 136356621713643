@use "sass:math";

.inquiry-page-content {
    margin: 0 auto $defaultMargin;
    max-width: 500px;

    .sale-price-row {
        margin-bottom: $defaultMargin * 0.5;
        text-align: center;
    }

    .list {
        font-size: $smallScreenFontSize;
        list-style: none;
        @include check-ok-list-with-border($defaultMargin * 0.25);
    }

    #checkout-title {
        line-height: 1.2;
        text-align: center;
    }

    #inquiry-form-box {
        .lessee-type-switcher {
            align-items: center;
            border-bottom: 2px solid $borderLightColor;
            margin-bottom: $defaultMargin * 0.5;
            padding: 0;
            @include flex-grid;

            label {
                &.control-label {
                    margin-right: $defaultMargin * 0.5;
                }

                &.required {
                    &:not(.control-label) {
                        cursor: pointer;
                        padding: $defaultMargin * 0.5 0 math.div($defaultMargin, 3);
                        position: relative;

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .radio {
                &:not(:last-of-type) {
                    margin-right: $defaultMargin;
                }
            }

            input {
                display: none;

                &:checked + span {
                    color: $brandColor;
                    display: inline-block;
                    font-weight: $boldFontWeight;

                    &::after {
                        background-color: $brandColor;
                        content: "";
                        display: inline-block;
                        height: 2px;
                        left: 0;
                        position: absolute;
                        top: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .terms-agreement {
            padding-top: $defaultMargin;
            text-align: center;

            .privacy-policy {
                font-size: $smallFontSize;
                font-style: italic;
                margin-top: math.div($defaultMargin, 6);
            }
        }

        .show-more {
            display: block;
            margin: 0 auto;
        }
    }

    .conditions-info {
        font-size: $smallScreenFontSize;
        text-align: center;
    }
}

@media (min-width: 321px) {
    .inquiry-page-content {
        .list {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }
}

@media (max-width: 599px) {
    .inquiry-page-content {
        #checkout-title {
            font-size: $inquiryHeadlineSmallerFontSize;
        }
    }
}

@include tablet-desktop {
    .inquiry-page-content {
        #checkout-title {
            font-size: $inquiryHeadlineFontSize;
        }

        .list {
            font-size: $defaultFontSize;
        }

        #inquiry-form-box {
            .lessee-type-switcher {
                label {
                    &.control-label {
                        margin-right: $defaultMargin;
                    }
                }

                & > div {
                    border: 0;
                    padding: 0;
                }
            }
        }
    }
}

@include desktop {
    #inquiry-how-it-works-modal {
        .modal-content {
            bottom: auto;
            max-height: 85vh;
        }
    }

    .inquiry-page-content {
        margin: 0 auto $defaultMargin * 2;

        .list {
            margin-left: -$smallScreenMargin;
            margin-right: -$smallScreenMargin;
        }
    }
}

@media (min-width: 1367px) {
    .inquiry-page-content {
        max-width: 540px;
    }
}

@media (min-width: 1921px) {
    .inquiry-page-content {
        max-width: 570px;
    }
}

// Retina, WXGA, SXGA displays
@media screen
and (min-device-width: $desktopLargeBreakpoint)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) {
    .inquiry-page-content {
        #checkout-title {
            font-size: 2.5rem;
            margin-bottom: $defaultMargin * 0.5;
        }

        .form-row,
        .form-group {
            margin-bottom: $defaultMargin * 0.25;
        }
    }
}
