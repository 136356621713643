@use "sass:math";

.table {
    background: $defaultBackgroundColor;
    border-collapse: collapse;
    border-radius: $defaultBorderRadius;
    margin-bottom: $defaultMargin;
    overflow: hidden;
    position: relative;
    table-layout: auto;
    width: 100%;

    td,
    th {
        padding: math.div($defaultMargin, 3) $defaultMargin;
        text-align: left;
    }

    thead {
        tr {
            background: $inquiryLeftColumnBackgroundColor;
            color: $inverseTextColor;
            height: $defaultMargin * 2.5;
        }

        th {
            font-weight: $defaultFontWeight;
        }
    }

    tbody {
        tr {
            height: auto;
            padding: math.div($defaultMargin, 3) 0;

            &:nth-child(odd) {
                background: $accentedBackgroundColor;
            }
        }
    }
}

@include desktop {
    .table {
        table-layout: fixed;

        td,
        th {
            padding: $defaultMargin * 0.5 $defaultMargin;
        }
    }
}
