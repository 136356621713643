#document-preview {
    img {
        max-height: 50vh;
        max-width: 50vw;
    }
}

.document-upload-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $defaultMargin;
}
