.inquiry-flow-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $defaultMargin;
    min-height: $defaultMargin * 2;
    padding-top: $defaultMargin * 0.5;
    position: relative;

    .note {
        display: none;
        font-size: $smallFontSize;
        margin-left: auto;
        margin-right: $defaultMargin * 0.5;
    }

    #how-it-works-modal-open {
        margin-left: auto;
        text-align: right;
    }

    .header-button {
        &.login-button {
            position: relative;

            &::before {
                background: url("/assets/icon/user.svg") 0 50% transparent no-repeat;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 1.4rem;
                margin-right: $defaultMargin * 0.25;
                margin-top: -0.3rem;
                vertical-align: middle;
                width: 1.4rem;
            }
        }

        &.back-button {
            &::before {
                background: url("/assets/icon/right-arrow-gray.svg") 0 50% transparent no-repeat;
                background-size: 85%;
                content: " ";
                display: inline-block;
                height: 1.4rem;
                margin-right: $defaultMargin * 0.25;
                margin-top: -0.3rem;
                transform: rotate(180deg);
                vertical-align: middle;
                width: 1.4rem;
            }
        }

        .hidden-text-on-mobile {
            display: none;
        }
    }
}

@include tablet-desktop {
    .inquiry-flow-header {
        padding-top: $defaultMargin;

        .note {
            display: inline-block;
            font-size: $defaultFontSize;
        }

        .header-button {
            .hidden-text-on-mobile {
                display: inline;
            }
        }
    }
}

// Retina, WXGA, SXGA displays
@media screen
and (min-device-width: $desktopLargeBreakpoint)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) {
    .inquiry-flow-header {
        margin-bottom: $defaultMargin;
    }
}

@media (min-width: 1921px) {
    .inquiry-flow-header {
        margin-bottom: $defaultMargin * 2;
    }
}
