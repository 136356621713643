.model-detail-gallery {
    .image-list-item {
        &:hover {
            .img-inner {
                transform: scale(1.03);
                transform-origin: center;
            }
        }
    }

    .primary-box {
        .images-list {
            height: 100%;
            margin-bottom: 0;

            .image-list-item {
                height: 100%;
                margin-bottom: 0;
                overflow: hidden;

                .img-inner {
                    max-height: 430px;
                }
            }
        }
    }

    .secondary-box {
        .gallery-link {
            color: $brandColor;

            &::before {
                background: url("/assets/icon/photoCamera.svg") 0 50% no-repeat transparent;
                background-size: 100%;
                content: " ";
                float: left;
                height: 20px;
                margin-right: $defaultMargin * 0.25;
                width: 20px;
            }
        }

        .images-list {
            @include flex-grid;

            .image-list-item {
                height: 70px;
                overflow: hidden;
            }

            .video {
                @include video-play-icon;

                min-height: auto;

                @include mobile {
                    &::before {
                        height: 4rem;
                        width: 4rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .model-detail-gallery {
        .secondary-box {
            .images-list {
                .image-list-item {
                    @include boxes-per-row(3, 2%);
                }
            }
        }
    }
}

@media (min-width: 600px) and (max-width: 1199px) {
    .model-detail-gallery {
        .secondary-box {
            .images-list {
                @include flex-grid;

                .image-list-item {
                    height: 120px;
                }
            }
        }
    }
}

@include desktop-large {
    .model-detail-gallery {
        .secondary-box {
            .gallery-link {
                align-self: center;
            }

            .images-list {
                .image-list-item {
                    &.main-item {
                        height: 160px;
                        width: 100%;
                    }

                    &:not(.main-item) {
                        width: 49%;
                    }

                    &:nth-child(3) {
                        margin-left: 2%;
                    }
                }
            }
        }

        &.catalog {
            .secondary-box {
                .images-list {
                    .image-list-item {
                        height: 120px;

                        &.main-item {
                            height: 180px;
                        }
                    }
                }
            }
        }
    }
}
