@use "sass:math";

.inquiry-left-column {
    background: $accentedBackgroundColor;
    display: none;
    height: 100%;
    min-height: 100vh;
    padding: $defaultMargin * 1.25 $defaultMargin $defaultMargin;

    .left-column-content {
        margin: 0 auto;
        max-width: 350px;
    }

    .favorite-left-column-content {
        margin: 0 auto;
        max-width: 400px;
    }

    .logo-container {
        margin-bottom: $defaultMargin;
        text-align: center;

        .logo {
            height: auto;
            max-width: 150px;
            width: 100%;
        }
    }

    .claim {
        text-align: center;
    }

    .car-placeholder-image {
        margin-bottom: $defaultMargin * 2;
        position: relative;

        img {
            height: auto;
            position: relative;
            width: 100%;
            z-index: 1;
        }

        .background-icon {
            svg {
                bottom: 0;
                fill: $brandColor;
                height: 140%;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
                z-index: 0;
            }
        }

        .favorite-icon {
            svg {
                bottom: 0;
                height: 55%;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
                z-index: 2;
            }
        }
    }

    .car-offer-info {
        border-radius: $largeBorderRadius;
        margin: 0 auto $defaultMargin;
        min-width: 270px;
        overflow: hidden;

        .car-image {
            height: 170px;
            position: relative;

            .img-inner {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }
        }

        .content {
            background: $defaultBackgroundColor;
            border: 1px solid $borderLightColor;
            border-radius: 0 0 $largeBorderRadius $largeBorderRadius;
            padding: $defaultMargin * 0.5;
            text-align: center;
        }

        .subtitle {
            font-size: $defaultFontSize;
            margin-bottom: $defaultMargin * 0.5;
        }

        .price-info-row {
            padding: $defaultMargin * 0.5 $defaultMargin;

            &.other {
                .value {
                    color: $darkTextColor;
                    font-size: $h3SmallScreenFontSize;
                    font-weight: $boldFontWeight;
                }
            }

            .price-title {
                color: $darkTextColor;
                margin-bottom: $defaultMargin * 0.25;
            }

            .value {
                &:first-child {
                    color: $darkTextColor;
                    font-size: $h3SmallScreenFontSize;
                    font-weight: $boldFontWeight;
                }

                .vat-info {
                    color: $defaultTextColor;
                    font-size: $smallScreenFontSize;
                    font-weight: $defaultFontWeight;
                    @include default-font-family;
                }
            }
        }

        .special-offer-description {
            font-size: $smallFontSize;
            margin-top: $defaultMargin * 0.5;
        }
    }

    .back-to-top {
        color: $inverseTextColor;
        cursor: pointer;
        font-weight: $boldFontWeight;
        margin-top: $defaultMargin;
        text-align: center;
        text-decoration: underline;

        &::after {
            background: url("/assets/icon/right-arrow-white.svg") 50% 50% no-repeat transparent;
            background-size: 100%;
            content: "";
            display: inline-block;
            height: 1.3rem;
            margin-left: $defaultMargin * 0.25;
            transform: rotate(-90deg);
            width: 1.3rem;
        }
    }
}

@media (max-width: 599px) {
    .inquiry-left-column {
        .car-offer-info {
            margin-bottom: $defaultMargin;
        }
    }
}

@include desktop {
    .inquiry-left-column {
        display: block;

        .car-offer-info {
            .car-image {
                height: 200px;
            }

            .content {
                padding: $defaultMargin;
            }
        }

        .back-to-top {
            display: none;
        }

        .car-placeholder-image {
            margin-bottom: $defaultMargin * 3;
            margin-top: $defaultMargin * 3;
        }
    }

    .starting-fee-payments-container {
        margin-top: $defaultMargin;
        text-align: center;
    }
}

// Retina, WXGA, SXGA displays
@media screen
and (min-device-width: $desktopLargeBreakpoint)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) {
    .inquiry-left-column {
        .logo-container {
            margin-bottom: $defaultMargin * 1.5;
        }

        .car-offer-info {
            margin-bottom: $defaultMargin;
        }
    }
}

@media (min-width: 1921px) {
    .inquiry-left-column {
        .logo-container {
            margin-bottom: $defaultMargin * 3.75;
        }
    }
}
