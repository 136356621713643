.tyre-services-page-content {
    .search-input {
        margin-bottom: $defaultMargin;
    }

    .table-container {
        overflow: auto;
        width: 100%;
    }
}
