.react-spinner {
    animation: 0.75s ease infinite spinner-border;
    border: 3px solid $brandColor;
    border-left-color: transparent;
    border-radius: 50%;
    display: inline-block;
    height: 5rem;
    vertical-align: -0.125em;
    width: 5rem;

    @keyframes spinner-border {
        to {
            transform: rotate(360deg);
        }
    }
}
