@use "sass:math";

.price-offers-comparator {
    margin-bottom: $smallScreenMargin;

    .sticky-table-header {
        background-color: $accentedBackgroundColor;
        border-bottom: 3px solid $borderLightColor;
        display: block;
        padding-left: 120px;
        position: fixed;
        top: 0;
        transform: translateY(-110%);
        transition: transform $defaultTransitionTime ease;
        width: 100%;
        will-change: transform;
        z-index: $headerZIndex - 2;

        > .inner {
            background-color: $defaultBackgroundColor;
            display: flex;
            overflow: hidden;
            width: inherit;
        }

        &.show {
            transform: translateY(0);
        }

        .column {
            border-left: 1px solid $borderLightColor;
            flex: none;
            padding: math.div($defaultMargin, 3) $defaultMargin * 0.5;
            text-align: center;
            width: 200px;

            &:last-child {
                border-right: 1px solid $borderLightColor;
            }

            .image {
                border-radius: $defaultBorderRadius;
                display: none;
                height: 50px;
                margin: 0 auto $defaultMargin * 0.25;
                object-fit: cover;
                object-position: center center;
                width: 100px;
            }

            .price {
                font-weight: $mediumFontWeight;
            }
        }
    }

    .table-container {
        margin-left: 120px;
        margin-top: $defaultMargin * 0.5;
        overflow-x: auto;

        .labels {
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
        }
    }

    .comparator-table-container {
        background-color: $accentedBackgroundColor;
        padding-bottom: $defaultMargin * 2.25;
        position: relative;
        width: 100%;

        & + .no-content {
            display: none;
        }

        .car-offer-info-box {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100%;
        }

        .car-image {
            display: block;
            height: 115px;
            margin: $defaultMargin * 0.5 auto;
            max-width: 230px;
            position: relative;
            width: 100%;
        }

        .img-inner {
            border-radius: $defaultBorderRadius;
        }

        .price-offer-price {
            margin-bottom: $defaultMargin * 0.5;
        }

        .button-light {
            margin-bottom: $defaultMargin * 0.5;
        }

        .price-vat {
            .price {
                &:first-of-type {
                    color: $offersListPriceColor;
                    display: block;
                }

                &:nth-of-type(2) {
                    font-weight: $defaultFontWeight;
                }
            }
        }

        .car-offer-title {
            flex-grow: 1;
            margin-bottom: $defaultMargin * 0.5;
        }

        .subtitle {
            font-weight: $defaultFontWeight;
        }

        .yes-label {
            color: $yesColor;
            text-transform: uppercase;
        }

        .no-label {
            color: $noColor;
            text-transform: uppercase;
        }

        .comparator-remove-all-offers,
        .remove-offer {
            color: $brandColor;
            cursor: pointer;
            font-weight: $defaultFontWeight;
            text-decoration: underline;

            &::before {
                background: url("/assets/icon/cross-no.svg") 0 50% no-repeat transparent;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 1.5rem;
                margin-right: math.div($defaultMargin, 6);
                text-decoration: none;
                vertical-align: middle;
                width: 1.5rem;
            }
        }

        .car-offer-equipment-list {
            text-align: left;
            @include check-ok-list(1rem);
        }

        table {
            border-bottom: 1px solid $borderLightColor;
            border-collapse: collapse;
            border-left: 0;
            min-width: 400px;
            table-layout: fixed;
            width: 100%;

            .head-col {
                font-weight: $boldFontWeight;
                text-align: right;
            }

            .equipment-head-col {
                align-items: center;
                display: flex;
                font-size: $smallFontSize;
                height: 44px;
                justify-content: flex-end;
                line-height: 1.3;
                padding: math.div($defaultMargin, 3) $defaultMargin * 0.5;
                text-align: right;
            }
        }

        th {
            background-color: $defaultBackgroundColor;
            font-weight: $boldFontWeight;

            &.headline {
                align-items: center;
                background: transparent;
                display: flex;
                flex-direction: column;
                font-size: 1.8rem;
                @include default-headline-font-size;

                .comparator-remove-all-offers {
                    font-size: $defaultFontSize;
                    margin-bottom: $defaultMargin;
                }
            }
        }

        td {
            position: relative;
        }

        td,
        th {
            display: table-cell;
            padding: $defaultMargin * 0.5;
            vertical-align: top;
            width: 200px;
        }

        td + td,
        th + th {
            border-left: 1px solid $borderLightColor;
            border-right: 1px solid $borderLightColor;
            display: table-cell;
            height: 44px;
            text-align: center;
            width: 200px;
        }

        th + th {
            border-top: 1px solid $borderLightColor;
            padding: $defaultMargin * 0.5;
            vertical-align: bottom;
        }

        .separator {
            td {
                background-color: $defaultBackgroundColor;
                border: 0;
                white-space: nowrap;

                &::before {
                    border-top: 1px solid $borderLightColor;
                    content: "";
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: -1px;
                }

                &::after {
                    border-bottom: 1px solid $borderLightColor;
                    bottom: -1px;
                    content: "";
                    left: 0;
                    position: absolute;
                    right: 0;
                }
            }
        }

        tr {
            background-color: $defaultBackgroundColor;

            &:nth-child(even) {
                background-color: $accentedBackgroundColor;
            }

            td,
            th {
                &:first-of-type {
                    left: 0;
                    min-height: 44px;
                    position: absolute;
                    width: 120px;
                    z-index: 2;
                }
            }
        }

        .autospace {
            margin: 0;
            padding: 0 $defaultMargin * 0.5;
            vertical-align: middle;
            width: 100%;
        }
    }

    .arrow-navigation {
        bottom: $defaultMargin * 0.25;
        display: flex;
        left: 50%;
        position: fixed;
        transform: translateX(-50%);
        z-index: $popupZIndex;

        &:not(.fixed) {
            position: absolute;
        }

        .nav {
            align-items: center;
            background-color: $comparatorArrowBackground;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 40px;
            justify-content: center;
            transition: opacity $defaultTransitionTime, transform $defaultTransitionTime;
            vertical-align: middle;
            width: 40px;
            will-change: opacity, transform;

            &::after {
                background: url("/assets/icon/right-arrow-white.svg") 50% 50% no-repeat transparent;
                background-size: 100%;
                content: " ";
                display: inline-block;
                height: 1.3rem;
                width: 1.3rem;
            }

            &.disable {
                opacity: 0.3;
                pointer-events: none;
            }
        }

        .right {
            margin-left: $defaultMargin * 0.5;
        }

        .top {
            margin-left: $defaultMargin * 0.5;
            transform: rotate(-90deg);

            &.disable {
                opacity: 0;
            }
        }

        .left {
            transform: rotate(180deg);
        }
    }

    .comparator-more-offers {
        .listing-link {
            display: block;
            margin: 0 auto $defaultMargin * 0.5;
            text-decoration: none;
            width: fit-content;

            &::before {
                -webkit-mask-image: url("/assets/icon/plus.svg");
                background-color: $brandColor;
                content: " ";
                display: inline-block;
                height: 24px;
                mask-image: url("/assets/icon/plus.svg");
                transform: scale(1.5);
                width: 24px;
                @include own-transition(transform);
            }
        }
    }
}

@media (min-width: $smallestDeviceBreakpoint) {
    .price-offers-comparator {
        .sticky-table-header {
            padding-left: 150px;
        }

        .table-container {
            margin-left: 150px;
        }

        .comparator-table-container {
            th {
                &.headline {
                    @include default-headline-font-size;
                }
            }

            tr {
                td,
                th {
                    &:first-of-type {
                        width: 150px;
                    }
                }
            }
        }
    }
}

@include tablet-desktop {
    .price-offers-comparator {
        .sticky-table-header {
            padding-left: 200px;

            .column {
                width: 230px;

                .image {
                    display: block;
                }
            }
        }

        .table-container {
            margin-left: 200px;
        }

        .comparator-table-container {
            td + td,
            th + th {
                width: 230px;
            }

            tr {
                td,
                th {
                    &:first-of-type {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@include desktop {
    .price-offers-comparator {
        margin-bottom: $largeScreenMargin;

        .sticky-table-header {
            padding-left: 230px;
        }

        .table-container {
            margin-left: 230px;
            margin-top: 0;

            &.mouse-drag {
                cursor: move;
            }

            &.active {
                user-select: none;
            }
        }

        .comparator-table-container {
            padding-bottom: $defaultMargin * 3.5;

            tr {
                td,
                th {
                    &:first-of-type {
                        width: 230px;
                    }
                }
            }
        }

        .arrow-navigation {
            bottom: $defaultMargin * 0.75;

            &.fixed {
                bottom: $defaultMargin;
            }

            .nav {
                height: 50px;
                width: 50px;

                &::after {
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }

            .right {
                &:hover {
                    transform: scale(1.2);
                }
            }

            .top {
                &:hover {
                    transform: scale(1.2) rotate(-90deg);
                }
            }

            .left {
                &:hover {
                    transform: scale(1.2) rotate(180deg);
                }
            }
        }
    }
}
