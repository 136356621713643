@use "sass:math";

.base-model-list {
    @include flex-grid;

    .model,
    .model-line-item {
        margin-right: $defaultMargin;

        .box-link {
            color: $brandColor;
            display: inline-block;
            font-weight: $boldFontWeight;
            text-decoration: underline;

            .production-dates {
                font-weight: $defaultFontWeight;
                padding-left: math.div($defaultMargin, 6);
            }
        }
    }
}

@media (max-width: 480px) {
    .base-model-list,
    .model,
    .model-line-item {
        list-style-position: outside;
        margin-left: 1em;
        margin-right: 0;
    }
}
