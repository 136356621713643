.videos-list {
    list-style: none;
    margin-bottom: 0;

    .video-list-item {
        border-radius: $defaultBorderRadius;

        > .video-thumbnail {
            background-color: $accentedBackgroundColor;
            background-image: url("/assets/icon/img-placeholder.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: $defaultBorderRadius;
            cursor: pointer;
            display: block;
            height: 100%;
            outline: none;
            overflow: hidden;
            padding: 0;
            text-align: center;
            width: 100%;
            @include video-play-icon;

            > img {
                display: block;
                height: auto;
                max-height: 120px;
                visibility: hidden;
                width: 100%;
            }

            &[data-image] {
                background-size: contain;
            }
        }
    }
}
