[type="radio"] {
    &:checked + label,
    &:not(:checked) + label {
        cursor: pointer;
        display: inline;
        font-weight: $defaultFontWeight;
        line-height: $defaultLineHeight;
        padding-left: $defaultMargin;
        position: relative;

        &:not(:last-of-type) {
            padding-right: $defaultMargin * 0.5;
        }

        &::before {
            background-color: $defaultBackgroundColor;
            border: 1px solid $formInputBorderColor;
            border-radius: 50%;
            content: "";
            height: 18px;
            left: 0;
            position: absolute;
            top: 0;
            width: 18px;
        }

        &::after {
            background: $inverseTextColor;
            border-radius: 50%;
            content: "";
            height: 8px;
            left: 5px;
            position: absolute;
            top: 5px;
            width: 8px;
        }
    }

    &:checked,
    &:not(:checked) {
        left: -9999px;
        position: absolute;
    }

    &:not(:checked) + label {
        &::after {
            opacity: 0;
        }
    }

    &:checked + label {
        color: $brandColor;

        &::after {
            opacity: 1;
        }

        &::before {
            background-color: $brandColor !important;
            border-color: $brandColor !important;
        }
    }
}
