@use "sass:math";

.gdpr-cookies-policy-header {
    p {
        margin-bottom: 0;
        margin-top: $defaultMargin;
    }
}

.gdpr-cookies-policy-content {
    h2 {
        color: $brandColor;
        font-size: $h2SmallScreenFontSize;

        &:not(:first-child) {
            margin-top: $defaultMargin;
        }
    }

    h3 {
        font-size: $slightlyBiggerFontSizePx;
    }

    .button-primary {
        margin-bottom: $defaultMargin * 2;
    }

    label {
        display: inline;
    }

    .table-container {
        overflow: auto;
        width: 100%;

        .cookies-table {
            border: 1px solid $borderLightColor;
            border-collapse: collapse;
            margin: $defaultMargin * 0.5 0 $defaultMargin;
            width: 100%;

            th,
            td {
                border: 1px solid $borderLightColor;
                padding: math.div($defaultMargin, 6) math.div($defaultMargin, 3);

                &:last-of-type {
                    min-width: 300px;
                }
            }
        }
    }

    .browsers {
        list-style: square inside;

        li {
            margin-bottom: 0;
        }
    }

    .gdpr-cookies-policy-options {
        list-style: none outside;
        margin-bottom: 0;
        margin-top: $defaultMargin * 0.5;

        li {
            label {
                font-weight: $boldFontWeight !important;
                line-height: $defaultLineHeight;
            }
        }
    }
}
