.data-boxes-section {
    background-color: $accentedBackgroundColor;
    padding-bottom: $defaultMargin;
    padding-top: $smallScreenMargin;

    .car-data-box {
        border: 2px solid $borderLightColor;
        border-radius: $defaultBorderRadius;
        margin-bottom: 2%;
        padding: $defaultMargin * 1.5 $defaultMargin $defaultMargin;
        @include hover-box-border;
    }
}

@include desktop {
    .data-boxes-section {
        padding-bottom: $smallScreenMargin;
        padding-top: $largeScreenMargin;

        .car-data-box {
            float: left;
            margin-left: 2%;
            padding: 2%;
            width: 49%;

            &:first-child,
            &:nth-child(4) {
                clear: left;
                margin-left: 0;
            }
        }
    }
}
