@use "sass:math";

.dropdown-container {
    user-select: none;

    > .dropdown-current-item {
        background-color: $formInputBackgroundColor;
        border: 1px solid $formInputBorderColor;
        border-radius: $formInputBorderRadius * 2;
        font-size: $defaultFontSize;
        justify-content: space-between;
        line-height: 1.2;
        padding: math.div($defaultMargin, 3) 3rem math.div($defaultMargin, 3) $defaultMargin * 0.5;
        position: relative;
        text-align: left;
        width: 100%;
        @include text-overflow-ellipsis;

        .sub-item {
            color: $defaultTextColor;
            font-size: $smallScreenFontSize;
        }
    }

    > .dropdown-list {
        background: $defaultBackgroundColor;
        display: none;
        line-height: $defaultLineHeight;
        max-height: 50vh;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        @include own-box-shadow(3px, 10px, 0.3);

        .dropdown-item {
            align-items: center;
            border: solid 1px $borderLightColor;
            border-top: 0;
            cursor: pointer;
            display: flex;
            font-size: $defaultFontSize;
            margin: 0;
            padding: math.div($defaultMargin, 4) math.div($defaultMargin, 2);
            position: relative;
            width: 100%;
            @include own-transition(background-color);

            &.group-item {
                border: 0;
                display: block;
                padding: 0;

                &:hover {
                    background-color: $defaultBackgroundColor;
                }

                > .group-label {
                    background-color: $accentedBackgroundColor;
                    border: solid 1px $borderLightColor;
                    font-weight: $boldFontWeight;
                    padding: math.div($defaultMargin, 4) math.div($defaultMargin, 2);
                }

                > .group-list {
                    margin: 0;
                }
            }

            .sub-item {
                color: $defaultTextColor;
                font-size: $smallScreenFontSize;
                margin-left: auto;
            }

            .reset-filter-item {
                margin-left: auto;
                margin-top: -0.2rem;

                &::before {
                    background-color: transparent;
                    background-image: url("/assets/icon/close.svg");
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    content: " ";
                    display: inline-block;
                    height: 1.4rem;
                    vertical-align: middle;
                    width: 1.4rem;
                }
            }

            .price {
                color: $offersListPriceColor;
                font-size: $smallScreenFontSize;
                font-weight: $boldFontWeight;
                margin-left: auto;
            }

            .checkbox,
            .radiobox {
                align-content: center;
                color: $darkTextColor;
                display: flex;
                justify-content: center;
                position: relative;

                &::before {
                    background-color: $formInputBackgroundColor;
                    border: 1px solid $formInputBorderColor;
                    border-radius: $formInputBorderRadius;
                    content: "";
                    display: inline-block;
                    margin-right: $defaultMargin * 0.25;
                    padding: math.div($defaultMargin, 3);
                    position: relative;
                    vertical-align: middle;
                }
            }

            .radiobox {
                &::before {
                    border-radius: 50%;
                }
            }

            &:hover {
                background-color: $accentedBackgroundColor;
            }

            &.selected {
                color: $dropdownSelectColor;

                .price {
                    color: $dropdownColor;
                }

                .checkbox {
                    color: $brandColor;

                    &::after {
                        border: solid $inverseTextColor;
                        border-width: 0 2px 2px 0;
                        content: "";
                        display: block;
                        height: 12px;
                        left: 6px;
                        margin-top: -0.3rem;
                        position: absolute;
                        top: 4px;
                        transform: rotate(45deg);
                        width: 6px;
                    }

                    &::before {
                        background-color: $brandColor;
                        border-color: $brandColor;
                    }
                }

                .radiobox {
                    color: $brandColor;

                    &::after {
                        background: $inverseTextColor;
                        border-radius: 50%;
                        content: "";
                        height: 10px;
                        left: 4px;
                        position: absolute;
                        top: 4px;
                        width: 10px;
                    }

                    &::before {
                        background-color: $brandColor;
                        border-color: $brandColor;
                    }
                }
            }

            &.disabled {
                color: $lightTextColor;
                cursor: default;
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    &.open {
        position: relative;
        z-index: 30;

        > .dropdown-current-item {
            &:not(.empty) {
                border: 1px solid $formInputFocusBorderColor;
                @include own-transition(border);

                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        > .dropdown-list {
            display: block;
        }
    }

    &:not(.disabled) {
        > .dropdown-current-item {
            cursor: pointer;
            position: relative;

            &::after {
                background-image: url("/assets/icon/expand-arrow.svg");
                background-position: 100% 50%;
                background-repeat: no-repeat;
                background-size: 100%;
                bottom: 0;
                content: " ";
                margin: math.div($defaultMargin, 3);
                position: absolute;
                right: 0;
                top: 0;
                transform-origin: 55% 50%;
                width: $defaultMargin * 0.5;
            }
        }
    }

    &.disabled {
        color: $lightTextColor;
        cursor: default;
        pointer-events: none;

        > .dropdown-current-item {
            opacity: 0.5;
        }
    }
}
