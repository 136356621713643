.authentication-container {
    .conditions-info {
        font-size: $smallScreenFontSize;
        text-align: center;
    }

    .authentication-tabs {
        display: flex;
        margin: 0 auto;

        .tab {
            color: $darkTextColor;
            display: inline-block;
            font-size: $slightlyBiggerFontSizePx;
            padding: $defaultMargin * 0.75;
            text-align: center;
            text-decoration: none;
            @include boxes-per-row(2, 0%);
            @include own-transition(background-color);

            &:nth-of-type(1) {
                border-top-left-radius: $largeBorderRadius;
            }

            &:nth-of-type(2) {
                border-top-right-radius: $largeBorderRadius;
            }

            &:not(.active) {
                background-color: $borderLightColor;
                color: $darkTextColor;
                font-weight: $mediumFontWeight;
            }

            &.active {
                background-color: $defaultBackgroundColor;
                border: 2px solid $borderLightColor;
                border-bottom: 0;
                cursor: none;
                font-weight: $defaultFontWeight;
                pointer-events: none;
            }

            &:hover {
                background-color: $borderHoverColor;
            }
        }
    }

    .authentication-content {
        border: 2px solid $borderLightColor;
        border-radius: 0 0 $largeBorderRadius $largeBorderRadius;
        border-top: 0;
        margin-bottom: $defaultMargin;
        padding: $defaultMargin;

        .sale-price-row {
            margin-bottom: $defaultMargin * 0.5;
            text-align: center;
        }

        h1,
        .description {
            text-align: center;
        }

        h1 {
            font-size: $inquiryHeadlineFontSize;
        }

        .description {
            margin-bottom: $defaultMargin;
        }

        .flash-message {
            margin-bottom: $defaultMargin * 0.5;
        }
    }
}

@media (max-width: 599px) {
    .authentication-container {
        .authentication-content {
            h1 {
                font-size: $inquiryHeadlineSmallerFontSize;
            }
        }
    }
}

@include desktop {
    .authentication-container {
        .authentication-tabs {
            max-width: 600px;

            .tab {
                padding: $defaultMargin;
            }
        }

        .authentication-content {
            border-radius: 0 0 $largeBorderRadius * 3 $largeBorderRadius;
            margin: 0 auto $defaultMargin * 2;
            max-width: 600px;
            padding: $defaultMargin $defaultMargin * 2;
        }
    }
}
