.list-of-boxes {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
        border-radius: $defaultBorderRadius;
        height: auto;
        min-height: 55px;
        position: relative;
        text-align: center;
        width: 100%;
        @include hover-box-border;

        .box-link {
            align-items: center;
            color: $brandColor;
            display: flex;
            flex-direction: column;
            font-weight: $boldFontWeight;
            height: 100%;
            justify-content: center;
            overflow: hidden;
            padding: 8%;
            text-overflow: ellipsis;
        }

        .admin-buttons {
            position: absolute;
            right: 0;
            text-align: right;
            top: 0;

            .admin-button {
                display: block;
                font-size: $smallScreenFontSize;
                padding: 0 $defaultMargin * 0.25;
            }
        }
    }

    &::after {
        @include utility-clearfix;
    }

    &.images-list {
        .image-list-item {
            border: 0;

            .gallery-image {
                background-color: $accentedBackgroundColor;
            }
        }
    }
}

@include mobile {
    .list-of-boxes {
        li {
            @include boxes-per-row(2);
        }
    }
}

@include tablet {
    .list-of-boxes {
        li {
            @include boxes-per-row(3);
        }
    }
}

@media (min-width: $desktopBreakpoint) and (max-width: 1199px) {
    .list-of-boxes {
        li {
            @include boxes-per-row(4);
        }
    }
}

@include desktop-large {
    .list-of-boxes {
        &.images-list {
            li {
                @include boxes-per-row(4);
            }
        }

        &:not(.images-list) {
            li {
                @include boxes-per-row(6);
            }
        }
    }
}
