.password-reset-link-header {
    margin-top: $defaultMargin;

    .headline {
        @include bigger-default-headline-font-size;
    }

    .description {
        font-size: $slightlyBiggerFontSize;
    }
}

.password-reset-link-sent {
    .button-primary {
        margin-top: $defaultMargin * 0.5;
        @include button-with-right-arrow;
    }
}

@include desktop {
    .password-reset-link-header {
        margin-top: $smallScreenMargin;
    }

    #reset-password-form {
        .form-control {
            max-width: 400px;
        }
    }
}
