.no-content {
    border: 1px solid $brandColor;
    margin: $defaultMargin;
    padding: $defaultMargin;
    text-align: center;

    .no-content-message {
        font-size: $slightlyBiggerFontSize;
        margin-bottom: 0;
    }
}
