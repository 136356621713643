.model-list-container {
    background-color: $accentedBackgroundColor;
    margin-bottom: $smallScreenMargin;

    .past-models {
        padding-bottom: $defaultMargin;
        padding-top: $defaultMargin;
    }
}

@include desktop {
    .model-list-container {
        margin-bottom: $largeScreenMargin;

        .past-models {
            padding-bottom: $defaultMargin * 2;
            padding-top: $defaultMargin * 2;
        }
    }
}
