@use "sass:math";

.admin-header {
    background-color: $accentedBackgroundColor;

    .admin-leasing-info {
        margin-top: $defaultMargin * 0.5;

        .admin-leasing-info-box {
            display: flex;
            justify-content: space-between;
        }

        .subscription-car-list-filter {
            align-items: center;
            background-color: $defaultBackgroundColor;
            border-radius: $defaultBorderRadius;
            display: flex;
            margin-bottom: $defaultMargin;
            padding: $defaultMargin * 0.5;
            width: 100%;

            select {
                margin-left: $defaultMargin * 0.5;
                max-width: 200px;
            }
        }

        .subscription-car-list {
            overflow: hidden;
            @include flex-grid;

            .car-list-by-status {
                margin-left: 1rem;
                margin-top: 1rem;
                max-width: 300px;
                min-width: 250px;

                .list {
                    list-style: none;
                }

                .list-item {
                    font-size: 85%;
                    margin-bottom: 0;

                    & .location-driveto-showroom {
                        background-color: $lightGreenBackgroundColor;
                        border-radius: $defaultBorderRadius * 0.5;
                        padding-left: $defaultBorderRadius;
                        padding-right: $defaultBorderRadius;
                    }

                    & .reservation-label {
                        background-color: $lightOrangeLabelColor;
                        border-radius: $defaultBorderRadius * 0.5;
                        padding-left: $defaultBorderRadius;
                        padding-right: $defaultBorderRadius;
                    }
                }

                .admin-carlist-button {
                    border-radius: $defaultBorderRadius;
                    color: $defaultTextColor;
                    display: inline-block;
                    margin: 0 0 math.div($defaultMargin, 12);
                    padding: math.div($defaultMargin, 6) math.div($defaultMargin, 3);
                    text-decoration: none;
                    width: 100%;
                    @include box-border(1px);

                    .color {
                        border: 1px solid $borderLightColor;
                        border-radius: 50%;
                        display: inline-block;
                        height: 1.5rem;
                        margin-right: math.div($defaultMargin, 6);
                        margin-top: -0.3rem;
                        vertical-align: middle;
                        width: 1.5rem;
                        @include car-basic-colors;
                    }

                    .label {
                        color: $defaultTextColor;
                        display: flex;
                        font-size: $smallFontSize;

                        .title {
                            display: inline-block;
                            font-weight: $boldFontWeight;
                            min-width: 125px;
                            text-transform: uppercase;
                        }
                    }

                    &:hover {
                        background-color: $lightGrayLabelBackgroundColor;
                        border-color: $borderHoverColor;
                    }

                    &.active {
                        background-color: $alertDangerBorder;
                        border-color: $alertDangerBorder;

                        &:hover {
                            background-color: darken($alertDangerBorder, 10%);
                        }
                    }
                }
            }

            .show-more {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .container {
        margin-top: $defaultMargin * 0.5;

        &:empty {
            display: none;
        }
    }
}

@include tablet {
    .admin-header {
        .admin-leasing-info {
            .subscription-car-list {
                display: flex;

                .car-list-by-status {
                    @include boxes-per-row(2, 1%);
                }
            }
        }
    }
}

@media (min-width: $desktopBreakpoint) and (max-width: 1199px) {
    .admin-header {
        .admin-leasing-info {
            .subscription-car-list {
                display: flex;

                .car-list-by-status {
                    @include boxes-per-row(3, 1%);
                }
            }
        }
    }
}

@include desktop-large {
    .admin-header {
        .admin-leasing-info {
            .subscription-car-list {
                display: flex;

                .car-list-by-status {
                    @include boxes-per-row(5, 0.5%);
                }
            }
        }
    }
}
