.registration-finished-header,
.confirmation-finished-header,
.add-email-header {
    margin-top: $defaultMargin;

    .headline {
        @include bigger-default-headline-font-size;
    }

    .description {
        font-size: $slightlyBiggerFontSize;
    }
}

.registration-finished,
.confirmation-finished,
.add-email-container,
.password-reset-container {
    .registration-benefits {
        @include check-ok-list-with-border($defaultMargin * 0.25);

        margin-bottom: $defaultMargin;
    }
}

.registration-finished,
.confirmation-failed,
.confirmation-finished {
    .headline {
        @include default-headline-font-size;
    }

    .button-primary {
        margin-top: $defaultMargin * 0.5;
        @include button-with-right-arrow;
    }
}

.add-email-container,
.password-reset-container {
    @include desktop {
        form {
            max-width: 50%;
        }
    }
}

@include desktop {
    .registration-finished-header,
    .confirmation-finished-header,
    .add-email-header {
        margin-top: $smallScreenMargin;
    }
}
