@use "sass:math";

.company-contacts-container {
    .title {
        font-size: $slightlyBiggerFontSizePx;
    }

    .subtitle {
        font-size: $defaultFontSize;
    }

    .columns-container {
        margin-bottom: $smallScreenMargin;

        .image {
            border-radius: $defaultBorderRadius;
            height: auto;
            width: 100%;
        }
    }

    .maps-container {
        .image {
            &:first-of-type {
                margin-bottom: $defaultMargin * 0.5;
            }
        }
    }

    .company-contacts {
        margin-bottom: $smallScreenMargin;

        .company-headquarters {
            font-size: $slightlyBiggerFontSize;
        }

        .contacts-container {
            margin-bottom: $defaultMargin;

            .contact {
                &:first-child {
                    margin-bottom: $defaultMargin;
                }
            }

            .opening-hours {
                margin-bottom: $defaultMargin * 0.25;
            }

            .phone,
            .email {
                display: inline-block;
                margin-bottom: $defaultMargin * 0.25;
                min-width: 150px;

                &:not(:last-of-type) {
                    margin-right: $defaultMargin;
                }

                &::before {
                    content: " ";
                    display: inline-block;
                    height: 2rem;
                    margin-right: math.div($defaultMargin, 3);
                    vertical-align: middle;
                    width: 2rem;
                }
            }

            .phone {
                &::before {
                    background: url("/assets/icon/headset.svg") 50% 50% no-repeat transparent;
                }
            }

            .email {
                &::before {
                    background: url("/assets/icon/envelope.svg") 50% 50% no-repeat transparent;
                }
            }
        }
    }
}
@include tablet-desktop {
    .company-contacts-container {
        .phone,
        .email {
            .company-contacts {
                .contacts-container {
                    display: block;
                }
            }
        }
    }
}

@include desktop {
    .company-contacts-container {
        .columns-container {
            display: flex;

            .column {
                @include boxes-per-row(2, 2%);
            }
        }

        .maps-container {
            .image {
                &:first-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .company-contacts {
            display: flex;

            .contacts-container {
                &:first-of-type {
                    border-right: 2px solid $borderLightColor;
                    width: 55%;
                }

                &:nth-of-type(2) {
                    margin-left: 5%;
                    width: 40%;
                }

                .phone,
                .email {
                    display: block;

                    &:not(:last-of-type) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
