@use "sass:math";

.about-us {
    margin: 0 auto $smallScreenMargin;

    &.vision {
        .box {
            &:nth-child(1) {
                margin-bottom: $smallScreenMargin;
            }
        }

        .label {
            background-color: $yesColor;
            border-radius: $defaultBorderRadius;
            color: $inverseTextColor;
            display: inline-block;
            font-weight: $boldFontWeight;
            letter-spacing: 2px;
            margin-bottom: $defaultMargin * 0.5;
            padding: math.div($defaultMargin, 6) $defaultMargin * 0.5;
            text-transform: uppercase;
        }

        .text {
            font-weight: $boldFontWeight;
        }
    }

    &.bio {
        line-height: $aboutUsLineHeight;
    }

    &.career {
        background-color: $accentedBackgroundColor;
        border-radius: $defaultBorderRadius;
        margin: 0 auto;
        padding: $defaultMargin;
        width: fit-content;
    }
}

@media (min-width: 550px) {
    .about-us {
        @include flex-grid;

        .box {
            @include boxes-per-row(2, 10%);
        }
    }
}

@include desktop {
    .about-us {
        margin: 0 auto $largeScreenMargin;
        max-width: $desktopBreakpoint;

        &.bio {
            font-size: $slightlyBiggerFontSizePx;
        }

        &.vision {
            .box {
                &:nth-child(1) {
                    margin-bottom: 0;
                }
            }

            .text {
                font-size: $slightlyBiggerFontSizePx;
            }
        }
    }
}
