@use "sass:math";

.subscription-faq-container {
    margin-bottom: $defaultMargin;

    #subscription-faq-tabs {
        .tabs-navigation {
            list-style: none;
            @include flex-grid;

            .tab-item {
                display: inherit;
                margin-right: math.div($defaultMargin, 3);

                .tab-item-link {
                    background-color: $accentedBackgroundColor;
                    border-radius: $defaultMargin;
                    color: $defaultTextColor;
                    font-weight: $boldFontWeight;
                    line-height: 1.2;
                    padding: $defaultMargin * 0.5 $defaultMargin * 0.75;
                    text-decoration: none;
                }

                .icon {
                    display: inline-block;
                    height: 1.7rem;
                    margin-right: $defaultMargin * 0.25;
                    margin-top: -0.3rem;
                    width: 1.7rem;
                }

                &.active {
                    .tab-item-link {
                        background-color: $accentedBackgroundColor;
                        color: $brandColor;
                    }
                }
            }
        }

        .tabs-content {
            .tab-content {
                &:not(.active) {
                    display: none;
                }
            }
        }
    }
}

@include desktop {
    .subscription-faq-container {
        margin-bottom: $largeScreenMargin;

        #subscription-faq-tabs {
            display: flex;
            width: 100%;

            .tabs-navigation {
                flex-flow: nowrap column;
                list-style: none;
                margin-right: 3%;
                position: relative;
                width: 30%;

                .tab-item {
                    margin-bottom: 0;

                    .tab-item-link {
                        align-items: center;
                        background-color: $defaultBackgroundColor;
                        border: 1px solid transparent;
                        border-radius: $defaultBorderRadius;
                        display: flex;
                        font-size: $slightlyBiggerFontSizePx;
                        line-height: 1.2;
                        padding: $defaultMargin * 0.75 $defaultMargin;
                        text-decoration: none;
                        width: 100%;
                        @include own-transition(background-color);

                        &:hover {
                            background-color: $accentedBackgroundColor;
                        }
                    }

                    &.active {
                        .tab-item-link {
                            &::after {
                                margin-left: auto;
                            }
                        }
                    }

                    .icon {
                        fill: $brandColor;
                        margin-right: $defaultMargin;
                    }
                }
            }

            .tabs-content {
                width: 67%;
            }
        }
    }
}
