$lg-path-fonts: "./../../../node_modules/lightgallery/fonts/" !default;
$lg-path-images: "./../../../node_modules/lightgallery/images/" !default;

@import "../../../../node_modules/lightgallery/scss/lightgallery";
@import "../../../../node_modules/lightgallery/scss/lg-video";
@import "../../../../node_modules/lightgallery/scss/lg-thumbnail";
@import "../../../../node_modules/lightgallery/scss/lg-fonts";

.lg-outer {
    &.lg-hide-download {
        .lg-download {
            display: none;
        }
    }
}
