.gallery-section {
    margin-top: $smallScreenMargin;
}

.gallery-section,
.video-section {
    margin-bottom: $smallScreenMargin;
}

@include desktop {
    .gallery-section {
        margin-top: $largeScreenMargin;
    }

    .gallery-section,
    .video-section {
        margin-bottom: $largeScreenMargin;
    }
}
