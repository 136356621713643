@use "sass:math";

body {
    &:not(.comparator-panel-visible) {
        .comparator-panel-container {
            display: none;
        }
    }

    &.comparator-panel-visible {
        .comparator-panel-container {
            display: block;
        }
    }
}

.comparator-panel-container {
    background-color: $accentedBackgroundColor;
    border-top: 1px solid $borderLightColor;
    bottom: 0;
    left: 0;
    padding: math.div($defaultMargin, 3) 0 $defaultMargin * 0.75;
    position: fixed;
    right: 0;
    transform: translateZ(0);
    z-index: $mainMenuZIndex - 1;

    &.highlight {
        animation: highlight 1s;
    }

    .comparator-panel {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .large-screen {
            display: none;
        }

        .small-screen {
            display: none;
        }
    }

    .buttons-wrapper {
        .button-light {
            font-size: $defaultFontSize;
            font-weight: $defaultFontWeight;
            margin-bottom: math.div($defaultMargin, 12);
            padding-bottom: 0;
        }

        span {
            display: none;
        }
    }

    .comparator-offer-count {
        background-color: $brandColor;
        border-radius: 50%;
        color: $inverseTextColor;
        display: inline-block;
        flex: none;
        font-weight: $boldFontWeight;
        height: 2.5rem;
        line-height: 2.5rem;
        margin-left: math.div($defaultMargin, 3);
        text-align: center;
        width: 2.5rem;
    }

    .comparator-link {
        margin-left: $defaultMargin;
        margin-right: $defaultMargin * 0.5;

        &::before {
            background-color: transparent;
            background-image: url("/assets/icon/compare-add.svg");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 1.5rem auto;
            content: " ";
            display: inline-block;
            height: 1.5rem;
            margin-right: math.div($defaultMargin, 12);
            margin-top: -0.2rem;
            vertical-align: middle;
            width: 1.5rem;
        }
    }

    .comparator-remove-all-offers {
        border-color: $defaultTextColor;
        color: $defaultTextColor;

        &::before {
            background-color: transparent;
            background-image: url("/assets/icon/close.svg");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 1.5rem auto;
            content: " ";
            display: inline-block;
            height: 1.5rem;
            margin-right: math.div($defaultMargin, 12);
            margin-top: -0.2rem;
            vertical-align: middle;
            width: 1.5rem;
        }
    }
}

@media (min-width: $smallestDeviceBreakpoint) {
    .comparator-panel-container {
        .comparator-panel {
            .small-screen {
                display: inline-block;
            }
        }
    }
}

@media (max-width: 480px) {
    .comparator-panel-container {
        .buttons-wrapper {
            .comparator-remove-all-offers {
                &::before {
                    display: none;
                }
            }
        }
    }
}

@include tablet-desktop {
    .comparator-panel-container {
        .comparator-panel {
            .large-screen {
                display: inline-block;
            }

            .small-screen {
                display: none;
            }
        }

        .buttons-wrapper {
            margin-left: $largeScreenMargin;

            span {
                display: inline;
            }
        }

        .comparator-link {
            margin-left: $defaultMargin * 2;
        }
    }
}

@include desktop {
    .comparator-panel-container {
        padding: math.div($defaultMargin, 3) 0;

        .comparator-panel {
            justify-content: center;
        }

        .comparator-link {
            margin-left: $defaultMargin * 2;
        }
    }
}

@keyframes highlight {
    0% {
        background: $yellowLabelBackgroundColor;
    }

    100% {
        background: $accentedBackgroundColor;
    }
}
