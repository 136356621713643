.small-boxes-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &::before {
        display: none;
    }

    li {
        border-radius: $defaultBorderRadius;
        height: auto;
        width: 100%;
        @include small-box;
        @include hover-box-border;

        & > a {
            align-items: center;
            display: flex;
            font-weight: $boldFontWeight;
            height: 100%;
            justify-content: center;
            padding: 1rem;
            text-align: center;
            text-decoration: none;
        }
    }

    &::after {
        @include utility-clearfix;
    }
}

@media (min-width: 480px) and (max-width: 639px) {
    .small-boxes-list {
        li {
            @include boxes-per-row(2);
        }
    }
}

@include tablet {
    .small-boxes-list {
        li {
            @include boxes-per-row(4);
        }
    }
}

@include desktop {
    .small-boxes-list {
        li {
            @include boxes-per-row(5);
        }
    }
}

.js {
    .small-boxes-list {
        &.hidden-list {
            .box-link {
                justify-content: center;
            }
        }
    }
}
