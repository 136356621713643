dl {
    &.car-data-list {
        overflow: auto;

        dt,
        dd {
            display: block;
            float: left;
            margin: 0.5rem 0;
            width: 50%;
        }

        dt {
            clear: left;
        }
    }
}
