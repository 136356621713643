.make-list {
    margin-bottom: 2rem;

    .make {
        margin-top: $defaultMargin * 0.25;
        white-space: nowrap;

        .make-logo {
            border-bottom: 1px solid $borderLightColor;
            display: block;
            padding: 8% 28%;

            img {
                height: auto;
                image-rendering: -webkit-optimize-contrast;
                max-width: 100%;
            }
        }

        h4 {
            font-size: $defaultFontSize;
            margin: 0;
        }
    }
}
