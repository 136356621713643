.model-line-list {
    .model-line-item {
        position: relative;

        .production-dates {
            display: block;
            font-weight: $defaultFontWeight;
        }
    }
}
