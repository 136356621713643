.listing-top-banner {
    margin-bottom: $defaultMargin;

    &.catalog {
        margin-top: $defaultMargin * 0.5;
    }

    .primary-box {
        border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
        min-height: 200px;
        overflow: hidden;
        position: relative;

        &.full-width {
            border-radius: $defaultBorderRadius;
        }

        .car-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
            @include box-absolute-gradient;

            padding: $defaultMargin * 0.5;

            .subtitle {
                display: block;
                font-size: 1.6rem;
                font-weight: $boldFontWeight;
                margin-right: $defaultMargin * 0.25;
                margin-top: $defaultMargin * 0.25;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .make-logo {
                align-self: flex-end;
                height: auto;
                max-width: 50px;
            }
        }

        .title {
            align-self: flex-end;
            color: $inverseTextColor;
            line-height: 1;
            margin-bottom: 0;
            min-width: 0;
            @include bigger-default-headline-font-size(false);

            .price-subtitle {
                display: block;
                margin-top: $defaultMargin * 0.5;
                @include default-font-family;
            }
        }
    }

    .secondary-box {
        border-left: 0;
        border-radius: 0 0 $defaultBorderRadius $defaultBorderRadius;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include box-border(1px, $accentedBackgroundColor);

        padding: $defaultMargin * 0.5;
    }
}

@include tablet-desktop {
    .listing-top-banner {
        .primary-box {
            min-height: 350px;

            .car-title {
                padding: $defaultMargin;

                .make-logo {
                    max-width: 70px;
                }
            }

            .images-list {
                .image-list-item {
                    border-radius: 0;
                }
            }
        }

        .secondary-box {
            padding: $defaultMargin;
        }
    }
}

@include desktop {
    .listing-top-banner {
        margin-bottom: $smallScreenMargin;
    }
}

@include desktop-large {
    .listing-top-banner {
        display: flex;

        .primary-box {
            border-radius: $largeBorderRadius * 3 0 0 $largeBorderRadius;
            width: 66.66%;

            &.full-width {
                border-radius: $largeBorderRadius * 3 $defaultBorderRadius $largeBorderRadius;
                min-height: 450px;
                width: 100%;
            }
        }

        .secondary-box {
            border-radius: 0 $largeBorderRadius $largeBorderRadius 0;
            width: 33.34%;
        }
    }
}
