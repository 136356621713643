@charset "UTF-8";
html.no-js .no-js-hidden {
  display: none;
}
html.js .js-hidden {
  display: none;
}
html.mobile-no-scroll {
  overflow: hidden;
}

body.mobile-no-scroll {
  overflow: auto;
}

.hidden {
  display: none;
}

.cf-turnstile {
  min-height: 20.3px;
}

.no-scroll {
  overflow: hidden;
}

.mobile-no-scroll {
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  touch-action: none;
}

.open-popup-checkbox {
  display: none;
}

.close-popup-arrow-before::before {
  content: "←";
  display: inline-block;
  margin-right: 4px;
  margin-top: -0.2rem;
  vertical-align: top;
}

.text-center {
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

.show-more-container .hidden-content {
  display: none;
}
.show-more-container .show-more {
  border-bottom: 1px solid #3c3f44;
  color: #3c3f44;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin-top: 12px;
  padding-bottom: 2px;
  text-decoration: none;
  width: fit-content;
}
.show-more-container .show-more::after {
  background-color: #3c3f44;
  content: " ";
  display: inline-block;
  height: 1.2rem;
  margin: -0.2rem 0 0 4px;
  mask: url("/assets/icon/expand-arrow.svg") no-repeat 50% 50%;
  mask-size: contain;
  vertical-align: middle;
  width: 1.2rem;
}
.show-more-container .show-more.active::after {
  transform: rotate(180deg);
}

.svg-sprite {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.img-fluid {
  height: auto;
  width: 100%;
}

img:not([src]) {
  display: none;
}

.video-container {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.video-container::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.video-container iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

body::after {
  content: "small-screen";
  display: none;
}

@media (min-width: 940px) {
  body::after {
    content: "medium-screen";
  }
}
@media (min-width: 1200px) {
  body::after {
    content: "large-screen";
  }
}
.button {
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  padding: 6px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
  border: 2px solid #ededed;
  background: #ffffff;
  color: #ff3f2d;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
.button:active, .button:hover, .button:focus {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
  color: #ffffff;
}
@media (min-width: 640px) {
  .button {
    padding: 8px 24px;
  }
}
.button:active, .button:hover, .button:focus {
  outline: 0;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.15);
}
.button:not(.mfp-close):active {
  transform: scale(0.98);
}
.button .icon {
  fill: #ff3f2d;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
.button:active .icon, .button:hover .icon, .button:focus .icon {
  filter: brightness(0) invert(1);
}
.button.button-primary {
  background: #ff3f2d;
  color: #ffffff;
  border: 2px solid #ff3f2d;
}
.button.button-primary:active, .button.button-primary:hover, .button.button-primary:focus {
  background-color: #b52c17;
  border-color: #b52c17;
  color: #ffffff;
}
.button.button-big {
  padding: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  .button.button-big {
    border-radius: 45px;
    font-size: 18px;
    padding: 12px 48px;
    width: auto;
  }
}
.button.button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
.button.header-button {
  font-size: 85%;
  background: #ffffff;
  color: #3c3f44;
  border: 1px solid #ededed;
}
.button.header-button:active, .button.header-button:hover, .button.header-button:focus {
  background-color: #ededee;
  border-color: #ededee;
  color: #000000;
}

.facebook-button {
  background: #4267b2;
  color: #ffffff;
  border: 2px solid #4267b2;
}
.facebook-button:active, .facebook-button:hover, .facebook-button:focus {
  background-color: #274c97;
  border-color: #274c97;
  color: #ffffff;
}

.button-light {
  border-bottom: 1px solid #ff3f2d;
  box-shadow: none;
  color: #ff3f2d;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.45;
  text-decoration: none;
  transition: color 0.3s, border-bottom-color 0.3s, padding 0.3s;
}
.button-light:focus, .button-light:hover {
  border-color: #b52c17;
  color: #b52c17;
}
@media (min-width: 940px) {
  .button-light {
    font-size: 16px;
  }
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  text-size-adjust: 100%;
}

body {
  margin: 0;
}

dl,
dl > dd {
  margin: 0;
}

audio,
iframe,
img,
svg {
  vertical-align: middle;
}

img {
  border-style: none;
}

svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-hidden=false][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[aria-hidden=false][hidden]:focus {
  clip: auto;
}

[aria-disabled] {
  cursor: default;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 12px;
  position: relative;
  width: 100%;
}
.container::after {
  clear: both;
  content: "";
  display: table;
}
.container--tight {
  max-width: 940px;
}

.container-content-body {
  margin-bottom: 48px;
}
@media (min-width: 940px) {
  .container-content-body {
    margin-bottom: 72px;
  }
}

@media (min-width: 640px) {
  .container {
    padding: 0;
    width: 90%;
  }
}
.grid-flex-left-sidebar {
  display: flex;
  min-height: 100vh;
}
@media (min-width: 1920px) {
  .grid-flex-left-sidebar {
    max-width: 1920px;
  }
}

.grid-flex {
  margin-bottom: 24px;
}
.grid-flex.listing {
  margin-bottom: 48px;
}

@media (min-width: 940px) {
  .container--tight {
    max-width: 940px;
  }
  .grid-flex {
    display: flex;
  }
  .grid-flex .content-main {
    width: 68%;
  }
  .grid-flex.listing {
    margin-bottom: 72px;
  }
  .grid-flex .content-aside-left {
    margin-right: 2%;
    position: relative;
    width: 30%;
  }
  .grid-flex .content-main-listing {
    margin-left: 1%;
    width: 76%;
  }
  .grid-flex .content-aside-listing {
    min-width: 270px;
    position: relative;
    width: 23%;
  }
  .grid-flex-left-sidebar {
    flex-direction: row;
  }
  .grid-flex-left-sidebar .content-main {
    margin-left: 2%;
    margin-right: 2%;
    width: 68%;
  }
  .grid-flex-left-sidebar .content-aside {
    min-width: 330px;
    width: 30%;
  }
}
html {
  font-size: 62.5%;
}

body {
  -moz-font-feature-settings: "liga" on;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #3c3f44;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  min-height: 100vh;
  overflow-anchor: auto;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

.fonts-loaded body {
  font-family: DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6 {
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 600;
  line-height: 1.5;
}

h1 {
  font-size: 24px;
  margin: 0 0 12px;
}

h2 {
  font-size: 20px;
  margin: 0 0 12px;
}

h3 {
  font-size: 18px;
  margin: 0 0 6px;
}

h4 {
  font-size: 18px;
  margin: 0 0 6px;
}

h5 {
  font-size: 16px;
  margin: 0 0 6px;
}

h6 {
  font-size: 16px;
  margin: 0 0 6px;
}

@media (min-width: 640px) {
  h1 {
    font-size: 32px;
    margin: 0 0 24px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 18px;
  }
}
p {
  margin-top: 0;
}

small {
  font-size: 85%;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: 700;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

::selection {
  background-color: #00baf2;
  color: #ffffff;
  text-shadow: none;
}

.error-heading {
  text-align: center;
}

.error-page {
  padding-top: 48px;
  text-align: center;
}
.error-page p {
  font-size: 115%;
}
.error-page img {
  display: block;
  height: auto;
  margin: 24px auto;
  max-width: 100%;
}
.error-page .button {
  margin-top: 24px;
}

@font-face {
  font-display: swap;
  font-family: DMSans;
  font-style: normal;
  font-weight: 400;
  src: local("DM-Sans-Regular"), local("DM Sans Regular"), url("/assets/font/dmsans-regular.woff2") format("woff2"), url("/assets/font/dmsans-regular.woff") format("woff"), url("/assets/font/dmsans-regular.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: DMSans;
  font-style: normal;
  font-weight: 500;
  src: local("DM-Sans-Medium"), local("DM Sans Medium"), url("/assets/font/dmsans-medium.woff2") format("woff2"), url("/assets/font/dmsans-medium.woff") format("woff"), url("/assets/font/dmsans-medium.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: DMSans;
  font-style: normal;
  font-weight: 700;
  src: local("DM-Sans"), local("DM Sans"), local("DM-Sans-Bold"), local("DM Sans Bold"), url("/assets/font/dmsans-bold.woff2") format("woff2"), url("/assets/font/dmsans-bold.woff") format("woff"), url("/assets/font/dmsans-bold.ttf") format("truetype");
}
@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local("Poppins-SemiBold"), local("Poppins SemiBold"), url("/assets/font/poppins-semibold.woff2") format("woff2"), url("/assets/font/poppins-semibold.woff") format("woff"), url("/assets/font/poppins-semibold.ttf") format("truetype");
}
input,
textarea,
select,
button {
  font-family: inherit;
  font-size: 14px;
}
@media (max-width: 939px) {
  input,
  textarea,
  select,
  button {
    font-size: 16.1px;
  }
}

button {
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  padding: 6px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
  border: 2px solid #ededed;
  background: #ffffff;
  color: #ff3f2d;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
button:active, button:hover, button:focus {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
  color: #ffffff;
}
@media (min-width: 640px) {
  button {
    padding: 8px 24px;
  }
}
button:active, button:hover, button:focus {
  outline: 0;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.15);
}
button:not(.mfp-close):active {
  transform: scale(0.98);
}
button .icon {
  fill: #ff3f2d;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
button:active .icon, button:hover .icon, button:focus .icon {
  filter: brightness(0) invert(1);
}
button.button-primary {
  background: #ff3f2d;
  color: #ffffff;
  border: 2px solid #ff3f2d;
}
button.button-primary:active, button.button-primary:hover, button.button-primary:focus {
  background-color: #b52c17;
  border-color: #b52c17;
  color: #ffffff;
}
button.button-big {
  padding: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  button.button-big {
    border-radius: 45px;
    font-size: 18px;
    padding: 12px 48px;
    width: auto;
  }
}
button.button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
button.header-button {
  font-size: 85%;
  background: #ffffff;
  color: #3c3f44;
  border: 1px solid #ededed;
}
button.header-button:active, button.header-button:hover, button.header-button:focus {
  background-color: #ededee;
  border-color: #ededee;
  color: #000000;
}

input[type=submit], input[type=reset], input[type=button] {
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  padding: 6px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, transform 0.3s ease;
  white-space: nowrap;
  border: 2px solid #ededed;
  background: #ffffff;
  color: #ff3f2d;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
input[type=submit]:active, input[type=submit]:hover, input[type=submit]:focus, input[type=reset]:active, input[type=reset]:hover, input[type=reset]:focus, input[type=button]:active, input[type=button]:hover, input[type=button]:focus {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
  color: #ffffff;
}
@media (min-width: 640px) {
  input[type=submit], input[type=reset], input[type=button] {
    padding: 8px 24px;
  }
}
input[type=submit]:active, input[type=submit]:hover, input[type=submit]:focus, input[type=reset]:active, input[type=reset]:hover, input[type=reset]:focus, input[type=button]:active, input[type=button]:hover, input[type=button]:focus {
  outline: 0;
  box-shadow: 0 5px 1px rgba(0, 0, 0, 0.15);
}
input[type=submit]:not(.mfp-close):active, input[type=reset]:not(.mfp-close):active, input[type=button]:not(.mfp-close):active {
  transform: scale(0.98);
}
input[type=submit] .icon, input[type=reset] .icon, input[type=button] .icon {
  fill: #ff3f2d;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
input[type=submit]:active .icon, input[type=submit]:hover .icon, input[type=submit]:focus .icon, input[type=reset]:active .icon, input[type=reset]:hover .icon, input[type=reset]:focus .icon, input[type=button]:active .icon, input[type=button]:hover .icon, input[type=button]:focus .icon {
  filter: brightness(0) invert(1);
}
input[type=submit].button-primary, input[type=reset].button-primary, input[type=button].button-primary {
  background: #ff3f2d;
  color: #ffffff;
  border: 2px solid #ff3f2d;
}
input[type=submit].button-primary:active, input[type=submit].button-primary:hover, input[type=submit].button-primary:focus, input[type=reset].button-primary:active, input[type=reset].button-primary:hover, input[type=reset].button-primary:focus, input[type=button].button-primary:active, input[type=button].button-primary:hover, input[type=button].button-primary:focus {
  background-color: #b52c17;
  border-color: #b52c17;
  color: #ffffff;
}
input[type=submit].button-big, input[type=reset].button-big, input[type=button].button-big {
  padding: 12px;
  width: 100%;
}
@media (min-width: 640px) {
  input[type=submit].button-big, input[type=reset].button-big, input[type=button].button-big {
    border-radius: 45px;
    font-size: 18px;
    padding: 12px 48px;
    width: auto;
  }
}
input[type=submit].button-disabled, input[type=reset].button-disabled, input[type=button].button-disabled {
  opacity: 0.8;
  pointer-events: none;
}
input[type=submit].header-button, input[type=reset].header-button, input[type=button].header-button {
  font-size: 85%;
  background: #ffffff;
  color: #3c3f44;
  border: 1px solid #ededed;
}
input[type=submit].header-button:active, input[type=submit].header-button:hover, input[type=submit].header-button:focus, input[type=reset].header-button:active, input[type=reset].header-button:hover, input[type=reset].header-button:focus, input[type=button].header-button:active, input[type=button].header-button:hover, input[type=button].header-button:focus {
  background-color: #ededee;
  border-color: #ededee;
  color: #000000;
}
input[type=email], input[type=tel], input[type=text], input[type=number], input[type=password] {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 8px;
  color: #4d5156;
  font-weight: 500;
  line-height: 1.45;
  margin: 0;
  padding: 8px 12px;
  width: 100%;
  transition: border-color 0.3s ease;
}
input[type=email]:disabled, input[type=tel]:disabled, input[type=text]:disabled, input[type=number]:disabled, input[type=password]:disabled {
  background-color: #ededee;
  pointer-events: none;
}
input[type=email]:active, input[type=email]:focus, input[type=tel]:active, input[type=tel]:focus, input[type=text]:active, input[type=text]:focus, input[type=number]:active, input[type=number]:focus, input[type=password]:active, input[type=password]:focus {
  border-color: #c6cad2;
  outline: none;
}
input[type=email]::placeholder, input[type=tel]::placeholder, input[type=text]::placeholder, input[type=number]::placeholder, input[type=password]::placeholder {
  color: #c6ccd7;
}
input[type=email]:-webkit-autofill, input[type=tel]:-webkit-autofill, input[type=text]:-webkit-autofill, input[type=number]:-webkit-autofill, input[type=password]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  -webkit-text-fill-color: #4d5156;
}
input[type=email].error, input[type=tel].error, input[type=text].error, input[type=number].error, input[type=password].error {
  border: 1px solid #ec5340;
}
input[type=email] ~ .error, input[type=tel] ~ .error, input[type=text] ~ .error, input[type=number] ~ .error, input[type=password] ~ .error {
  color: #ec5340;
  font-size: 85%;
  line-height: 1.45;
  padding-top: 6px;
}

select,
textarea {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 8px;
  color: #4d5156;
  font-weight: 500;
  line-height: 1.45;
  margin: 0;
  padding: 8px 12px;
  width: 100%;
  transition: border-color 0.3s ease;
}
select:disabled,
textarea:disabled {
  background-color: #ededee;
  pointer-events: none;
}
select:active, select:focus,
textarea:active,
textarea:focus {
  border-color: #c6cad2;
  outline: none;
}
select::placeholder,
textarea::placeholder {
  color: #c6ccd7;
}
select:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  -webkit-text-fill-color: #4d5156;
}
select.error,
textarea.error {
  border: 1px solid #ec5340;
}
select ~ .error,
textarea ~ .error {
  color: #ec5340;
  font-size: 85%;
  line-height: 1.45;
  padding-top: 6px;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("/assets/icon/expand-arrow.svg");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  padding-right: 24px;
}
select option:checked {
  color: #ff3f2d;
  font-weight: 700;
}
select::-ms-expand {
  display: none;
}

label {
  display: block;
  font-weight: 500;
  line-height: 2;
}
label.required::after {
  color: #ec5340;
  content: "*";
  display: inline-block;
  padding-left: 0.2rem;
}
label.help-title::after {
  background-color: #00baf2;
  border-radius: 50%;
  color: #ffffff;
  content: "?";
  cursor: help;
  display: inline-block;
  font-size: 85%;
  font-weight: 700;
  line-height: 1.25;
  margin-left: 0.3rem;
  padding: 0 0.4rem;
}

.form-row,
.form-group {
  margin-bottom: 8px;
}

.form-row--alternative {
  display: none;
}

.form-submit-row,
.form-agreement {
  text-align: center;
}

.form-flex-row {
  display: flex;
  flex-wrap: wrap;
}

.form-submit-row .button-primary {
  margin-bottom: 12px;
}

.form-half {
  width: 100%;
}
@media (max-width: 639px) {
  .form-half:first-child {
    margin-bottom: 12px;
  }
}
@media (min-width: 640px) {
  .form-half {
    margin-left: 1%;
    width: 49.5%;
    margin-bottom: 0;
  }
  .form-half:nth-child(2n+1) {
    margin-left: 0;
  }
}

form .help-block {
  background-color: #f2dede;
  border-color: #ebccd1;
  border-radius: 10px;
  color: #a94442;
  display: block;
  font-size: 85%;
  line-height: 1.45;
  margin: 8px 0;
  padding: 6px 12px;
}
form .help-block .list-unstyled {
  list-style: none;
  margin: 0;
}
form .help-block .list-unstyled li {
  margin: 0;
}

picture {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
picture.fallback {
  background-color: #ededee;
  background-image: url("/assets/icon/img-placeholder.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

img.fallback {
  height: auto;
  max-width: 80%;
}
img.img-inner {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  transition: transform 0.3s ease;
  will-change: transform;
}

.server-name-label {
  padding: 12px;
  position: fixed;
  right: 24px;
  text-align: center;
  top: 24px;
  z-index: 1400;
}
.server-name-label.dev {
  background-color: #fedd24;
}
.server-name-label.prod {
  background-color: #da0339;
}
.server-name-label.preprod {
  background-color: #ff573d;
}
.server-name-label.local {
  background-color: #6b6f73;
}
.server-name-label .server-name {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 940px) {
  .server-name-label {
    padding: 24px;
  }
  .server-name-label .server-name {
    font-size: 3rem;
  }
}
.tooltip.tooltip-active {
  cursor: pointer;
  position: relative;
}
.tooltip.tooltip-active::after {
  background: url("/assets/icon/question-mark-gray.svg") 50% 50% no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.6rem;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.6rem;
}
.tooltip.tooltip-active::before {
  background-image: url("/assets/icon/close.svg");
  background-position: right 12px top 12px;
  background-repeat: no-repeat;
  background-size: 2rem;
  bottom: 0;
  color: #000000;
  content: attr(data-title);
  cursor: pointer;
  display: block;
  font-weight: 400;
  left: 0;
  line-height: 1.45;
  padding: 48px 24px;
  position: fixed;
  text-decoration: none;
  text-transform: none;
  transform: translateY(100%);
  white-space: pre-wrap;
  width: 100%;
  z-index: 1020;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: transform 0.3s ease;
  will-change: transform;
}
.tooltip.tooltip-active.tooltip-open::before {
  transform: translateY(0);
}

@media (min-width: 640px) {
  .tooltip.tooltip-active::before {
    border-radius: 10px;
    bottom: auto;
    display: none;
    left: 50%;
    max-width: 400px;
    min-width: 200px;
    padding: 36px 24px;
    position: absolute;
    right: auto;
    top: 24px;
    transform: translateX(-50%) translateY(0);
    transition: none;
    width: max-content;
  }
  .tooltip.tooltip-active.tooltip-open::before {
    display: block;
    transform: translateX(-50%);
  }
  .tooltip.tooltip-active.tooltip-open.right::before {
    left: auto;
    right: 0;
    transform: translateX(15%);
  }
  .tooltip.tooltip-active.tooltip-open.left::before {
    left: 0;
    right: auto;
    transform: translateX(-85%);
  }
}
@media (max-width: 639px) {
  body.tooltip-open {
    position: relative;
  }
  body.tooltip-open::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
  body.tooltip-open #main-menu::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
}
@media (min-width: 940px) {
  .tooltip.tooltip-active::before {
    z-index: 598;
  }
}
input[type=checkbox]:not(.open-popup-checkbox) {
  cursor: pointer;
  display: none;
  height: initial;
  margin-bottom: 0;
  padding: 0;
  width: initial;
}
input[type=checkbox]:not(.open-popup-checkbox) + label {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.45;
  position: relative;
}
input[type=checkbox]:not(.open-popup-checkbox) + label::before {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  content: "";
  display: inline-block;
  margin-right: 6px;
  margin-top: -0.3rem;
  padding: 8px;
  position: relative;
  vertical-align: middle;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label {
  color: #ff3f2d;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label::after {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 12px;
  left: 6px;
  margin-top: -0.2rem;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 6px;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label::before {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
}
input[type=checkbox]:not(.open-popup-checkbox):checked + label.green::before {
  background-color: #2dbd58;
  border-color: #2dbd58;
}
input[type=checkbox]:not(.open-popup-checkbox):disabled + label {
  cursor: auto;
}
input[type=checkbox]:not(.open-popup-checkbox):disabled + label::before {
  opacity: 0.4;
}
input.green[type=checkbox]:not(.open-popup-checkbox):checked + label {
  color: #000000;
}
input.green[type=checkbox]:not(.open-popup-checkbox):checked + label::before {
  background-color: #2dbd58;
  border-color: #2dbd58;
}

.subscription-banner-small-container {
  margin-bottom: 24px;
  overflow: hidden;
  padding: 48px 12px;
  position: relative;
}
.subscription-banner-small-container picture {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.subscription-banner-small-container picture img {
  object-position: center 65%;
}
.subscription-banner-small-container::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 0%, transparent 50%, transparent 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.subscription-banner-small-container .banner-promo-text {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.subscription-banner-small-container .banner-promo-text span {
  background-color: #ff3f2d;
  color: #ffffff;
  display: block;
  font-size: 32px;
  font-weight: 700;
  padding: 3px 18px;
  text-transform: uppercase;
  width: fit-content;
}
.subscription-banner-small-container .banner-promo-text span:first-child {
  transform: rotate(-5deg) translateX(-10px) translateY(-5px);
}
.subscription-banner-small-container .banner-promo-text span:nth-child(2) {
  transform: rotate(3deg);
}
.subscription-banner-small-container .banner-container-column {
  position: relative;
  z-index: 3;
}
.subscription-banner-small-container .banner-container-column:first-child {
  min-height: 140px;
  text-align: center;
}

@media (min-width: 640px) {
  .subscription-banner-small-container {
    border-radius: 20px;
    margin-top: 24px;
    padding: 24px 48px;
  }
  .subscription-banner-small-container::before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 0%, transparent 50%, transparent 100%);
  }
}
@media (min-width: 940px) {
  .subscription-banner-small-container .banner-promo-text {
    align-items: flex-start;
    transform: translateY(25px);
  }
  .subscription-banner-small-container .banner-promo-text span {
    font-size: 32px;
  }
  .subscription-banner-small-container .banner-promo-text span:first-child {
    transform: rotate(-5deg) translateX(-10px) translateY(-5px);
  }
  .subscription-banner-small-container .banner-promo-text span:nth-child(2) {
    transform: rotate(3deg);
  }
}
@media (min-width: 1220px) {
  .subscription-banner-small-container .banner-container-columns {
    display: flex;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column {
    margin-left: 2%;
    width: 49%;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column:nth-child(2n+1) {
    margin-left: 0;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column:first-child {
    text-align: left;
  }
  .subscription-banner-small-container .banner-container-columns .banner-container-column:nth-child(2) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}
.abandoned-inquiry-car-modal {
  border-radius: 10px;
  display: none;
  max-width: 360px;
  padding: 24px;
  right: 24px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  z-index: 599;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.abandoned-inquiry-car-modal:hover {
  border-color: #c6cad2;
}
.abandoned-inquiry-car-modal:not(.fixed) {
  position: absolute;
  top: 165px;
}
.abandoned-inquiry-car-modal.fixed {
  position: fixed;
  top: 24px;
  transition: transform 0.3s ease;
  will-change: transform;
}
.abandoned-inquiry-car-modal .content {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}
.abandoned-inquiry-car-modal .content .car-offer-image {
  border: 1px solid #ededed;
  border-radius: 10px 10px 10px 20px;
  flex: none;
  height: 80px;
  margin-right: 8px;
  overflow: hidden;
  width: 120px;
}
.abandoned-inquiry-car-modal .content .link .no-wrap {
  font-weight: 500;
}
.abandoned-inquiry-car-modal .button-primary {
  width: 100%;
}
.abandoned-inquiry-car-modal .close-modal {
  background-color: transparent;
  background-image: url("/assets/icon/close.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: inline-block;
  height: 2rem;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 12px;
  width: 2rem;
}

@media (min-width: 940px) {
  .abandoned-inquiry-car-modal {
    display: block;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes fadeInFromLeft {
  0% {
    left: -50%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.content-header .container {
  padding-bottom: 12px;
  padding-top: 12px;
}

#cookie-policy-panel {
  border-radius: 10px;
  bottom: 6px;
  left: 6px;
  overflow-y: auto;
  padding: 24px;
  position: fixed;
  right: 6px;
  top: 6px;
  z-index: 1030;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
#cookie-policy-panel:hover {
  border-color: #c6cad2;
}
#cookie-policy-panel:not(.open) .buttons-container .button:nth-of-type(-n+2) {
  min-width: 290px;
}
#cookie-policy-panel .back {
  display: none;
}
#cookie-policy-panel .logo-container {
  text-align: center;
}
#cookie-policy-panel .logo-container .headline {
  margin-top: 24px;
}
#cookie-policy-panel .buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
#cookie-policy-panel .buttons-container #confirm-selected-cookies {
  display: none;
}
#cookie-policy-panel .buttons-container .button {
  font-size: 14px;
  margin-top: 12px;
}
#cookie-policy-panel #cookie-policy-panel-options {
  margin-top: 24px;
}
#cookie-policy-panel #cookie-policy-panel-options .gdpr-cookies-policy-options {
  list-style: none outside;
  margin-bottom: 0;
}
#cookie-policy-panel #cookie-policy-panel-options .gdpr-cookies-policy-options li label {
  font-weight: 700;
  line-height: 1.45;
}
#cookie-policy-panel #cookie-policy-panel-options p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 375px) {
  #cookie-policy-panel {
    top: auto;
  }
  #cookie-policy-panel:not(.open) .buttons-container .button:nth-of-type(-n+2) {
    min-width: 325px;
  }
  #cookie-policy-panel .buttons-container .button {
    font-size: initial;
  }
}
@media (max-width: 939px) {
  #cookie-policy-panel #cookie-policy-panel-options {
    display: none;
  }
  #cookie-policy-panel.open {
    border-radius: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  #cookie-policy-panel.open #cookie-policy-panel-options {
    display: block;
  }
  #cookie-policy-panel.open .back {
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }
  #cookie-policy-panel.open .back::before {
    background: url("/assets/icon/right-arrow-gray.svg") 0 50% transparent no-repeat;
    background-size: 85%;
    content: " ";
    display: inline-block;
    height: 1.4rem;
    margin-right: 6px;
    margin-top: -0.3rem;
    transform: rotate(180deg);
    vertical-align: middle;
    width: 1.4rem;
  }
}
@media (min-width: 940px) {
  #cookie-policy-panel {
    align-items: center;
    bottom: 12px;
    display: flex;
    left: 5%;
    padding: 72px 48px;
    right: auto;
    width: 90%;
  }
  #cookie-policy-panel .logo-container {
    width: 100%;
  }
  #cookie-policy-panel .content-container {
    border-right: 2px solid #ededed;
    margin-right: 24px;
    padding-right: 24px;
  }
  #cookie-policy-panel #cookie-policy-panel-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  #cookie-policy-panel.open #cookie-policy-panel-options {
    max-height: 1000px;
  }
}
@media (min-width: 1200px) {
  #cookie-policy-panel {
    left: 50%;
    max-width: 1280px;
    padding: 108px 72px;
    transform: translateX(-50%);
  }
  #cookie-policy-panel .content-container {
    margin-right: 48px;
    padding-right: 48px;
  }
}
.alert {
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 12px 0;
  padding: 12px;
}
.alert.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert .list-unstyled {
  list-style: none;
  margin: 0;
}
.alert .list-unstyled li {
  margin: 0;
}

footer {
  background-color: #000000;
  color: #4d5156;
  margin-top: auto;
  overflow: auto;
  padding-top: 24px;
}
footer a {
  color: #ffffff;
  text-decoration: none;
}
footer a:hover {
  color: #dee0e2;
  text-decoration: underline;
}
footer .driveto-symbol {
  height: auto;
  max-width: 70%;
  width: 100%;
}
footer .footer-box.subscription .menu {
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}
footer .help-contacts:nth-of-type(1) {
  margin-bottom: 36px;
}
footer .help-contacts .title {
  color: #ffffff;
  display: block;
  margin-bottom: 4px;
}
footer .help-contacts .phone:nth-of-type(1) {
  margin-bottom: 24px;
}
footer .help-contacts a {
  display: block;
  font-size: 22px;
  font-weight: 700;
  padding: 8px 0;
}
footer .help-contacts span {
  display: block;
  margin-top: 4px;
}
footer .section-title {
  color: #4d5156;
  font-size: 22px;
  letter-spacing: 0.15rem;
  margin-bottom: 12px;
}
footer .bar {
  padding-bottom: 24px;
  padding-top: 24px;
}
footer .footer-menu-container .footer-box .section-title {
  font-weight: 700;
}
footer .footer-menu-container .footer-box:first-child {
  display: none;
}
footer .footer-menu-container .menu {
  list-style: none;
  margin-bottom: 24px;
}
footer .footer-menu-container .menu li {
  color: #ffffff;
  padding: 0;
}
footer .copyright {
  color: #ffffff;
  text-align: center;
}

#footer-social .footer-social-links {
  margin-bottom: 24px;
  text-align: left;
}
#footer-social .footer-social-links a {
  display: inline-block;
  margin-bottom: 12px;
  white-space: nowrap;
}
#footer-social .footer-social-links a:hover {
  text-decoration: underline;
}
#footer-social .footer-social-links a:not(:last-of-type) {
  margin-right: 12px;
}
#footer-social .footer-social-links a .icon {
  background-color: #4d5156;
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  margin-right: 6px;
  padding: 6px;
  width: 35px;
}
#footer-social .footer-social-links a svg {
  fill: #000000;
}

@media (min-width: 580px) {
  footer .footer-menu-container {
    display: flex;
    flex-wrap: wrap;
  }
  footer .footer-menu-container .footer-box {
    padding-right: 12px;
  }
}
@media (min-width: 580px) and (max-width: 999px) {
  footer {
    padding-top: 48px;
  }
  footer .footer-menu-container .footer-box {
    margin-left: 0;
    width: 50%;
  }
  footer .footer-menu-container .footer-box:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  footer {
    padding-top: 72px;
  }
  footer .footer-menu-container .footer-box:first-child {
    display: block;
    width: 10%;
  }
  footer .footer-menu-container .footer-box:nth-child(n+2) {
    margin-left: 2%;
    width: 28%;
  }
  footer .copyright {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-left: 12%;
    margin-right: 8%;
  }
  #footer-social .footer-social-links {
    margin-bottom: 0;
  }
  #footer-social .footer-social-links a:not(:last-of-type) {
    margin-right: 48px;
  }
  #footer-social .footer-social-links a .icon {
    margin-right: 12px;
  }
}
#header {
  background-color: #ffffff;
  border-bottom: 1px solid #ededed;
  position: relative;
  z-index: 600;
}
#header #menubar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 65px;
}
#header .customer-menu-back-link {
  border-right: 2px dashed #ededed;
  color: #ff3f2d;
  display: inline-block;
  flex: none;
  margin-left: 48px;
  padding: 24px 12px;
  text-decoration: underline;
}
#header .menu-toggle {
  background-color: #ff3f2d;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  line-height: 1.45;
  margin-left: auto;
  padding: 10px 8px;
  text-transform: uppercase;
}
#header .menu-toggle .bar {
  background-color: #ffffff;
  display: block;
  height: 0.3rem;
  margin-bottom: 0.5rem;
  position: relative;
  top: 0;
  width: 2.5rem;
  transition: transform 0.3s ease;
  will-change: transform;
}
#header .menu-toggle .bar:last-child {
  margin-bottom: 0;
}
#header .open-popup-checkbox:checked + .menu-toggle .bar:nth-child(2) {
  opacity: 0;
}
#header .open-popup-checkbox:checked + .menu-toggle .bar:first-child {
  top: 0.8rem;
  transform: rotate(45deg);
}
#header .open-popup-checkbox:checked + .menu-toggle .bar:last-child {
  top: -0.8rem;
  transform: rotate(-45deg);
}
#header .logo {
  display: block;
  flex: none;
  text-decoration: none;
  width: 108px;
}
#header .logo.large-screen {
  display: none;
}
#header .logo .logo-image {
  height: auto;
  max-width: 108px;
  width: 100%;
}
#header .customer-profile-label {
  background-color: #000000;
  border-radius: 24px;
  color: #ffffff;
  font-size: 85%;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1;
  margin-top: 4px;
  padding: 2px 12px;
  text-align: center;
  text-transform: uppercase;
}
#header .compare-offers-link,
#header .favorite-offers-link {
  line-height: 1;
  position: relative;
}
#header .compare-offers-link .items-count,
#header .favorite-offers-link .items-count {
  align-items: center;
  background-color: #ff3f2d;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  content: "";
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: -12px;
  top: -6px;
  width: 2rem;
  z-index: 5;
}
#header .compare-offers-link .tooltip-label,
#header .favorite-offers-link .tooltip-label {
  color: #3c3f44;
  margin-bottom: 12px;
  margin-right: 6px;
}
#header .compare-offers-link .compare-link,
#header .compare-offers-link .favorite-link,
#header .favorite-offers-link .compare-link,
#header .favorite-offers-link .favorite-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
}
#header .compare-offers-link .compare-link.hidden,
#header .compare-offers-link .favorite-link.hidden,
#header .favorite-offers-link .compare-link.hidden,
#header .favorite-offers-link .favorite-link.hidden {
  display: none;
}
#header .compare-offers-link .compare-link::after,
#header .compare-offers-link .favorite-link::after,
#header .favorite-offers-link .compare-link::after,
#header .favorite-offers-link .favorite-link::after {
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 3rem;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 3rem;
}
#header .favorite-offers-link {
  margin-right: 6px;
}
#header .favorite-offers-link .favorite-link::after {
  background: url("/assets/icon/favorite-add.svg") 50% 50% no-repeat #ffffff;
  background-size: 65%;
}
#header .compare-offers-link .compare-link::after {
  background: url("/assets/icon/compare-add.svg") 50% 50% no-repeat #ffffff;
  background-size: 65%;
}

#contacts-menu-container {
  display: none;
}
#contacts-menu-container .contacts-menu {
  align-items: center;
  display: flex;
  margin-left: auto;
}
#contacts-menu-container .email-contact,
#contacts-menu-container .phone-contact {
  margin-right: 12px;
}
#contacts-menu-container .email-contact a,
#contacts-menu-container .phone-contact a {
  font-size: 12px;
  text-decoration: none;
}
#contacts-menu-container .phone-contact .link {
  color: #3c3f44;
}
#contacts-menu-container .phone-contact .link::before {
  background: url("/assets/icon/headset.svg") 50% 50% no-repeat transparent;
  content: " ";
  display: inline-block;
  height: 2rem;
  vertical-align: middle;
  width: 2rem;
}
#contacts-menu-container .email-contact .link {
  color: #3c3f44;
}
#contacts-menu-container .email-contact .link::before {
  background: url("/assets/icon/envelope.svg") 50% 50% no-repeat transparent;
  content: " ";
  display: inline-block;
  height: 2.3rem;
  vertical-align: middle;
  width: 2.3rem;
}

#main-menu {
  bottom: 0;
  display: none;
  left: 0;
  padding-bottom: 24px;
  position: fixed;
  right: 0;
  top: 65px;
  z-index: 700;
}
#main-menu .homepage-icon {
  display: none;
}
#main-menu .homepage-icon img {
  filter: brightness(0%);
  transition: filter 0.3s ease;
}
#main-menu .homepage-text {
  display: inline-block;
}
#main-menu .main-menu {
  background-color: #ffffff;
  clear: both;
  list-style: none;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
#main-menu .main-menu > li {
  display: block;
  margin: 0;
}
#main-menu .main-menu > li > a {
  color: #000000;
  display: block;
  line-height: 2.3rem;
  padding: 12px 24px;
  text-decoration: none;
}
#main-menu .main-menu > li.current-menu-item > a {
  color: #ff3f2d;
}
#main-menu .main-menu > li.current-menu-item > a .homepage-icon img {
  filter: none;
}
#main-menu .main-menu > li:first-of-type:hover .homepage-icon img {
  filter: none;
}

@media (max-width: 1024px) {
  #header .main-menu-container {
    align-items: center;
    display: flex;
  }
  #header .customer-menu-back-link.large-screen {
    display: none;
  }
  #header .customer-menu-back-link.small-screen {
    border-right: 0;
    display: inline-block;
    margin-left: 12px;
  }
  #header #main-menu {
    background-color: #ededee;
    text-align: center;
    transition: visibility, opacity 0.3s;
  }
  #header #main-menu .user-menu-container {
    padding: 12px 24px;
  }
  #header .menu-toggle.open {
    border-right: 1px solid #ededed;
    padding: 8px;
  }
  #header #open-menu:checked ~ #main-menu {
    display: block;
    overflow: auto;
  }
  #header .compare-offers-link,
  #header .favorite-offers-link {
    margin: 6px 0;
  }
}
@media (min-width: 640px) {
  #main-menu .main-menu > li > a {
    padding: 12px 48px;
  }
}
@media (min-width: 1025px) {
  #header {
    background: linear-gradient(to bottom, #ededee 0%, #ededee 50%, #ffffff 50%, #ffffff 100%);
  }
  #header .customer-menu-back-link {
    padding: 24px;
  }
  #header .customer-menu-back-link.small-screen {
    display: none;
  }
  #header .logo.small-screen {
    display: none;
  }
  #header .logo.large-screen {
    display: block;
  }
  #header .logo .logo-image {
    max-width: 130px;
  }
  #header .menu-toggle {
    display: none;
  }
  #header .compare-offers-link .tooltip,
  #header .compare-offers-link .compare-link,
  #header .compare-offers-link .favorite-link,
  #header .favorite-offers-link .tooltip,
  #header .favorite-offers-link .compare-link,
  #header .favorite-offers-link .favorite-link {
    background-color: #ffffff;
    border-radius: 15px;
    height: 40px;
    line-height: 1.45;
    padding: 8px 5px;
    width: 40px;
  }
  #header .compare-offers-link .tooltip-label,
  #header .favorite-offers-link .tooltip-label {
    display: none;
  }
  #header .compare-offers-link .items-count,
  #header .favorite-offers-link .items-count {
    right: -6px;
    top: -4px;
  }
  #header .compare-offers-link {
    border-left: 0;
  }
  #main-menu {
    align-items: center;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 65px;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 500;
  }
  #main-menu .break {
    flex-basis: 100%;
    height: 0;
    order: 3;
    width: 100%;
  }
  #main-menu #contacts-menu-container {
    align-items: center;
    display: flex;
    flex: 1;
    min-height: 71px;
    order: 1;
  }
  #main-menu .logo {
    order: 4;
  }
  #main-menu .homepage-icon {
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  #main-menu .homepage-text {
    display: none;
  }
  #main-menu .customer-menu-back-link {
    order: 5;
  }
  #main-menu #price-switch-container {
    margin-left: auto;
    min-width: 225px;
    order: 7;
  }
  #main-menu .user-menu-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    order: 2;
  }
  #main-menu .main-menu {
    align-items: flex-end;
    box-shadow: none;
    display: flex;
    flex: 1;
    margin: 0;
    min-width: 400px;
    order: 6;
    padding-left: 24px;
  }
  #main-menu .main-menu > li {
    position: relative;
    white-space: nowrap;
  }
  #main-menu .main-menu > li > a {
    display: inline-block;
    margin-bottom: 0;
    padding: 24px 12px;
    text-decoration: none;
    transition: color 0.3s ease, background 0.3s ease;
  }
  #main-menu .main-menu > li > a:hover {
    color: #ff3f2d;
  }
  #main-menu .main-menu > li.current-menu-item > a {
    position: relative;
  }
  #main-menu .main-menu > li.current-menu-item > a::after {
    background-color: #ff3f2d;
    border-radius: 2px;
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 3px;
    left: 12px;
    position: absolute;
    right: 12px;
  }
}
@media (min-width: 1180px) {
  #main-menu .main-menu {
    padding-left: 48px;
  }
  #main-menu .main-menu > li {
    position: relative;
    white-space: nowrap;
  }
  #main-menu .main-menu > li > a {
    padding: 24px;
  }
  #main-menu .main-menu > li.current-menu-item > a::after {
    left: 24px;
    right: 24px;
  }
}
@media (min-width: 375px) {
  #header .customer-menu-back-link::before {
    background: url("/assets/icon/how-it-works-right-arrow.svg") 0 50% transparent no-repeat;
    background-size: 100%;
    content: " ";
    display: inline-block;
    height: 1.4rem;
    margin-right: 6px;
    margin-top: -0.3rem;
    transform: rotate(180deg);
    vertical-align: middle;
    width: 1.4rem;
  }
}
a {
  background-color: transparent;
  color: #ff3f2d;
  outline: none;
  transition: color 0.15s ease;
}
a:hover {
  color: #b52c17;
}
a.inverse {
  color: #ffffff;
}
a.inverse:hover {
  color: #dee0e2;
}

ul {
  list-style: circle inside;
}

ol {
  list-style: decimal inside;
}

ol,
ul {
  line-height: 1.7;
  margin-top: 0;
  padding-left: 0;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin: 1.5rem 0 1.5rem 3rem;
}

li {
  margin-bottom: 1rem;
}

#login-info-box {
  margin: 24px 0;
  text-align: center;
}
#login-info-box .login-link {
  box-shadow: none;
  display: inline-block;
  margin-top: 12px;
  max-width: 370px;
  text-decoration: none;
  width: 100%;
}
#login-info-box .login-link::before {
  background-color: transparent;
  background-image: url("/assets/icon/user-white.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  vertical-align: middle;
  width: 2rem;
}
#login-info-box .login-link .user-login-label {
  line-height: 2;
}
#login-info-box > .user-menu-dropdown {
  height: 100%;
  margin: 0 auto;
  max-width: 600px;
  position: relative;
}
#login-info-box > .user-menu-dropdown #user-menu {
  display: none;
}
#login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container {
  display: none;
}
#login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .logged-in-label {
  display: none;
}
#login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .logged-in-label::before {
  background-color: transparent;
  background-image: url("/assets/icon/user-white.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 80%;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 2rem;
}
#login-info-box > .user-menu-dropdown .user-menu {
  border-radius: 10px;
  display: block;
  left: 0;
  list-style: none;
  margin-bottom: 0;
  margin-top: 12px;
  min-width: 270px;
  padding: 12px 12px 4px;
  position: relative;
  right: -1px;
  text-align: left;
  top: 100%;
  z-index: 601;
  background-color: #ffffff;
  border: 1px solid #ededed;
}
#login-info-box > .user-menu-dropdown .user-menu .title {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
#login-info-box > .user-menu-dropdown .user-menu .username-info {
  color: #000000;
}
#login-info-box > .user-menu-dropdown .user-menu .username-info .username {
  display: block;
  margin-bottom: 8px;
  text-align: center;
  white-space: nowrap;
}
#login-info-box > .user-menu-dropdown .user-menu .username-info .button-primary {
  display: block;
}
#login-info-box > .user-menu-dropdown .user-menu .logout {
  border-top: 1px solid #ededed;
  margin-top: 12px;
  padding-top: 12px;
}
#login-info-box > .user-menu-dropdown .user-menu .logout .button {
  width: 100%;
}
#login-info-box > .user-menu-dropdown .user-menu .deal-list {
  margin-top: 24px;
}
#login-info-box > .user-menu-dropdown input[type=checkbox] + #user-menu-dropdown-label-container::after, #login-info-box > .user-menu-dropdown input[type=checkbox] + #user-menu-dropdown-label-container::before {
  display: none;
}
#login-info-box > .user-menu-dropdown input[type=checkbox]:checked ~ .user-menu {
  display: block;
  margin-top: 10px;
  animation: fadeIn 0.3s ease-out;
  will-change: opacity;
}
#login-info-box > .user-menu-dropdown input[type=checkbox]:checked + #user-menu-dropdown-label-container {
  color: #3c3f44;
}

@media (min-width: 1025px) {
  #login-info-box {
    background-color: #ff3f2d;
    border-radius: 15px;
    margin-bottom: 0;
    margin-left: 12px;
    margin-top: 0;
  }
  #login-info-box #user-menu-dropdown-label-container {
    cursor: pointer;
  }
  #login-info-box .login-link {
    background: none;
    border: 0;
    color: #ff3f2d;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    margin-top: 0;
    min-height: 40px;
    text-transform: none;
    vertical-align: middle;
  }
  #login-info-box .login-link .large-screen-hidden {
    display: none;
  }
  #login-info-box .login-link::before {
    margin-right: 0;
  }
  #login-info-box > .user-menu-dropdown .user-menu {
    display: none;
    left: auto;
    margin-top: 0;
    padding: 24px 24px 12px;
    position: absolute;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  #login-info-box > .user-menu-dropdown .user-menu .username-info {
    align-items: center;
    display: flex;
  }
  #login-info-box > .user-menu-dropdown .user-menu .username-info .username {
    margin-bottom: 0;
    width: 100%;
  }
  #login-info-box > .user-menu-dropdown .user-menu .username-info .button-primary {
    margin-left: 12px;
    margin-top: 0;
  }
  #login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container {
    display: block;
  }
  #login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .logged-in-label {
    color: #ffffff;
    display: inline-block;
    padding: 10px 12px;
    white-space: nowrap;
  }
  #login-info-box > .user-menu-dropdown #user-menu-dropdown-label-container .label {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.main-menu-phone-request-container,
.main-menu-email-request-container {
  padding: 12px;
}
.main-menu-phone-request-container .form-container,
.main-menu-email-request-container .form-container {
  margin-top: 24px;
}
.main-menu-phone-request-container .form-container .form-flex-row,
.main-menu-email-request-container .form-container .form-flex-row {
  align-items: flex-start;
  flex-wrap: nowrap;
}
.main-menu-phone-request-container .form-container .form-row.button-row,
.main-menu-email-request-container .form-container .form-row.button-row {
  text-align: center;
}
.main-menu-phone-request-container .form-container .inner-addon,
.main-menu-email-request-container .form-container .inner-addon {
  display: flex;
  flex-direction: column;
  position: relative;
}
.main-menu-phone-request-container .form-container .inner-addon.left-addon input,
.main-menu-email-request-container .form-container .inner-addon.left-addon input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-left: 6rem;
}
.main-menu-phone-request-container .form-container .inner-addon .phone-prefix,
.main-menu-email-request-container .form-container .inner-addon .phone-prefix {
  align-items: center;
  border-right: 1px solid #ededed;
  color: #c6ccd7;
  display: flex;
  font-weight: 500;
  height: 39px;
  padding: 0 0.8rem;
  position: absolute;
  top: 1px;
}
@media (min-width: 940px) {
  .main-menu-phone-request-container .form-container .inner-addon .phone-prefix,
  .main-menu-email-request-container .form-container .inner-addon .phone-prefix {
    height: 36px;
  }
}
.main-menu-phone-request-container .form-container .button-primary,
.main-menu-email-request-container .form-container .button-primary {
  min-width: 125px;
}
.main-menu-phone-request-container .form-container .button-primary.main-menu-phone-save,
.main-menu-email-request-container .form-container .button-primary.main-menu-phone-save {
  border: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 9.5px 12px;
}
.main-menu-phone-request-container p,
.main-menu-email-request-container p {
  text-align: center;
}
.main-menu-phone-request-container .response-message,
.main-menu-email-request-container .response-message {
  font-size: 2rem;
  font-weight: 500;
  margin: 48px 0;
  text-align: center;
}
.main-menu-phone-request-container .response-message.success,
.main-menu-email-request-container .response-message.success {
  color: #2dbd58;
}
.main-menu-phone-request-container .response-message.error,
.main-menu-email-request-container .response-message.error {
  color: #ec5340;
}
.main-menu-phone-request-container .opening-hours,
.main-menu-email-request-container .opening-hours {
  align-items: center;
  display: flex;
  margin-top: 24px;
}
.main-menu-phone-request-container .opening-hours .link,
.main-menu-email-request-container .opening-hours .link {
  font-weight: 500;
}
.main-menu-phone-request-container .opening-hours .hours,
.main-menu-email-request-container .opening-hours .hours {
  font-weight: 500;
}
.main-menu-phone-request-container .opening-hours::before,
.main-menu-email-request-container .opening-hours::before {
  background-color: #ffffff;
  background-image: url("/assets/icon/opening-hours.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 70%;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  flex: none;
  height: 4rem;
  margin-right: 12px;
  width: 4rem;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

@media (min-width: 540px) {
  .main-menu-phone-request-container,
  .main-menu-email-request-container {
    padding: 24px;
  }
}
@media (min-width: 940px) {
  #main-menu-email-modal .modal-content,
  #main-menu-phone-modal .modal-content {
    bottom: auto;
    max-height: 85vh;
  }
  .main-menu-phone-request-container,
  .main-menu-email-request-container {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 48px;
    background-color: #ffffff;
    border: 1px solid #ededed;
    transition: border-color 0.3s ease;
  }
  .main-menu-phone-request-container:hover,
  .main-menu-email-request-container:hover {
    border-color: #c6cad2;
  }
  .main-menu-phone-request-container .form-container,
  .main-menu-email-request-container .form-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .main-menu-phone-request-container .form-container.email-form,
  .main-menu-email-request-container .form-container.email-form {
    flex-direction: column;
  }
  .main-menu-phone-request-container .form-container.email-form .form-row,
  .main-menu-email-request-container .form-container.email-form .form-row {
    min-width: 400px;
  }
  .main-menu-phone-request-container .form-container .button-primary,
  .main-menu-email-request-container .form-container .button-primary {
    padding: 6px 24px;
  }
  .main-menu-phone-request-container .opening-hours,
  .main-menu-email-request-container .opening-hours {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.modal {
  -webkit-overflow-scrolling: touch;
  background-color: #ffffff;
  bottom: 0;
  left: -100%;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  will-change: left;
  z-index: 610;
  transition: left 0.3s ease;
  z-index: 1020;
}
.modal.open {
  left: 0;
}

.modal-content {
  background-color: #ffffff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  right: 0;
  top: 0;
}
.modal-content .close-modal {
  display: inline-block;
  margin-bottom: 12px;
}
.modal-content .close-modal img {
  display: none;
}
.modal-content .modal-header {
  flex: none;
  padding-bottom: 12px;
  padding-top: 12px;
}
.modal-content .modal-header .modal-headline {
  text-align: center;
  font-size: 24px;
}
@media (min-width: 640px) {
  .modal-content .modal-header .modal-headline {
    font-size: 32px;
  }
}
.modal-content .modal-body {
  margin-right: -12px;
  overflow-y: auto;
  padding-right: 12px;
}
.modal-content .modal-footer {
  background-color: #ffffff;
  display: block;
  margin-bottom: -18px;
  padding: 12px;
  position: relative;
  text-align: center;
}
.modal-content .modal-footer::before {
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 100%;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  right: 4px;
  z-index: 10;
}
.modal-content .modal-footer .modal-footer-scroll {
  color: #ff3f2d;
}
.modal-content .modal-footer .modal-footer-scroll::before {
  animation: jump 1.5s ease 0s infinite normal;
  background: url("/assets/icon/arrow-up.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.3rem;
  margin-right: 6px;
  vertical-align: middle;
  width: 1.3rem;
}

@media (max-width: 939px) {
  .modal {
    transition: left 0.3s ease;
  }
}
@media (min-width: 940px) {
  .modal {
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    height: 100%;
    left: 0;
    overflow: auto;
    width: 100%;
    animation: fadeIn 0.3s ease-out;
    will-change: opacity;
  }
  .modal.open {
    display: block;
  }
  .modal-content {
    border-radius: 10px;
    bottom: 48px;
    margin: 0 auto;
    max-width: 940px;
    padding: 48px 42px 36px 48px;
    top: 48px;
    width: 80%;
  }
  .modal-content .close-modal {
    cursor: pointer;
    padding: 24px;
    position: absolute;
    right: 4px;
    top: 0;
  }
  .modal-content .close-modal:hover img {
    transform: rotate(90deg);
  }
  .modal-content .close-modal img {
    display: block;
    width: 24px;
    transition: transform 0.3s ease;
    will-change: transform;
  }
  .modal-content .close-modal span {
    display: none;
  }
  .modal-content .modal-header {
    min-height: 40px;
    padding-top: 0;
  }
  .modal-content .modal-body {
    padding-right: 18px;
  }
}
@keyframes jump {
  0% {
    transform: translateY(0) rotate(180deg);
  }
  25% {
    transform: translateY(-2px) rotate(180deg);
  }
  50% {
    transform: translateY(0) rotate(180deg);
  }
  75% {
    transform: translateY(2px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(180deg);
  }
}
#price-switch-container {
  padding: 24px 0;
}
#price-switch-container .price-switch-label {
  align-items: center;
  color: #3c3f44;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  justify-content: center;
  padding: 3px 0;
}
#price-switch-container .switch {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin-right: 6px;
  position: relative;
  width: 55px;
}
#price-switch-container .switch .slider {
  background-color: #c6cad2;
  border-radius: 30px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
}
#price-switch-container .switch .slider::before {
  background-color: #ffffff;
  border-radius: 50%;
  bottom: 3px;
  content: "";
  height: 24px;
  left: 5px;
  position: absolute;
  transition: 0.3s;
  width: 24px;
}
#price-switch-container .switch .slider::after {
  background: url("/assets/icon/check-ok-orange.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  bottom: 3px;
  content: "";
  filter: grayscale(1);
  height: 24px;
  left: 5px;
  opacity: 0.3;
  position: absolute;
  transition: 0.3s;
  width: 24px;
}
#price-switch-container .switch input {
  display: none;
}
#price-switch-container .switch input:checked + .slider {
  background-color: #ff3f2d;
}
#price-switch-container .switch input:checked + .slider::before {
  transform: translateX(22px);
}
#price-switch-container .switch input:checked + .slider::after {
  filter: none;
  opacity: 1;
  transform: translateX(22px);
}

@media (min-width: 1025px) {
  #price-switch-container {
    flex: none;
    padding: 0;
  }
  #price-switch-container .switch .slider {
    background-color: #ededee;
  }
}
.small-screen-menu-container {
  background-color: #ffffff;
  border-top: 2px solid #ededed;
  position: relative;
}
.small-screen-menu-container .menu {
  list-style: none;
  margin: 0;
}
.small-screen-menu-container .menu > li {
  display: inline-block;
  margin: 0;
  white-space: nowrap;
}
.small-screen-menu-container .menu > li > a {
  color: #000000;
  display: inline-block;
  padding: 8px 6px;
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease;
}
.small-screen-menu-container .menu > li > a .homepage-icon {
  filter: brightness(0%);
  margin-top: -0.3rem;
}
.small-screen-menu-container .menu > li > a:focus, .small-screen-menu-container .menu > li > a:hover {
  color: #ff3f2d;
}
.small-screen-menu-container .menu > li.current-menu-item > a {
  color: #ff3f2d;
}
.small-screen-menu-container .menu > li.current-menu-item > a::after {
  border-bottom: 3px solid #ff3f2d;
  border-radius: 2px;
  bottom: -2px;
  content: "";
  display: inline-block;
  left: 6px;
  position: absolute;
  right: 6px;
  z-index: 601;
}
.small-screen-menu-container .menu > li.current-menu-item > a .homepage-icon {
  filter: none;
}
.small-screen-menu-container .small-device-hidden {
  display: none;
}

@media (min-width: 375px) {
  .small-screen-menu-container .container {
    padding: 0;
  }
  .small-screen-menu-container .small-device-hidden {
    display: inline;
  }
  .small-screen-menu-container .menu > li > a {
    padding: 8px 12px 6px;
  }
  .small-screen-menu-container .menu > li.current-menu-item > a::after {
    left: 12px;
    right: 12px;
  }
}
@media (min-width: 1025px) {
  .small-screen-menu-container {
    display: none;
  }
}
html.displayWithoutVat .withVat {
  display: none;
}
html.displayWithVat .withoutVat {
  display: none;
}

.car-info-container:not(:last-of-type) {
  margin-bottom: 24px;
}
.car-info-container .car-info-description {
  border: 1px solid #ededed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 24px;
}
.car-info-container .car-info-description::before {
  background: url("/assets/icon/user/info-admin.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: block;
  flex: none;
  height: 2rem;
  margin-right: 8px;
  width: 2rem;
}
.car-info-container .hidden-content .car-offer-info:last-of-type {
  margin-bottom: 24px;
}
.car-info-container .car-offer-info {
  align-items: flex-start;
  border: 1px solid #ededed;
  border-radius: 20px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
}
.car-info-container .car-offer-info:not(:last-of-type) {
  margin-bottom: 24px;
}
.car-info-container .car-offer-info .subtitle {
  display: block;
  font-size: 14px;
  font-weight: 400;
  font-family: DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.car-info-container .car-offer-info .car-offer-image {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  width: 100%;
}
.car-info-container .car-offer-info .car-offer-image .car-image {
  border-radius: inherit;
  height: 100%;
  min-width: 80px;
  object-fit: cover;
  width: 100%;
}
.car-info-container .car-offer-info .car-info {
  padding: 12px;
  width: 100%;
}
.car-info-container .car-offer-info .car-info .claim-label .tooltip {
  text-align: right;
}
.car-info-container .car-offer-info .car-info .licence-plate-container {
  color: #000000;
  font-size: 85%;
  margin-top: 12px;
}
.car-info-container .car-offer-info .car-info .licence-plate-container .licence-plate {
  font-weight: 700;
  letter-spacing: 1px;
}
.car-info-container .car-offer-info .car-info .inquiry-for-container {
  color: #000000;
}
.car-info-container .car-offer-info.inquired-car .price-box {
  margin-bottom: 12px;
}
.car-info-container .car-offer-info.inquired-car .price-box:nth-of-type(1) {
  background-color: #e0f5e6;
}
.car-info-container .car-offer-info.inquired-car .price-box:nth-of-type(2) {
  background-color: #ededee;
}
.car-info-container .car-offer-info.subscribed-car {
  background-color: #ededee;
}
.car-info-container .car-offer-info.subscribed-car .prices-container {
  background-color: #ffffff;
  border-radius: 20px;
}
.car-info-container .car-offer-info.subscribed-car .price-box {
  border-radius: 0;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) {
  border-bottom: 1px dashed #ededed;
  position: relative;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .payment-label,
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .claim-label {
  margin-top: 6px;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) {
  text-align: center;
}
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .payment-label,
.car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .claim-label {
  display: none;
}
.car-info-container .prices-container {
  margin-bottom: 12px;
  margin-top: 12px;
}
.car-info-container .price-box {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
}
.car-info-container .price-box:nth-of-type(1) .price:nth-of-type(1) {
  color: #2dbd58;
}
.car-info-container .price-box:nth-of-type(1) .price:nth-of-type(1) .vat-info {
  font-size: 14px;
}
.car-info-container .price-box:nth-of-type(2) .price:nth-of-type(1) {
  color: #000000;
}
.car-info-container .price-box .price-title {
  color: #000000;
  margin-bottom: 6px;
}
.car-info-container .price-box .price-offer-price {
  white-space: nowrap;
}
.car-info-container .price-box .price-offer-price .vat-info {
  color: #3c3f44;
  font-size: 14px;
  font-weight: 400;
}
.car-info-container .price-box .price-offer-price .price:nth-of-type(1) {
  font-size: 18px;
  font-weight: 700;
}
.car-info-container .price-box .price-offer-price .price:nth-of-type(2) {
  font-size: 12px;
}
.car-info-container .price-box .price-offer-price .price:nth-of-type(2) .vat-info {
  font-size: 12px;
}
.car-info-container .button-container .button {
  width: 100%;
}
.car-info-container .button-container .button:nth-of-type(1) {
  margin-bottom: 6px;
}
.car-info-container .car-offer-info-footer {
  border-top: 1px dashed #ededed;
  font-size: 85%;
  padding-top: 12px;
  text-align: center;
}

@media (min-width: 375px) {
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .payment-label {
    margin-top: 0;
    position: absolute;
    right: 12px;
    top: 36px;
  }
}
@media (min-width: 390px) {
  .car-info-container .car-offer-info.inquired-car .prices-container {
    display: flex;
  }
  .car-info-container .car-offer-info.inquired-car .price-box {
    margin-bottom: 0;
    margin-left: 2%;
    width: 49%;
  }
  .car-info-container .car-offer-info.inquired-car .price-box:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  .car-info-container .car-offer-info {
    padding: 24px;
  }
  .car-info-container .car-offer-info .car-info {
    margin-left: 3%;
    padding: 0;
    width: 54%;
  }
  .car-info-container .car-offer-info .car-info .licence-plate-container {
    font-size: 14px;
  }
  .car-info-container .car-offer-info .car-offer-image {
    border-radius: 20px;
    height: 255px;
    width: 43%;
  }
  .car-info-container .car-offer-info .services-container {
    order: 4;
    padding: 24px;
  }
  .car-info-container .car-offer-info .services-container.deal-parameters {
    margin-top: 48px;
  }
  .car-info-container .car-offer-info .services-container .services {
    column-count: 2;
    font-size: 14px;
  }
  .car-info-container .car-offer-info.subscribed-car .prices-container {
    display: flex;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box {
    margin-left: 0;
    width: 50%;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-child(2n+1) {
    margin-left: 0;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) {
    border-bottom: 0;
    border-right: 2px dashed #ededed;
    text-align: left;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .payment-label,
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(1) .claim-label {
    display: none;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) {
    align-items: flex-end;
    text-align: right;
  }
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .payment-label,
  .car-info-container .car-offer-info.subscribed-car .price-box:nth-of-type(2) .claim-label {
    display: flex;
  }
  .car-info-container .price-box {
    padding: 24px;
  }
  .car-info-container .price-box .price-offer-price .price:nth-of-type(1) {
    font-size: 24px;
  }
  .car-info-container .price-box .price-offer-price .price:nth-of-type(2) {
    font-size: 14px;
  }
  .car-info-container .price-box .price-offer-price .price:nth-of-type(2) .vat-info {
    font-size: 14px;
  }
  .car-info-container .button-container {
    display: flex;
  }
  .car-info-container .button-container .button {
    width: auto;
  }
  .car-info-container .button-container .button:nth-of-type(1) {
    margin-bottom: 0;
  }
  .car-info-container .button-container .button:nth-of-type(2) {
    margin-left: 12px;
  }
  .car-info-container .car-offer-info-footer {
    border-top: 0;
    font-size: 14px;
    text-align: left;
  }
}
.user-profile-no-content {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 20px;
  margin-bottom: 24px;
  margin-top: 48px;
  padding: 24px;
  position: relative;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
.user-profile-no-content .title {
  margin-right: 72px;
}
.user-profile-no-content .button-primary {
  white-space: normal;
}
.user-profile-no-content .icon {
  border-radius: 50%;
  height: 4rem;
  padding: 8px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 4rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 940px) {
  .user-profile-no-content.no-inquiries {
    margin-top: 72px;
  }
}
.admin-header {
  background-color: #ededee;
}
.admin-header .admin-leasing-info {
  margin-top: 12px;
}
.admin-header .admin-leasing-info .admin-leasing-info-box {
  display: flex;
  justify-content: space-between;
}
.admin-header .admin-leasing-info .subscription-car-list-filter {
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  margin-bottom: 24px;
  padding: 12px;
  width: 100%;
}
.admin-header .admin-leasing-info .subscription-car-list-filter select {
  margin-left: 12px;
  max-width: 200px;
}
.admin-header .admin-leasing-info .subscription-car-list {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status {
  margin-left: 1rem;
  margin-top: 1rem;
  max-width: 300px;
  min-width: 250px;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .list {
  list-style: none;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .list-item {
  font-size: 85%;
  margin-bottom: 0;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .list-item .location-driveto-showroom {
  background-color: #e0f5e6;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .list-item .reservation-label {
  background-color: #ffc800;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button {
  border-radius: 10px;
  color: #3c3f44;
  display: inline-block;
  margin: 0 0 2px;
  padding: 4px 8px;
  text-decoration: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ededed;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color {
  border: 1px solid #ededed;
  border-radius: 50%;
  display: inline-block;
  height: 1.5rem;
  margin-right: 4px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.5rem;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-black {
  background-color: #000000;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-blue {
  background-color: #0000ff;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-brown {
  background-color: #800000;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-gray {
  background-color: #808080;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-green {
  background-color: #008000;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-orange {
  background-color: #e67e22;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-pink {
  background-color: #ff00ff;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-purple {
  background-color: #800080;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-red {
  background-color: #ff0000;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-silver {
  background-color: #c0c0c0;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-white {
  background-color: #ffffff;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-yellow {
  background-color: #f4d03f;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .color.color-multicolor {
  background: linear-gradient(#00ffff, transparent), linear-gradient(-45deg, #ff00ff, transparent), linear-gradient(45deg, #f4d03f, transparent);
  background-blend-mode: multiply;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .label {
  color: #3c3f44;
  display: flex;
  font-size: 85%;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button .label .title {
  display: inline-block;
  font-weight: 700;
  min-width: 125px;
  text-transform: uppercase;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button:hover {
  background-color: #ededed;
  border-color: #c6cad2;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button.active {
  background-color: #ebccd1;
  border-color: #ebccd1;
}
.admin-header .admin-leasing-info .subscription-car-list .car-list-by-status .admin-carlist-button.active:hover {
  background-color: #dda7b0;
}
.admin-header .admin-leasing-info .subscription-car-list .show-more {
  display: block;
  margin: 0 auto;
}
.admin-header .container {
  margin-top: 12px;
}
.admin-header .container:empty {
  display: none;
}

@media (min-width: 640px) and (max-width: 939px) {
  .admin-header .admin-leasing-info .subscription-car-list {
    display: flex;
  }
  .admin-header .admin-leasing-info .subscription-car-list .car-list-by-status {
    margin-left: 1%;
    width: 49.5%;
  }
  .admin-header .admin-leasing-info .subscription-car-list .car-list-by-status:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) and (max-width: 1199px) {
  .admin-header .admin-leasing-info .subscription-car-list {
    display: flex;
  }
  .admin-header .admin-leasing-info .subscription-car-list .car-list-by-status {
    margin-left: 1%;
    width: 32.6666666667%;
  }
  .admin-header .admin-leasing-info .subscription-car-list .car-list-by-status:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .admin-header .admin-leasing-info .subscription-car-list {
    display: flex;
  }
  .admin-header .admin-leasing-info .subscription-car-list .car-list-by-status {
    margin-left: 0.5%;
    width: 19.6%;
  }
  .admin-header .admin-leasing-info .subscription-car-list .car-list-by-status:nth-child(5n+1) {
    margin-left: 0;
  }
}
.admin-leasing-info {
  background-color: #ededee;
  padding: 0.1rem 6px;
}
.admin-leasing-info ul,
.admin-leasing-info li {
  margin: 0;
}

.header-link-only {
  margin-top: 12px;
}
.header-link-only > a {
  display: none;
  font-size: 14px;
}

ol.breadcrumb {
  color: #3c3f44;
  font-size: 85%;
  list-style: none;
  margin-bottom: 0;
  overflow: auto;
  padding-bottom: 12px;
  position: relative;
  white-space: nowrap;
}
ol.breadcrumb li {
  display: inline-block;
  margin-bottom: 0;
}
ol.breadcrumb li .separator {
  display: inline-block;
  padding: 0 4px;
}
ol.breadcrumb li a {
  color: #3c3f44;
}
ol.breadcrumb li:last-child a {
  pointer-events: none;
  text-decoration: none;
}
@media (max-width: 470px) {
  ol.breadcrumb li:not(:last-child) a {
    display: inline-block;
    max-width: 120px;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.header-breadcrumbs-only {
  padding-bottom: 12px;
  padding-top: 12px;
}

@media (min-width: 940px) {
  .header-link-only > a {
    display: block;
  }
  ol.breadcrumb {
    padding-bottom: 0;
  }
}
dl.car-data-list {
  overflow: auto;
}
dl.car-data-list dt,
dl.car-data-list dd {
  display: block;
  float: left;
  margin: 0.5rem 0;
  width: 50%;
}
dl.car-data-list dt {
  clear: left;
}

body:not(.comparator-panel-visible) .comparator-panel-container {
  display: none;
}
body.comparator-panel-visible .comparator-panel-container {
  display: block;
}

.comparator-panel-container {
  background-color: #ededee;
  border-top: 1px solid #ededed;
  bottom: 0;
  left: 0;
  padding: 8px 0 18px;
  position: fixed;
  right: 0;
  transform: translateZ(0);
  z-index: 699;
}
.comparator-panel-container.highlight {
  animation: highlight 1s;
}
.comparator-panel-container .comparator-panel {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.comparator-panel-container .comparator-panel .large-screen {
  display: none;
}
.comparator-panel-container .comparator-panel .small-screen {
  display: none;
}
.comparator-panel-container .buttons-wrapper .button-light {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 2px;
  padding-bottom: 0;
}
.comparator-panel-container .buttons-wrapper span {
  display: none;
}
.comparator-panel-container .comparator-offer-count {
  background-color: #ff3f2d;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  flex: none;
  font-weight: 700;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-left: 8px;
  text-align: center;
  width: 2.5rem;
}
.comparator-panel-container .comparator-link {
  margin-left: 24px;
  margin-right: 12px;
}
.comparator-panel-container .comparator-link::before {
  background-color: transparent;
  background-image: url("/assets/icon/compare-add.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 1.5rem auto;
  content: " ";
  display: inline-block;
  height: 1.5rem;
  margin-right: 2px;
  margin-top: -0.2rem;
  vertical-align: middle;
  width: 1.5rem;
}
.comparator-panel-container .comparator-remove-all-offers {
  border-color: #3c3f44;
  color: #3c3f44;
}
.comparator-panel-container .comparator-remove-all-offers::before {
  background-color: transparent;
  background-image: url("/assets/icon/close.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 1.5rem auto;
  content: " ";
  display: inline-block;
  height: 1.5rem;
  margin-right: 2px;
  margin-top: -0.2rem;
  vertical-align: middle;
  width: 1.5rem;
}

@media (min-width: 375px) {
  .comparator-panel-container .comparator-panel .small-screen {
    display: inline-block;
  }
}
@media (max-width: 480px) {
  .comparator-panel-container .buttons-wrapper .comparator-remove-all-offers::before {
    display: none;
  }
}
@media (min-width: 640px) {
  .comparator-panel-container .comparator-panel .large-screen {
    display: inline-block;
  }
  .comparator-panel-container .comparator-panel .small-screen {
    display: none;
  }
  .comparator-panel-container .buttons-wrapper {
    margin-left: 72px;
  }
  .comparator-panel-container .buttons-wrapper span {
    display: inline;
  }
  .comparator-panel-container .comparator-link {
    margin-left: 48px;
  }
}
@media (min-width: 940px) {
  .comparator-panel-container {
    padding: 8px 0;
  }
  .comparator-panel-container .comparator-panel {
    justify-content: center;
  }
  .comparator-panel-container .comparator-link {
    margin-left: 48px;
  }
}
@keyframes highlight {
  0% {
    background: #fedd24;
  }
  100% {
    background: #ededee;
  }
}
.data-boxes-section {
  background-color: #ededee;
  padding-bottom: 24px;
  padding-top: 48px;
}
.data-boxes-section .car-data-box {
  border: 2px solid #ededed;
  border-radius: 10px;
  margin-bottom: 2%;
  padding: 36px 24px 24px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.data-boxes-section .car-data-box:hover {
  border-color: #c6cad2;
}

@media (min-width: 940px) {
  .data-boxes-section {
    padding-bottom: 48px;
    padding-top: 72px;
  }
  .data-boxes-section .car-data-box {
    float: left;
    margin-left: 2%;
    padding: 2%;
    width: 49%;
  }
  .data-boxes-section .car-data-box:first-child, .data-boxes-section .car-data-box:nth-child(4) {
    clear: left;
    margin-left: 0;
  }
}
.favorite-offer-label {
  background-color: #ededee;
  border-radius: 50%;
  height: 35px;
  margin: 6px 6px 4px;
  padding: 8px 6px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 35px;
}
.favorite-offer-label::after {
  content: url("/assets/icon/favorite-add.svg");
  display: inline-block;
  line-height: 1;
}

.dropdown-container {
  user-select: none;
}
.dropdown-container > .dropdown-current-item {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 8px;
  font-size: 14px;
  justify-content: space-between;
  line-height: 1.2;
  padding: 8px 3rem 8px 12px;
  position: relative;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-container > .dropdown-current-item .sub-item {
  color: #3c3f44;
  font-size: 12px;
}
.dropdown-container > .dropdown-list {
  background: #ffffff;
  display: none;
  line-height: 1.45;
  max-height: 50vh;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}
.dropdown-container > .dropdown-list .dropdown-item {
  align-items: center;
  border: solid 1px #ededed;
  border-top: 0;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin: 0;
  padding: 6px 12px;
  position: relative;
  width: 100%;
  transition: background-color 0.3s ease;
}
.dropdown-container > .dropdown-list .dropdown-item.group-item {
  border: 0;
  display: block;
  padding: 0;
}
.dropdown-container > .dropdown-list .dropdown-item.group-item:hover {
  background-color: #ffffff;
}
.dropdown-container > .dropdown-list .dropdown-item.group-item > .group-label {
  background-color: #ededee;
  border: solid 1px #ededed;
  font-weight: 700;
  padding: 6px 12px;
}
.dropdown-container > .dropdown-list .dropdown-item.group-item > .group-list {
  margin: 0;
}
.dropdown-container > .dropdown-list .dropdown-item .sub-item {
  color: #3c3f44;
  font-size: 12px;
  margin-left: auto;
}
.dropdown-container > .dropdown-list .dropdown-item .reset-filter-item {
  margin-left: auto;
  margin-top: -0.2rem;
}
.dropdown-container > .dropdown-list .dropdown-item .reset-filter-item::before {
  background-color: transparent;
  background-image: url("/assets/icon/close.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.4rem;
  vertical-align: middle;
  width: 1.4rem;
}
.dropdown-container > .dropdown-list .dropdown-item .price {
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  margin-left: auto;
}
.dropdown-container > .dropdown-list .dropdown-item .checkbox,
.dropdown-container > .dropdown-list .dropdown-item .radiobox {
  align-content: center;
  color: #000000;
  display: flex;
  justify-content: center;
  position: relative;
}
.dropdown-container > .dropdown-list .dropdown-item .checkbox::before,
.dropdown-container > .dropdown-list .dropdown-item .radiobox::before {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  content: "";
  display: inline-block;
  margin-right: 6px;
  padding: 8px;
  position: relative;
  vertical-align: middle;
}
.dropdown-container > .dropdown-list .dropdown-item .radiobox::before {
  border-radius: 50%;
}
.dropdown-container > .dropdown-list .dropdown-item:hover {
  background-color: #ededee;
}
.dropdown-container > .dropdown-list .dropdown-item.selected {
  color: #ff3f2d;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .price {
  color: #848da0;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .checkbox {
  color: #ff3f2d;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .checkbox::after {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 12px;
  left: 6px;
  margin-top: -0.3rem;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 6px;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .checkbox::before {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .radiobox {
  color: #ff3f2d;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .radiobox::after {
  background: #ffffff;
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 10px;
}
.dropdown-container > .dropdown-list .dropdown-item.selected .radiobox::before {
  background-color: #ff3f2d;
  border-color: #ff3f2d;
}
.dropdown-container > .dropdown-list .dropdown-item.disabled {
  color: #848da0;
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
.dropdown-container.open {
  position: relative;
  z-index: 30;
}
.dropdown-container.open > .dropdown-current-item:not(.empty) {
  border: 1px solid #c6cad2;
  transition: border 0.3s ease;
}
.dropdown-container.open > .dropdown-current-item:not(.empty)::after {
  transform: rotate(180deg);
}
.dropdown-container.open > .dropdown-list {
  display: block;
}
.dropdown-container:not(.disabled) > .dropdown-current-item {
  cursor: pointer;
  position: relative;
}
.dropdown-container:not(.disabled) > .dropdown-current-item::after {
  background-image: url("/assets/icon/expand-arrow.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;
  content: " ";
  margin: 8px;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: 55% 50%;
  width: 12px;
}
.dropdown-container.disabled {
  color: #848da0;
  cursor: default;
  pointer-events: none;
}
.dropdown-container.disabled > .dropdown-current-item {
  opacity: 0.5;
}

.images-list {
  list-style: none;
  margin-bottom: 0;
}
.images-list .image-list-item {
  border: 1px solid #ededed;
  border-radius: 10px;
  overflow: hidden;
  transition: border 0.3s ease;
}
.images-list .image-list-item:hover {
  border-color: #c6cad2;
}
.images-list .image-list-item > .gallery-image {
  background-color: #ededee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  height: 100%;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-align: center;
  width: 100%;
}

.labels {
  left: 12px;
  position: absolute;
  top: 12px;
  z-index: 2;
}
.labels .label {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 15px;
  clear: left;
  color: #000000;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 3px;
  padding: 3px 6px;
  white-space: nowrap;
}
.labels .label--winter-tires {
  align-items: center;
  color: #ff3f2d;
  display: flex;
}
.labels .label--winter-tires::before {
  background-image: url("/assets/icon/tyre.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 13px;
  margin-right: 3px;
  width: 13px;
}
.labels .label--on-stock {
  background-color: #2dbd58;
  border-color: #2dbd58;
  color: #ffffff;
}
.labels .label--available-sooner {
  background-color: #fedd24;
  border-color: #fedd24;
  color: #3c3f44;
}

.extra-labels {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;
}
.extra-labels .label {
  align-items: center;
  background-color: #2dbd58;
  border: 1px solid #2dbd58;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 6px;
  margin-right: 0;
  padding: 3px 6px;
  white-space: nowrap;
}
.extra-labels .label::before {
  background-image: url("/assets/icon/badge.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 13px;
  margin-right: 3px;
  width: 13px;
}
.extra-labels--row {
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.extra-labels--row .label {
  margin-right: 3px;
}

@font-face {
  font-family: "lg";
  src: url("./../../../node_modules/lightgallery/fonts//lg.woff2?io9a6k") format("woff2"), url("./../../../node_modules/lightgallery/fonts//lg.ttf?io9a6k") format("truetype"), url("./../../../node_modules/lightgallery/fonts//lg.woff?io9a6k") format("woff"), url("./../../../node_modules/lightgallery/fonts//lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lg-next,
.lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1084;
  outline: none;
  border: none;
}
.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}
.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}
.lg-single-item .lg-next,
.lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}
.lg-next:before {
  content: "\e095";
}

.lg-prev {
  left: 20px;
}
.lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
}
.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close:after {
  content: "\e070";
}
.lg-toolbar .lg-maximize {
  font-size: 22px;
}
.lg-toolbar .lg-maximize:after {
  content: "\e90a";
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
.lg-sub-html a {
  color: inherit;
}
.lg-sub-html a:hover {
  text-decoration: underline;
}
.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-error-msg {
  font-size: 14px;
  color: #999;
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(0.5, 0.5, 0.5);
  -o-transform: scale3d(0.5, 0.5, 0.5);
  -ms-transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
}
.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
  opacity: 1;
}
.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}
.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-zoom-from-image {
  opacity: 1;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap;
}
.lg-outer .lg-item {
  display: none !important;
}
.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("./../../../node_modules/lightgallery/images//loading.gif") no-repeat scroll center center transparent;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}
.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}
.lg-outer.lg-hide-download .lg-download {
  opacity: 0.75;
  pointer-events: none;
}
.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}
.lg-container.lg-show {
  display: block;
}
.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}
.lg-inline .lg-backdrop {
  z-index: 1;
}
.lg-inline .lg-outer {
  z-index: 2;
}
.lg-inline .lg-maximize:after {
  content: "\e909";
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out 0s;
  -moz-transition: -moz-transform 0.35s ease-out 0s;
  -o-transition: -o-transform 0.35s ease-out 0s;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.lg-outer .lg-video-cont {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.lg-outer .lg-video-cont .lg-object {
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.lg-outer .lg-video-object {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.lg-outer .lg-video-poster {
  z-index: 1;
}
.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.lg-outer .lg-has-video.lg-video-loaded .lg-video-poster,
.lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button {
  opacity: 0 !important;
}
.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1;
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes lg-play-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lg-video-play-button {
  width: 18%;
  max-width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -o-transition: -o-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  transition: transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
}
.lg-video-play-button:hover .lg-video-play-icon-bg,
.lg-video-play-button:hover .lg-video-play-icon {
  opacity: 1;
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}

.lg-video-play-icon-circle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  fill: none;
  stroke-width: 3%;
  stroke: rgba(30, 30, 30, 0.9);
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
}

.lg-video-play-icon {
  position: absolute;
  width: 25%;
  max-width: 120px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}
.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc;
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: lg-play-rotate 2s linear 0.25s infinite, lg-play-stroke 1.5s ease-in-out 0.25s infinite;
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
  float: left;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}
.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}
.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}
.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}
.lg-outer .lg-thumb {
  padding: 5px 0;
  height: 100%;
  margin-bottom: -5px;
  display: inline-block;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}
.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border-radius: 2px;
  margin-bottom: 5px;
  will-change: border-color;
}
@media (min-width: 768px) {
  .lg-outer .lg-thumb-item {
    border-radius: 4px;
    border: 2px solid #fff;
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: rgb(169, 7, 7);
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}
.lg-outer.lg-animate-thumb .lg-thumb {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@font-face {
  font-family: "lg";
  src: url("./../../../node_modules/lightgallery/fonts//lg.woff2?io9a6k") format("woff2"), url("./../../../node_modules/lightgallery/fonts//lg.ttf?io9a6k") format("truetype"), url("./../../../node_modules/lightgallery/fonts//lg.woff?io9a6k") format("woff"), url("./../../../node_modules/lightgallery/fonts//lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-outer.lg-hide-download .lg-download {
  display: none;
}

.list-of-boxes {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.list-of-boxes li {
  border-radius: 10px;
  height: auto;
  min-height: 55px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.list-of-boxes li:hover {
  border-color: #c6cad2;
}
.list-of-boxes li .box-link {
  align-items: center;
  color: #ff3f2d;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 8%;
  text-overflow: ellipsis;
}
.list-of-boxes li .admin-buttons {
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.list-of-boxes li .admin-buttons .admin-button {
  display: block;
  font-size: 12px;
  padding: 0 6px;
}
.list-of-boxes::after {
  clear: both;
  content: "";
  display: table;
}
.list-of-boxes.images-list .image-list-item {
  border: 0;
}
.list-of-boxes.images-list .image-list-item .gallery-image {
  background-color: #ededee;
}

@media (max-width: 639px) {
  .list-of-boxes li {
    margin-left: 1%;
    width: 49.5%;
  }
  .list-of-boxes li:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 640px) and (max-width: 939px) {
  .list-of-boxes li {
    margin-left: 1%;
    width: 32.6666666667%;
  }
  .list-of-boxes li:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) and (max-width: 1199px) {
  .list-of-boxes li {
    margin-left: 1%;
    width: 24.25%;
  }
  .list-of-boxes li:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .list-of-boxes.images-list li {
    margin-left: 1%;
    width: 24.25%;
  }
  .list-of-boxes.images-list li:nth-child(4n+1) {
    margin-left: 0;
  }
  .list-of-boxes:not(.images-list) li {
    margin-left: 1%;
    width: 15.8333333333%;
  }
  .list-of-boxes:not(.images-list) li:nth-child(6n+1) {
    margin-left: 0;
  }
}
.prices-vat-info {
  font-size: 85%;
  margin-top: 4px;
  text-align: right;
}

.newsletter-subscription-container {
  background-color: #ededee;
  margin-top: 48px;
}
.newsletter-subscription-container:not(.discount) {
  border-radius: 20px;
  display: block;
  padding: 24px;
  text-align: center;
  width: 100%;
}
.newsletter-subscription-container:not(.discount):not(.active) {
  pointer-events: none;
  position: relative;
}
.newsletter-subscription-container:not(.discount):not(.active)::before {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.newsletter-subscription-container:not(.discount) .icon {
  align-items: center;
  background-color: #dce0e7;
  border-radius: 50%;
  display: flex;
  flex: none;
  height: 130px;
  justify-content: center;
  margin: 0 auto 12px;
  position: relative;
  width: 130px;
}
.newsletter-subscription-container:not(.discount) .icon::before {
  background-color: transparent;
  background-image: url("/assets/icon/newsletter-paper-plane-icon.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 4rem;
  position: absolute;
  right: -6px;
  top: 24px;
  width: 4rem;
}
.newsletter-subscription-container:not(.discount).subscription-listing {
  margin-bottom: 48px;
  margin-top: 0;
}
.newsletter-subscription-container:not(.discount) .form .form-group {
  margin-bottom: 0;
  position: relative;
}
.newsletter-subscription-container:not(.discount) .form .form-group:nth-child(1) {
  width: 100%;
}
.newsletter-subscription-container:not(.discount) .form .form-group .button-primary {
  width: 100%;
}
.newsletter-subscription-container:not(.discount) .form .send-button-label {
  color: #000000;
  margin-bottom: 6px;
  margin-top: 24px;
  min-width: 245px;
  text-align: center;
}
.newsletter-subscription-container:not(.discount) .form .send-button-label::before {
  background-color: transparent;
  background-image: url("/assets/icon/check-ok.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.8rem;
  margin-right: 4px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.8rem;
}
.newsletter-subscription-container:not(.discount) input {
  padding: 12px;
}
.newsletter-subscription-container:not(.discount) .response-icon {
  display: block;
  margin-right: 6px;
  width: 50px;
}
.newsletter-subscription-container:not(.discount) .response-icon .circle {
  animation: dash 1s ease-in-out;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.newsletter-subscription-container:not(.discount) .response-icon .symbol {
  animation: dash-check 1s 0.5s ease-in-out forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.newsletter-subscription-container.discount {
  border-radius: 10px;
  bottom: 12px;
  display: none;
  left: 12px;
  max-width: 420px;
  padding: 24px;
  position: fixed;
  right: 12px;
  text-align: center;
  z-index: 610;
  background-color: #ededee;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.newsletter-subscription-container.discount:hover {
  border-color: #c6cad2;
}
.newsletter-subscription-container.discount .close-popup {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
}
.newsletter-subscription-container.discount .close-popup .close-icon {
  height: auto;
  width: 2.5rem;
}
.newsletter-subscription-container.discount .form .form-group {
  margin-bottom: 0;
  position: relative;
}
.newsletter-subscription-container.discount .form .form-group label {
  line-height: 1.45;
  margin-bottom: 12px;
}
.newsletter-subscription-container.discount .form .form-group .button-primary {
  margin-top: 12px;
}
.newsletter-subscription-container.discount input {
  padding: 12px;
}
.newsletter-subscription-container.discount .headline {
  padding: 0 24px;
}
.newsletter-subscription-container.discount .response-icon {
  display: block;
  margin: 0 auto 6px;
  width: 50px;
}
.newsletter-subscription-container.discount .response-icon .circle {
  animation: dash 1s ease-in-out;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.newsletter-subscription-container.discount .response-icon .symbol {
  animation: dash-check 1s 0.5s ease-in-out forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.newsletter-subscription-container.complete {
  align-items: center;
  display: flex;
  justify-content: center;
}
.newsletter-subscription-container .error-message {
  color: #ec5340;
  margin-top: 12px;
}
.newsletter-subscription-container .error-message::before {
  background: url("/assets/icon/cross-no.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  text-decoration: none;
  vertical-align: middle;
  width: 2rem;
}

@media (min-width: 640px) {
  .newsletter-subscription-container.discount {
    bottom: 24px;
    left: auto;
    right: 24px;
  }
}
@media (min-width: 940px) {
  .newsletter-subscription-container {
    margin-top: 72px;
  }
  .newsletter-subscription-container:not(.discount) {
    align-items: center;
    border-radius: 20px 20px 60px;
    display: flex;
    padding: 72px;
    text-align: left;
  }
  .newsletter-subscription-container:not(.discount) .icon {
    margin-bottom: 0;
  }
  .newsletter-subscription-container:not(.discount) .content {
    margin-left: 36px;
    width: 100%;
  }
  .newsletter-subscription-container:not(.discount) .form {
    align-items: flex-end;
    display: flex;
  }
  .newsletter-subscription-container:not(.discount) .form .send-button-label {
    margin-top: 0;
  }
  .newsletter-subscription-container:not(.discount) .form .form-group:nth-child(2) {
    margin-left: 12px;
  }
  .newsletter-subscription-container:not(.discount) .response-icon {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 12px;
    width: 60px;
  }
  .newsletter-subscription-container:not(.discount).subscription-listing {
    margin-bottom: 72px;
    margin-top: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.not-available-label {
  align-items: center;
  background-color: #ededee;
  border-radius: 20px;
  color: #000000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: 435px;
  min-height: 150px;
  padding: 24px;
  width: 100%;
}
.not-available-label .title {
  font-size: 20px;
}
.not-available-label.preparing {
  margin-top: 24px;
}
.not-available-label.loading {
  height: 2rem;
  width: 2rem;
}

.no-content {
  border: 1px solid #ff3f2d;
  margin: 24px;
  padding: 24px;
  text-align: center;
}
.no-content .no-content-message {
  font-size: 115%;
  margin-bottom: 0;
}

.react-spinner {
  animation: 0.75s ease infinite spinner-border;
  border: 3px solid #ff3f2d;
  border-left-color: transparent;
  border-radius: 50%;
  display: inline-block;
  height: 5rem;
  vertical-align: -0.125em;
  width: 5rem;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.table {
  background: #ffffff;
  border-collapse: collapse;
  border-radius: 10px;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  table-layout: auto;
  width: 100%;
}
.table td,
.table th {
  padding: 8px 24px;
  text-align: left;
}
.table thead tr {
  background: #000000;
  color: #ffffff;
  height: 60px;
}
.table thead th {
  font-weight: 400;
}
.table tbody tr {
  height: auto;
  padding: 8px 0;
}
.table tbody tr:nth-child(odd) {
  background: #ededee;
}

@media (min-width: 940px) {
  .table {
    table-layout: fixed;
  }
  .table td,
  .table th {
    padding: 12px 24px;
  }
}
.tooltip.tooltip-active {
  cursor: pointer;
  position: relative;
}
.tooltip.tooltip-active::after {
  background: url("/assets/icon/question-mark-gray.svg") 50% 50% no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.6rem;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.6rem;
}
.tooltip.tooltip-active::before {
  background-image: url("/assets/icon/close.svg");
  background-position: right 12px top 12px;
  background-repeat: no-repeat;
  background-size: 2rem;
  bottom: 0;
  color: #000000;
  content: attr(data-title);
  cursor: pointer;
  display: block;
  font-weight: 400;
  left: 0;
  line-height: 1.45;
  padding: 48px 24px;
  position: fixed;
  text-decoration: none;
  text-transform: none;
  transform: translateY(100%);
  white-space: pre-wrap;
  width: 100%;
  z-index: 1020;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: transform 0.3s ease;
  will-change: transform;
}
.tooltip.tooltip-active.tooltip-open::before {
  transform: translateY(0);
}

@media (min-width: 640px) {
  .tooltip.tooltip-active::before {
    border-radius: 10px;
    bottom: auto;
    display: none;
    left: 50%;
    max-width: 400px;
    min-width: 200px;
    padding: 36px 24px;
    position: absolute;
    right: auto;
    top: 24px;
    transform: translateX(-50%) translateY(0);
    transition: none;
    width: max-content;
  }
  .tooltip.tooltip-active.tooltip-open::before {
    display: block;
    transform: translateX(-50%);
  }
  .tooltip.tooltip-active.tooltip-open.right::before {
    left: auto;
    right: 0;
    transform: translateX(15%);
  }
  .tooltip.tooltip-active.tooltip-open.left::before {
    left: 0;
    right: auto;
    transform: translateX(-85%);
  }
}
@media (max-width: 639px) {
  body.tooltip-open {
    position: relative;
  }
  body.tooltip-open::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
  body.tooltip-open #main-menu::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 700;
  }
}
@media (min-width: 940px) {
  .tooltip.tooltip-active::before {
    z-index: 598;
  }
}
.small-boxes-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.small-boxes-list::before {
  display: none;
}
.small-boxes-list li {
  border-radius: 10px;
  height: auto;
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.small-boxes-list li:hover {
  border-color: #c6cad2;
}
.small-boxes-list li > a {
  align-items: center;
  display: flex;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
}
.small-boxes-list::after {
  clear: both;
  content: "";
  display: table;
}

@media (min-width: 480px) and (max-width: 639px) {
  .small-boxes-list li {
    margin-left: 1%;
    width: 49.5%;
  }
  .small-boxes-list li:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 640px) and (max-width: 939px) {
  .small-boxes-list li {
    margin-left: 1%;
    width: 24.25%;
  }
  .small-boxes-list li:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  .small-boxes-list li {
    margin-left: 1%;
    width: 19.2%;
  }
  .small-boxes-list li:nth-child(5n+1) {
    margin-left: 0;
  }
}
.js .small-boxes-list.hidden-list .box-link {
  justify-content: center;
}

.social-share {
  position: relative;
}
.social-share::before {
  background: url("/assets/icon/share-icon.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.social-share .social-share-popup {
  padding: 24px;
}
.social-share .social-share-title {
  color: #3c3f44;
  display: inline-block;
  margin-bottom: 6px;
}
.social-share .form-flex {
  margin-bottom: 24px;
}
.social-share .form-flex input,
.social-share .form-flex .button {
  font-size: 14px;
  width: 100%;
}
.social-share .social-share-links {
  display: flex;
  flex-wrap: wrap;
}
.social-share .social-share-links .social-share-link {
  margin-left: 1%;
  width: 49.5%;
}
.social-share .social-share-links .social-share-link:nth-child(2n+1) {
  margin-left: 0;
}
.social-share .social-share-links .social-share-link .share-link {
  display: inline-block;
}
.social-share .social-share-links .social-icon {
  fill: #ffffff;
  height: 3rem;
  margin-right: 12px;
  padding: 4px;
  width: 3rem;
}
.social-share .social-share-links .social-icon.facebook {
  background-color: #4267b2;
}
.social-share .social-share-links .social-icon.twitter {
  background-color: #20b8ff;
}
.social-share label.open-popup-label {
  align-items: center;
  background-color: #ffffff;
  color: #ff3f2d;
  cursor: pointer;
  display: flex;
  line-height: 1;
  text-decoration: underline;
}
.social-share .close-popup {
  margin-bottom: 24px;
}

@media (max-width: 939px) {
  .social-share .social-share-popup {
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    bottom: 0;
    left: -100%;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    will-change: left;
    z-index: 610;
    transition: left 0.3s ease;
  }
  .social-share .open-popup-checkbox:checked + .social-share-popup {
    left: 0;
  }
}
@media (max-width: 759px) {
  .social-share {
    align-items: center;
    display: flex;
  }
}
@media (min-width: 640px) {
  .social-share .open-popup-label span {
    margin-left: 4px;
  }
  .social-share .form-flex {
    display: flex;
  }
  .social-share .form-flex input {
    border-right: 0;
    width: 70%;
  }
  .social-share .form-flex .button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    letter-spacing: 0;
    padding: 6px 12px;
    width: 35%;
  }
}
@media (min-width: 940px) {
  .social-share .social-share-popup {
    cursor: auto;
    display: none;
    margin-top: 12px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 380px;
    z-index: 599;
    animation: fadeIn 0.3s ease-out;
    will-change: opacity;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: 1px solid #ededed;
  }
  .social-share .social-share-popup::after {
    border-bottom: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 100%;
    content: "";
    position: absolute;
    right: 42px;
  }
  .social-share .social-share-popup::before {
    border-bottom: 7px solid #ededed;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    bottom: 100%;
    content: "";
    position: absolute;
    right: 40px;
  }
  .social-share .open-popup-checkbox:checked + .social-share-popup {
    display: block;
  }
  .social-share .close-popup {
    display: none;
  }
}
[type=radio]:checked + label, [type=radio]:not(:checked) + label {
  cursor: pointer;
  display: inline;
  font-weight: 400;
  line-height: 1.45;
  padding-left: 24px;
  position: relative;
}
[type=radio]:checked + label:not(:last-of-type), [type=radio]:not(:checked) + label:not(:last-of-type) {
  padding-right: 12px;
}
[type=radio]:checked + label::before, [type=radio]:not(:checked) + label::before {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 50%;
  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}
[type=radio]:checked + label::after, [type=radio]:not(:checked) + label::after {
  background: #ffffff;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 8px;
}
[type=radio]:checked, [type=radio]:not(:checked) {
  left: -9999px;
  position: absolute;
}
[type=radio]:not(:checked) + label::after {
  opacity: 0;
}
[type=radio]:checked + label {
  color: #ff3f2d;
}
[type=radio]:checked + label::after {
  opacity: 1;
}
[type=radio]:checked + label::before {
  background-color: #ff3f2d !important;
  border-color: #ff3f2d !important;
}

.videos-list {
  list-style: none;
  margin-bottom: 0;
}
.videos-list .video-list-item {
  border-radius: 10px;
}
.videos-list .video-list-item > .video-thumbnail {
  background-color: #ededee;
  background-image: url("/assets/icon/img-placeholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  height: 100%;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-align: center;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  min-height: 100px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.videos-list .video-list-item > .video-thumbnail::before {
  background: url("/assets/icon/video-play.svg") 55% 50% no-repeat #ff3f2d;
  background-size: 35%;
  border-radius: 50%;
  content: " ";
  height: 5rem;
  left: 50%;
  opacity: 0.9;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: 5rem;
  will-change: opacity, transform;
  z-index: 2;
}
@media (min-width: 940px) {
  .videos-list .video-list-item > .video-thumbnail::before {
    height: 7rem;
    width: 7rem;
  }
}
.videos-list .video-list-item > .video-thumbnail::after {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.3s ease;
}
.videos-list .video-list-item > .video-thumbnail:hover::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
}
.videos-list .video-list-item > .video-thumbnail:hover::after {
  background-color: rgba(0, 0, 0, 0.4);
}
.videos-list .video-list-item > .video-thumbnail > img {
  display: block;
  height: auto;
  max-height: 120px;
  visibility: hidden;
  width: 100%;
}
.videos-list .video-list-item > .video-thumbnail[data-image] {
  background-size: contain;
}

:root {
  --slide-spacing: 12px;
  --slide-gap: 2%;
  --slide-small-screen-size: 95%;
  --slide-middle-screen-size: 49%;
  --slide-large-screen-size: 23.5%;
}

.embla {
  position: relative;
}
.embla .embla-viewport {
  overflow: hidden;
}
.embla .embla-viewport:not(.embla .embla-viewport.embla-active) .embla-container .embla-slide {
  overflow: visible;
}
.embla .embla-viewport.embla-active .embla-container .embla-slide {
  overflow: hidden;
}
.embla .embla-container {
  backface-visibility: hidden;
  display: flex;
  gap: var(--slide-gap);
  touch-action: pan-y;
}
.embla .embla-container .embla-slide {
  flex: 0 0 var(--slide-small-screen-size);
  overflow: hidden;
  position: relative;
}
.embla .embla-buttons .embla-button {
  cursor: pointer;
  display: inline-block;
}
.embla .embla-buttons .embla-button.disabled {
  opacity: 0.5;
}
.embla .embla-buttons .embla-button::after {
  background: url("/assets/icon/right-arrow-orange.svg") 50% 50% no-repeat #ffffff;
  background-size: 35%;
  border: 2px solid #ededed;
  border-radius: 15px;
  content: " ";
  display: inline-block;
  height: 4rem;
  width: 4rem;
  transition: transform 0.3s ease;
  will-change: transform;
}
.embla .embla-buttons .embla-button.embla-button-prev::after {
  transform: rotate(180deg);
}
.embla .embla-dots {
  margin: 12px auto 0;
  min-height: 21px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 80px;
}
.embla .embla-dots:empty {
  display: none;
}
.embla .embla-dots .embla-dot {
  background-color: #ededee;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 10px;
  margin: 0 3px;
  position: relative;
  width: 10px;
  transition: left 0.3s ease;
}
.embla .embla-dots .embla-dot.embla-dot-selected {
  background-color: #ff3f2d;
}

@media (max-width: 639px) {
  .embla .embla-container .embla-slide {
    flex: 0 0 var(--slide-small-screen-size);
  }
}
@media (min-width: 640px) and (max-width: 939px) {
  .embla .embla-container .embla-slide {
    flex: 0 0 var(--slide-middle-screen-size);
  }
}
@media (min-width: 940px) {
  .embla .embla-container .embla-slide {
    flex: 0 0 var(--slide-large-screen-size);
  }
}
.admin-header .alert {
  border: 1px solid #ededed;
  margin-bottom: 24px;
  padding: 0.5rem 1rem;
}

.base-model-list {
  display: flex;
  flex-wrap: wrap;
}
.base-model-list .model,
.base-model-list .model-line-item {
  margin-right: 24px;
}
.base-model-list .model .box-link,
.base-model-list .model-line-item .box-link {
  color: #ff3f2d;
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
}
.base-model-list .model .box-link .production-dates,
.base-model-list .model-line-item .box-link .production-dates {
  font-weight: 400;
  padding-left: 4px;
}

@media (max-width: 480px) {
  .base-model-list,
  .model,
  .model-line-item {
    list-style-position: outside;
    margin-left: 1em;
    margin-right: 0;
  }
}
.car-description.detail {
  margin-bottom: 24px;
}
@media (min-width: 940px) {
  .car-description {
    font-size: 115%;
  }
  .car-description.detail {
    margin-bottom: 48px;
  }
}

.catalog-subscription-context-banner {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 48px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.catalog-subscription-context-banner:hover {
  border-color: #c6cad2;
}
.catalog-subscription-context-banner .column {
  padding: 24px;
  position: relative;
}
.catalog-subscription-context-banner .column:nth-of-type(2) {
  border-top: 1px dashed #ededed;
}
.catalog-subscription-context-banner .column .main-title {
  margin-bottom: 24px;
  text-align: center;
}
.catalog-subscription-context-banner .column .title {
  margin-bottom: 24px;
}
.catalog-subscription-context-banner .column .label-icon {
  background-color: #45474c;
  border-radius: 5px;
  color: #ffffff;
  font-size: 85%;
  left: 50%;
  padding: 4px 12px;
  position: absolute;
  text-transform: uppercase;
  top: -12px;
  transform: translateX(-50%);
}
.catalog-subscription-context-banner .column .label-icon::before {
  background: url("/assets/icon/happy.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.6rem;
  margin-right: 4px;
  margin-top: -0.2rem;
  vertical-align: middle;
  width: 1.6rem;
}
.catalog-subscription-context-banner .column .list {
  list-style: none;
  margin-bottom: 0;
}
.catalog-subscription-context-banner .column .list-item {
  padding-left: 24px;
}
.catalog-subscription-context-banner .column .list-item.yes::before {
  color: transparent;
  display: inline-block;
  height: 1.2rem;
  vertical-align: middle;
  width: 1.2rem;
  background: url("/assets/icon/check-ok.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  margin: -0.4rem 0.3rem -0.2rem -24px;
}
.catalog-subscription-context-banner .column .list-item.plus {
  color: #22ce82;
  font-weight: 700;
  padding-left: 0;
}
.catalog-subscription-context-banner .column .button-container {
  margin-top: 18px;
}
.catalog-subscription-context-banner .column .button-container .button {
  width: 100%;
}
.catalog-subscription-context-banner .column .main-image-container {
  border-radius: 10px;
  display: block;
  height: 180px;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.catalog-subscription-context-banner .column .main-image-container:hover {
  border-color: #c6cad2;
}
.catalog-subscription-context-banner .column .image-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  padding: 12px 12px 0;
  position: absolute;
  right: 0;
  background: linear-gradient(0deg, #000000, transparent);
  padding-bottom: 6px;
}
.catalog-subscription-context-banner .column .image-content .sale-old-price {
  color: #ffffff;
}
.catalog-subscription-context-banner .column .image-content .car-title {
  color: #ffffff;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.catalog-subscription-context-banner .column .image-content .subtitle {
  color: #dee0e2;
  display: block;
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.catalog-subscription-context-banner .column .image-content .price {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 8px;
  margin-left: 12px;
}
.catalog-subscription-context-banner .column .car-image-gallery .car-gallery-images {
  list-style: none;
  margin-bottom: 0;
}
.catalog-subscription-context-banner .column .car-image-gallery .image-list-item {
  background-position: 50%;
  background-size: cover;
  border-radius: 10px;
  display: block;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.catalog-subscription-context-banner .column .car-image-gallery .image-list-item:hover {
  border-color: #c6cad2;
}

@media (max-width: 939px) {
  .catalog-subscription-context-banner .column .main-image-container {
    margin-bottom: 8px;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .car-gallery-images {
    display: flex;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .image-list-item {
    height: 60px;
    margin-left: 2%;
    width: 32%;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .image-list-item:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 640px) and (max-width: 939px) {
  .catalog-subscription-context-banner .column .main-image-container {
    height: 280px;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .image-list-item {
    height: 130px;
  }
}
@media (min-width: 940px) {
  .catalog-subscription-context-banner {
    margin-bottom: 72px;
  }
  .catalog-subscription-context-banner .columns {
    display: flex;
  }
  .catalog-subscription-context-banner .column:nth-of-type(1) {
    width: 55%;
  }
  .catalog-subscription-context-banner .column:nth-of-type(2) {
    border-left: 1px dashed #ededed;
    border-top: 0;
    width: 45%;
  }
  .catalog-subscription-context-banner .column .main-image-container {
    height: 260px;
  }
  .catalog-subscription-context-banner .column .car-image-gallery {
    display: flex;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .main-image-container {
    width: 73%;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .car-gallery-images {
    margin-left: 2%;
    width: 25%;
  }
  .catalog-subscription-context-banner .column .car-image-gallery .image-list-item {
    height: 80px;
  }
  .catalog-subscription-context-banner .column .image-content .price {
    font-size: 20px;
  }
  .catalog-subscription-context-banner .column .button-container .button {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .catalog-subscription-context-banner .column {
    padding: 48px 48px 36px;
  }
}
.gallery-section {
  margin-top: 48px;
}

.gallery-section,
.video-section {
  margin-bottom: 48px;
}

@media (min-width: 940px) {
  .gallery-section {
    margin-top: 72px;
  }
  .gallery-section,
  .video-section {
    margin-bottom: 72px;
  }
}
.powertrain-list-container,
.trim-list-container {
  margin-bottom: 48px;
}

.make-detail-header-container {
  background-color: #ededee;
}
.make-detail-header-container.model-line-detail {
  background-color: #ffffff;
}
.make-detail-header-container .make-detail-header {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
  margin-top: 24px;
}
.make-detail-header-container .make-detail-header .make-logo {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 50%;
  display: none;
  margin-right: 24px;
  padding: 24px;
}
.make-detail-header-container .make-detail-header .make-name {
  margin-bottom: 12px;
}
.make-detail-header-container .make-detail-header .model-title .car-subtitle {
  font-size: 18px;
}

@media (min-width: 640px) {
  .make-detail-header-container .make-detail-header {
    align-items: center;
    display: flex;
  }
  .make-detail-header-container .make-detail-header .make-logo {
    display: block;
  }
}
.make-list {
  margin-bottom: 2rem;
}
.make-list .make {
  margin-top: 6px;
  white-space: nowrap;
}
.make-list .make .make-logo {
  border-bottom: 1px solid #ededed;
  display: block;
  padding: 8% 28%;
}
.make-list .make .make-logo img {
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  max-width: 100%;
}
.make-list .make h4 {
  font-size: 14px;
  margin: 0;
}

.model-list-container {
  background-color: #ededee;
  margin-bottom: 48px;
}
.model-list-container .past-models {
  padding-bottom: 24px;
  padding-top: 24px;
}

@media (min-width: 940px) {
  .model-list-container {
    margin-bottom: 72px;
  }
  .model-list-container .past-models {
    padding-bottom: 48px;
    padding-top: 48px;
  }
}
.model-list .model,
.model-list .model-line-item,
.model-line-list .model,
.model-line-list .model-line-item {
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.model-list .model:hover,
.model-list .model-line-item:hover,
.model-line-list .model:hover,
.model-line-list .model-line-item:hover {
  border-color: #c6cad2;
}

.model-line-list .model-line-item {
  position: relative;
}
.model-line-list .model-line-item .production-dates {
  display: block;
  font-weight: 400;
}

.trim-list,
.powertrain-list {
  margin-bottom: 0;
}

.listing-top-banner {
  margin-bottom: 24px;
}
.listing-top-banner.catalog {
  margin-top: 12px;
}
.listing-top-banner .primary-box {
  border-radius: 10px 10px 0 0;
  min-height: 200px;
  overflow: hidden;
  position: relative;
}
.listing-top-banner .primary-box.full-width {
  border-radius: 10px;
}
.listing-top-banner .primary-box .car-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  padding: 12px 12px 0;
  position: absolute;
  right: 0;
  background: linear-gradient(0deg, #000000, transparent);
  padding: 12px;
}
.listing-top-banner .primary-box .car-title .subtitle {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-right: 6px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listing-top-banner .primary-box .car-title .make-logo {
  align-self: flex-end;
  height: auto;
  max-width: 50px;
}
.listing-top-banner .primary-box .title {
  align-self: flex-end;
  color: #ffffff;
  line-height: 1;
  margin-bottom: 0;
  min-width: 0;
  font-size: 24px;
}
@media (min-width: 640px) {
  .listing-top-banner .primary-box .title {
    font-size: 32px;
  }
}
.listing-top-banner .primary-box .title .price-subtitle {
  display: block;
  margin-top: 12px;
  font-family: DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.listing-top-banner .secondary-box {
  border-left: 0;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ededee;
  border: 1px solid #ededed;
  padding: 12px;
}

@media (min-width: 640px) {
  .listing-top-banner .primary-box {
    min-height: 350px;
  }
  .listing-top-banner .primary-box .car-title {
    padding: 24px;
  }
  .listing-top-banner .primary-box .car-title .make-logo {
    max-width: 70px;
  }
  .listing-top-banner .primary-box .images-list .image-list-item {
    border-radius: 0;
  }
  .listing-top-banner .secondary-box {
    padding: 24px;
  }
}
@media (min-width: 940px) {
  .listing-top-banner {
    margin-bottom: 48px;
  }
}
@media (min-width: 1200px) {
  .listing-top-banner {
    display: flex;
  }
  .listing-top-banner .primary-box {
    border-radius: 60px 0 0 20px;
    width: 66.66%;
  }
  .listing-top-banner .primary-box.full-width {
    border-radius: 60px 10px 20px;
    min-height: 450px;
    width: 100%;
  }
  .listing-top-banner .secondary-box {
    border-radius: 0 20px 20px 0;
    width: 33.34%;
  }
}
.model-detail-gallery .image-list-item:hover .img-inner {
  transform: scale(1.03);
  transform-origin: center;
}
.model-detail-gallery .primary-box .images-list {
  height: 100%;
  margin-bottom: 0;
}
.model-detail-gallery .primary-box .images-list .image-list-item {
  height: 100%;
  margin-bottom: 0;
  overflow: hidden;
}
.model-detail-gallery .primary-box .images-list .image-list-item .img-inner {
  max-height: 430px;
}
.model-detail-gallery .secondary-box .gallery-link {
  color: #ff3f2d;
}
.model-detail-gallery .secondary-box .gallery-link::before {
  background: url("/assets/icon/photoCamera.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  float: left;
  height: 20px;
  margin-right: 6px;
  width: 20px;
}
.model-detail-gallery .secondary-box .images-list {
  display: flex;
  flex-wrap: wrap;
}
.model-detail-gallery .secondary-box .images-list .image-list-item {
  height: 70px;
  overflow: hidden;
}
.model-detail-gallery .secondary-box .images-list .video {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  min-height: 100px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  min-height: auto;
}
.model-detail-gallery .secondary-box .images-list .video::before {
  background: url("/assets/icon/video-play.svg") 55% 50% no-repeat #ff3f2d;
  background-size: 35%;
  border-radius: 50%;
  content: " ";
  height: 5rem;
  left: 50%;
  opacity: 0.9;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  width: 5rem;
  will-change: opacity, transform;
  z-index: 2;
}
@media (min-width: 940px) {
  .model-detail-gallery .secondary-box .images-list .video::before {
    height: 7rem;
    width: 7rem;
  }
}
.model-detail-gallery .secondary-box .images-list .video::after {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.3s ease;
}
.model-detail-gallery .secondary-box .images-list .video:hover::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
}
.model-detail-gallery .secondary-box .images-list .video:hover::after {
  background-color: rgba(0, 0, 0, 0.4);
}
@media (max-width: 639px) {
  .model-detail-gallery .secondary-box .images-list .video::before {
    height: 4rem;
    width: 4rem;
  }
}

@media (max-width: 1199px) {
  .model-detail-gallery .secondary-box .images-list .image-list-item {
    margin-left: 2%;
    width: 32%;
  }
  .model-detail-gallery .secondary-box .images-list .image-list-item:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 600px) and (max-width: 1199px) {
  .model-detail-gallery .secondary-box .images-list {
    display: flex;
    flex-wrap: wrap;
  }
  .model-detail-gallery .secondary-box .images-list .image-list-item {
    height: 120px;
  }
}
@media (min-width: 1200px) {
  .model-detail-gallery .secondary-box .gallery-link {
    align-self: center;
  }
  .model-detail-gallery .secondary-box .images-list .image-list-item.main-item {
    height: 160px;
    width: 100%;
  }
  .model-detail-gallery .secondary-box .images-list .image-list-item:not(.main-item) {
    width: 49%;
  }
  .model-detail-gallery .secondary-box .images-list .image-list-item:nth-child(3) {
    margin-left: 2%;
  }
  .model-detail-gallery.catalog .secondary-box .images-list .image-list-item {
    height: 120px;
  }
  .model-detail-gallery.catalog .secondary-box .images-list .image-list-item.main-item {
    height: 180px;
  }
}
.subscription-banner-container {
  margin-bottom: 48px;
  overflow: hidden;
  padding: 48px 12px;
  position: relative;
}
.subscription-banner-container picture {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.subscription-banner-container::before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 50%, transparent 100%, transparent 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.subscription-banner-container .banner-promo-text {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.subscription-banner-container .banner-promo-text span {
  background-color: #ff3f2d;
  color: #ffffff;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  padding: 3px 18px;
  text-transform: uppercase;
  white-space: nowrap;
  width: fit-content;
}
.subscription-banner-container .banner-promo-text span:first-child {
  transform: rotate(-5deg) translateX(-10px) translateY(-5px);
}
.subscription-banner-container .banner-promo-text span:nth-child(2) {
  transform: rotate(3deg);
}
.subscription-banner-container.container {
  padding: 48px 12px;
}
.subscription-banner-container:not(.container) {
  border-radius: 20px;
  padding: 48px 24px;
}
.subscription-banner-container .title {
  color: #ffffff;
}
.subscription-banner-container .list-title {
  color: #ffffff;
  font-size: 135%;
  font-weight: 500;
  margin-bottom: 24px;
}
.subscription-banner-container .list {
  color: #ffffff;
  list-style: none;
  margin-bottom: 24px;
}
.subscription-banner-container .list .item {
  font-size: 16px;
  line-height: 1.45;
  margin-left: 3.5rem;
}
.subscription-banner-container .list .item::before {
  background: url("/assets/icon/check-ok-white.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  color: transparent;
  content: " ";
  display: inline-block;
  height: 2.4rem;
  margin: -0.4rem 0.8rem -0.2rem -3.5rem;
  vertical-align: middle;
  width: 2.4rem;
}
.subscription-banner-container .banner-container-column {
  min-height: 120px;
  position: relative;
  z-index: 3;
}
.subscription-banner-container .banner-container-column .buttons-container {
  display: flex;
}
.subscription-banner-container .banner-container-column .buttons-container .button:nth-of-type(2) {
  margin-left: 12px;
}

@media (min-width: 640px) {
  .subscription-banner-container:not(.authentication) {
    border-radius: 20px 20px 20px 60px;
    padding: 48px;
  }
  .subscription-banner-container:not(.authentication).container {
    margin-top: 24px;
    padding: 48px;
  }
  .subscription-banner-container:not(.authentication) .title {
    font-size: 32px;
    line-height: 1.2;
  }
}
@media (min-width: 640px) and (min-width: 940px) {
  .subscription-banner-container:not(.authentication) .title {
    font-size: 48px;
  }
}
@media (min-width: 640px) {
  .subscription-banner-container:not(.authentication) .list {
    display: flex;
    flex-wrap: wrap;
  }
  .subscription-banner-container:not(.authentication) .list .item {
    padding-left: 36px;
    margin-left: 2%;
    width: 49%;
  }
  .subscription-banner-container:not(.authentication) .list .item:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  .subscription-banner-container:not(.authentication) {
    display: flex;
    margin-bottom: 72px;
    padding: 72px;
  }
  .subscription-banner-container:not(.authentication) .banner-promo-text span {
    font-size: 34px;
  }
  .subscription-banner-container:not(.authentication) .banner-promo-text span:first-child {
    transform: rotate(-5deg) translateX(-10px) translateY(-5px);
  }
  .subscription-banner-container:not(.authentication) .banner-promo-text span:nth-child(2) {
    transform: rotate(3deg);
  }
  .subscription-banner-container:not(.authentication).container {
    padding: 72px;
  }
  .subscription-banner-container:not(.authentication):not(.container) {
    padding: 72px;
  }
}
@media (min-width: 1100px) {
  .subscription-banner-container {
    min-height: 480px;
  }
  .subscription-banner-container:not(.authentication)::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 30%, transparent 75%, transparent 100%);
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns {
    display: flex;
    width: 100%;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column {
    margin-left: 2%;
    width: 49%;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column:nth-child(2n+1) {
    margin-left: 0;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column:nth-of-type(1) {
    order: 2;
  }
  .subscription-banner-container:not(.authentication) .banner-container-columns .banner-container-column:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
  }
}
.authentication-container .conditions-info {
  font-size: 12px;
  text-align: center;
}
.authentication-container .authentication-tabs {
  display: flex;
  margin: 0 auto;
}
.authentication-container .authentication-tabs .tab {
  color: #000000;
  display: inline-block;
  font-size: 16px;
  padding: 18px;
  text-align: center;
  text-decoration: none;
  margin-left: 0%;
  width: 50%;
  transition: background-color 0.3s ease;
}
.authentication-container .authentication-tabs .tab:nth-child(2n+1) {
  margin-left: 0;
}
.authentication-container .authentication-tabs .tab:nth-of-type(1) {
  border-top-left-radius: 20px;
}
.authentication-container .authentication-tabs .tab:nth-of-type(2) {
  border-top-right-radius: 20px;
}
.authentication-container .authentication-tabs .tab:not(.active) {
  background-color: #ededed;
  color: #000000;
  font-weight: 500;
}
.authentication-container .authentication-tabs .tab.active {
  background-color: #ffffff;
  border: 2px solid #ededed;
  border-bottom: 0;
  cursor: none;
  font-weight: 400;
  pointer-events: none;
}
.authentication-container .authentication-tabs .tab:hover {
  background-color: #c6cad2;
}
.authentication-container .authentication-content {
  border: 2px solid #ededed;
  border-radius: 0 0 20px 20px;
  border-top: 0;
  margin-bottom: 24px;
  padding: 24px;
}
.authentication-container .authentication-content .sale-price-row {
  margin-bottom: 12px;
  text-align: center;
}
.authentication-container .authentication-content h1,
.authentication-container .authentication-content .description {
  text-align: center;
}
.authentication-container .authentication-content h1 {
  font-size: 3rem;
}
.authentication-container .authentication-content .description {
  margin-bottom: 24px;
}
.authentication-container .authentication-content .flash-message {
  margin-bottom: 12px;
}

@media (max-width: 599px) {
  .authentication-container .authentication-content h1 {
    font-size: 2.3rem;
  }
}
@media (min-width: 940px) {
  .authentication-container .authentication-tabs {
    max-width: 600px;
  }
  .authentication-container .authentication-tabs .tab {
    padding: 24px;
  }
  .authentication-container .authentication-content {
    border-radius: 0 0 60px 20px;
    margin: 0 auto 48px;
    max-width: 600px;
    padding: 24px 48px;
  }
}
#inquiry-form-box {
  margin: 24px 0;
}
#inquiry-form-box input[type=checkbox] + label {
  cursor: pointer;
  display: inline;
  line-height: 1.45;
}
#inquiry-form-box .radio {
  display: inline-block;
  margin-right: 8px;
}
#inquiry-form-box .inquiry-form .form-row.radio-driven-display {
  display: none;
}
#inquiry-form-box .inquiry-form.selected-lessee-type-company .form-row.display-for-company {
  display: block;
}
#inquiry-form-box .inquiry-form.selected-lessee-type-sole-proprietor .form-row.display-for-sole-proprietor {
  display: block;
}
#inquiry-form-box .inquiry-form .inner-addon {
  position: relative;
}
#inquiry-form-box .inquiry-form .inner-addon.left-addon input {
  padding-left: 6rem;
}
#inquiry-form-box .inquiry-form .inner-addon .phone-prefix {
  align-items: center;
  border-right: 1px solid #ededed;
  color: #c6ccd7;
  display: flex;
  font-weight: 700;
  height: 39px;
  padding: 0 0.8rem;
  position: absolute;
  top: 1px;
}
#inquiry-form-box .inquiry-form .inquiry-send-button {
  margin: 6px auto 0;
}
#inquiry-form-box .inquiry-form .inquiry-send-button:disabled {
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
}
#inquiry-form-box .inquiry-form .form-flex-row .form-row {
  margin-bottom: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 639px) {
  #inquiry-form-box .inquiry-form .form-flex-row .form-row:first-child {
    margin-bottom: 12px;
  }
}
#inquiry-form-box .inquiry-form .terms-agreement .age-execution-agreement {
  font-weight: 500;
}
#inquiry-form-box .inquiry-form .terms-agreement .tooltip {
  display: block;
  margin: 0 auto;
  width: fit-content;
}
#inquiry-form-box .inquiry-form .terms-agreement .tooltip::after {
  display: none;
}
#inquiry-form-box .inquiry-form .terms-agreement .tooltip .mobile-only {
  display: inline-block;
  margin-top: 4px;
  padding: 3px 0;
  text-decoration: underline;
}

@media (max-width: 599px) {
  #inquiry-form-box .inquiry-form .inquiry-send-button {
    width: 100%;
  }
  #inquiry-form-box .inquiry-form .phone-prefix {
    line-height: 1.7;
  }
}
@media (min-width: 640px) {
  #inquiry-form-box .inquiry-form .terms-agreement .tooltip.tooltip-open::before {
    bottom: 100%;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
    width: 275px;
  }
  #inquiry-form-box .inquiry-form .form-flex-row .form-row:nth-child(1) {
    width: 60%;
  }
  #inquiry-form-box .inquiry-form .form-flex-row .form-row:nth-child(2) {
    margin-left: 1%;
    width: 39%;
  }
}
@media (min-width: 940px) {
  #inquiry-form-box .inquiry-form .inner-addon .phone-prefix {
    height: 36px;
  }
  #inquiry-form-box .inquiry-form .terms-agreement .tooltip {
    display: inline-block;
    margin: 0;
    width: auto;
  }
  #inquiry-form-box .inquiry-form .terms-agreement .tooltip::after {
    display: inline-block;
  }
  #inquiry-form-box .inquiry-form .terms-agreement .tooltip.tooltip-open::before {
    margin-bottom: 6px;
    transform: translateX(-70%);
  }
  #inquiry-form-box .inquiry-form .terms-agreement .tooltip .mobile-only {
    display: none;
    margin-top: 0;
  }
}
.inquiry-flow-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  min-height: 48px;
  padding-top: 12px;
  position: relative;
}
.inquiry-flow-header .note {
  display: none;
  font-size: 85%;
  margin-left: auto;
  margin-right: 12px;
}
.inquiry-flow-header #how-it-works-modal-open {
  margin-left: auto;
  text-align: right;
}
.inquiry-flow-header .header-button.login-button {
  position: relative;
}
.inquiry-flow-header .header-button.login-button::before {
  background: url("/assets/icon/user.svg") 0 50% transparent no-repeat;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.4rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  vertical-align: middle;
  width: 1.4rem;
}
.inquiry-flow-header .header-button.back-button::before {
  background: url("/assets/icon/right-arrow-gray.svg") 0 50% transparent no-repeat;
  background-size: 85%;
  content: " ";
  display: inline-block;
  height: 1.4rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  transform: rotate(180deg);
  vertical-align: middle;
  width: 1.4rem;
}
.inquiry-flow-header .header-button .hidden-text-on-mobile {
  display: none;
}

@media (min-width: 640px) {
  .inquiry-flow-header {
    padding-top: 24px;
  }
  .inquiry-flow-header .note {
    display: inline-block;
    font-size: 14px;
  }
  .inquiry-flow-header .header-button .hidden-text-on-mobile {
    display: inline;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .inquiry-flow-header {
    margin-bottom: 24px;
  }
}
@media (min-width: 1921px) {
  .inquiry-flow-header {
    margin-bottom: 48px;
  }
}
.inquiry-left-column {
  background: #ededee;
  display: none;
  height: 100%;
  min-height: 100vh;
  padding: 30px 24px 24px;
}
.inquiry-left-column .left-column-content {
  margin: 0 auto;
  max-width: 350px;
}
.inquiry-left-column .favorite-left-column-content {
  margin: 0 auto;
  max-width: 400px;
}
.inquiry-left-column .logo-container {
  margin-bottom: 24px;
  text-align: center;
}
.inquiry-left-column .logo-container .logo {
  height: auto;
  max-width: 150px;
  width: 100%;
}
.inquiry-left-column .claim {
  text-align: center;
}
.inquiry-left-column .car-placeholder-image {
  margin-bottom: 48px;
  position: relative;
}
.inquiry-left-column .car-placeholder-image img {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 1;
}
.inquiry-left-column .car-placeholder-image .background-icon svg {
  bottom: 0;
  fill: #ff3f2d;
  height: 140%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 0;
}
.inquiry-left-column .car-placeholder-image .favorite-icon svg {
  bottom: 0;
  height: 55%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 2;
}
.inquiry-left-column .car-offer-info {
  border-radius: 20px;
  margin: 0 auto 24px;
  min-width: 270px;
  overflow: hidden;
}
.inquiry-left-column .car-offer-info .car-image {
  height: 170px;
  position: relative;
}
.inquiry-left-column .car-offer-info .car-image .img-inner {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.inquiry-left-column .car-offer-info .content {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 0 0 20px 20px;
  padding: 12px;
  text-align: center;
}
.inquiry-left-column .car-offer-info .subtitle {
  font-size: 14px;
  margin-bottom: 12px;
}
.inquiry-left-column .car-offer-info .price-info-row {
  padding: 12px 24px;
}
.inquiry-left-column .car-offer-info .price-info-row.other .value {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}
.inquiry-left-column .car-offer-info .price-info-row .price-title {
  color: #000000;
  margin-bottom: 6px;
}
.inquiry-left-column .car-offer-info .price-info-row .value:first-child {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}
.inquiry-left-column .car-offer-info .price-info-row .value .vat-info {
  color: #3c3f44;
  font-size: 12px;
  font-weight: 400;
  font-family: DMSans, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.inquiry-left-column .car-offer-info .special-offer-description {
  font-size: 85%;
  margin-top: 12px;
}
.inquiry-left-column .back-to-top {
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
  text-decoration: underline;
}
.inquiry-left-column .back-to-top::after {
  background: url("/assets/icon/right-arrow-white.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: "";
  display: inline-block;
  height: 1.3rem;
  margin-left: 6px;
  transform: rotate(-90deg);
  width: 1.3rem;
}

@media (max-width: 599px) {
  .inquiry-left-column .car-offer-info {
    margin-bottom: 24px;
  }
}
@media (min-width: 940px) {
  .inquiry-left-column {
    display: block;
  }
  .inquiry-left-column .car-offer-info .car-image {
    height: 200px;
  }
  .inquiry-left-column .car-offer-info .content {
    padding: 24px;
  }
  .inquiry-left-column .back-to-top {
    display: none;
  }
  .inquiry-left-column .car-placeholder-image {
    margin-bottom: 72px;
    margin-top: 72px;
  }
  .starting-fee-payments-container {
    margin-top: 24px;
    text-align: center;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .inquiry-left-column .logo-container {
    margin-bottom: 36px;
  }
  .inquiry-left-column .car-offer-info {
    margin-bottom: 24px;
  }
}
@media (min-width: 1921px) {
  .inquiry-left-column .logo-container {
    margin-bottom: 90px;
  }
}
.inquiry-page-content {
  margin: 0 auto 24px;
  max-width: 500px;
}
.inquiry-page-content .sale-price-row {
  margin-bottom: 12px;
  text-align: center;
}
.inquiry-page-content .list {
  font-size: 12px;
  list-style: none;
  list-style: none;
  margin: 0;
}
.inquiry-page-content .list li {
  display: flex;
  margin: 0 0 6px 2.8rem;
}
.inquiry-page-content .list li::before {
  background: url("/assets/icon/check-ok-green.svg") 20% 50% no-repeat transparent;
  background-size: contain;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  flex: none;
  height: 2.4rem;
  margin: 0 8px 0 -24px;
  vertical-align: middle;
  width: 2.4rem;
}
.inquiry-page-content #checkout-title {
  line-height: 1.2;
  text-align: center;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher {
  align-items: center;
  border-bottom: 2px solid #ededed;
  margin-bottom: 12px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher label.control-label {
  margin-right: 12px;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher label.required:not(.control-label) {
  cursor: pointer;
  padding: 12px 0 8px;
  position: relative;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher label.required:not(.control-label)::after {
  display: none;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher .radio:not(:last-of-type) {
  margin-right: 24px;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher input {
  display: none;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher input:checked + span {
  color: #ff3f2d;
  display: inline-block;
  font-weight: 700;
}
.inquiry-page-content #inquiry-form-box .lessee-type-switcher input:checked + span::after {
  background-color: #ff3f2d;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}
.inquiry-page-content #inquiry-form-box .terms-agreement {
  padding-top: 24px;
  text-align: center;
}
.inquiry-page-content #inquiry-form-box .terms-agreement .privacy-policy {
  font-size: 85%;
  font-style: italic;
  margin-top: 4px;
}
.inquiry-page-content #inquiry-form-box .show-more {
  display: block;
  margin: 0 auto;
}
.inquiry-page-content .conditions-info {
  font-size: 12px;
  text-align: center;
}

@media (min-width: 321px) {
  .inquiry-page-content .list {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 599px) {
  .inquiry-page-content #checkout-title {
    font-size: 2.3rem;
  }
}
@media (min-width: 640px) {
  .inquiry-page-content #checkout-title {
    font-size: 3rem;
  }
  .inquiry-page-content .list {
    font-size: 14px;
  }
  .inquiry-page-content #inquiry-form-box .lessee-type-switcher label.control-label {
    margin-right: 24px;
  }
  .inquiry-page-content #inquiry-form-box .lessee-type-switcher > div {
    border: 0;
    padding: 0;
  }
}
@media (min-width: 940px) {
  #inquiry-how-it-works-modal .modal-content {
    bottom: auto;
    max-height: 85vh;
  }
  .inquiry-page-content {
    margin: 0 auto 48px;
  }
  .inquiry-page-content .list {
    margin-left: -48px;
    margin-right: -48px;
  }
}
@media (min-width: 1367px) {
  .inquiry-page-content {
    max-width: 540px;
  }
}
@media (min-width: 1921px) {
  .inquiry-page-content {
    max-width: 570px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .inquiry-page-content #checkout-title {
    font-size: 2.5rem;
    margin-bottom: 12px;
  }
  .inquiry-page-content .form-row,
  .inquiry-page-content .form-group {
    margin-bottom: 6px;
  }
}
.inquiry-thank-you-page {
  padding-bottom: 24px;
  text-align: center;
}
.inquiry-thank-you-page h1,
.inquiry-thank-you-page h2 {
  text-align: center;
}
.inquiry-thank-you-page h1 {
  font-size: 3rem;
}
.inquiry-thank-you-page p {
  margin-top: 24px;
  text-align: center;
}
.inquiry-thank-you-page p > .email {
  font-weight: 700;
}
.inquiry-thank-you-page .check-ok-icon {
  height: 5rem;
  margin-bottom: 24px;
  width: 5rem;
}

.back-to-inquiry-offer {
  margin-top: 24px;
  text-align: center;
}

@media (max-width: 599px) {
  .inquiry-thank-you-page h1 {
    font-size: 2.3rem;
  }
}
@media (min-width: 940px) {
  .inquiry-thank-you-page {
    padding-bottom: 48px;
  }
}
@media (min-width: 1200px) {
  .inquiry-thank-you-page {
    margin: 0 auto;
    max-width: 600px;
  }
}
.subscription-landing-page-references {
  margin-bottom: 48px;
}
.subscription-landing-page-references.authentication {
  margin-bottom: 24px;
  margin-top: 48px;
}
.subscription-landing-page-references .title {
  color: #000000;
  font-size: 18px;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.subscription-landing-page-references .reference-box {
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 24px;
  text-align: center;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.subscription-landing-page-references .reference-box:hover {
  border-color: #c6cad2;
}
.subscription-landing-page-references .reference-box .text {
  color: #3c3f44;
}
.subscription-landing-page-references .reference-box:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.subscription-landing-page-references .reference-box:hover .logo {
  scale: 1.05;
}
.subscription-landing-page-references .reference-box .button-light {
  margin-top: 12px;
}
.subscription-landing-page-references .reference-box .logo {
  height: auto;
  margin-bottom: 24px;
  max-height: 35px;
  max-width: 230px;
  width: auto;
  transition: scale 0.3s ease;
}

@media (min-width: 940px) {
  .subscription-landing-page-references {
    margin-bottom: 72px;
  }
  .subscription-landing-page-references.authentication {
    margin-top: 72px;
  }
  .subscription-landing-page-references .embla .embla-container {
    flex-wrap: wrap;
  }
  .subscription-landing-page-references .embla .embla-container .embla-slide {
    flex: 0 0 32%;
    margin-bottom: 24px;
  }
  .subscription-landing-page-references .reference-box {
    margin-bottom: 24px;
  }
  .subscription-landing-page-references .section-title {
    text-align: center;
  }
}
.about-us {
  margin: 0 auto 48px;
}
.about-us.vision .box:nth-child(1) {
  margin-bottom: 48px;
}
.about-us.vision .label {
  background-color: #2dbd58;
  border-radius: 10px;
  color: #ffffff;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 12px;
  padding: 4px 12px;
  text-transform: uppercase;
}
.about-us.vision .text {
  font-weight: 700;
}
.about-us.bio {
  line-height: 1.85;
}
.about-us.career {
  background-color: #ededee;
  border-radius: 10px;
  margin: 0 auto;
  padding: 24px;
  width: fit-content;
}

@media (min-width: 550px) {
  .about-us {
    display: flex;
    flex-wrap: wrap;
  }
  .about-us .box {
    margin-left: 10%;
    width: 45%;
  }
  .about-us .box:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 940px) {
  .about-us {
    margin: 0 auto 72px;
    max-width: 940px;
  }
  .about-us.bio {
    font-size: 16px;
  }
  .about-us.vision .box:nth-child(1) {
    margin-bottom: 0;
  }
  .about-us.vision .text {
    font-size: 16px;
  }
}
#authentication-form {
  margin-bottom: 24px;
  position: relative;
  z-index: 15;
}
#authentication-form .facebook-button {
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
}
#authentication-form .facebook-button .social-icon {
  height: 2rem;
  margin-top: -0.5rem;
  width: 2rem;
}
#authentication-form .forgotten-password-link {
  color: #ff3f2d;
  display: block;
  margin: 12px 0;
  text-align: center;
}
#authentication-form #login-form,
#authentication-form #registration-form {
  margin: 0 auto;
  max-width: 350px;
}
#authentication-form #login-form .terms-agreement,
#authentication-form #registration-form .terms-agreement {
  margin-bottom: 24px;
  text-align: center;
}
#authentication-form .form-separator {
  margin: 18px 0;
  position: relative;
  text-align: center;
}
#authentication-form .form-separator::before {
  border-top: 1px solid #ededed;
  bottom: 0;
  content: " ";
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
}
#authentication-form .form-separator span {
  background-color: #ffffff;
  padding: 0 12px;
}
#authentication-form .login-error {
  margin-bottom: 12px;
}
#authentication-form .terms-agreement label,
#authentication-form .privacy-agreement label {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  line-height: 1.45;
}
#authentication-form .terms-agreement label::before,
#authentication-form .privacy-agreement label::before {
  margin-right: 8px;
  margin-top: 3px;
}
#authentication-form .terms-agreement .privacy-policy,
#authentication-form .privacy-agreement .privacy-policy {
  font-size: 85%;
  font-style: italic;
  margin-top: 4px;
}
#authentication-form button[type=submit] {
  margin-bottom: 12px;
  width: 100%;
}

@media (max-width: 480px) {
  #authentication-form .form-separator {
    margin: 12px 0;
  }
}
.company-contacts-container .title {
  font-size: 16px;
}
.company-contacts-container .subtitle {
  font-size: 14px;
}
.company-contacts-container .columns-container {
  margin-bottom: 48px;
}
.company-contacts-container .columns-container .image {
  border-radius: 10px;
  height: auto;
  width: 100%;
}
.company-contacts-container .maps-container .image:first-of-type {
  margin-bottom: 12px;
}
.company-contacts-container .company-contacts {
  margin-bottom: 48px;
}
.company-contacts-container .company-contacts .company-headquarters {
  font-size: 115%;
}
.company-contacts-container .company-contacts .contacts-container {
  margin-bottom: 24px;
}
.company-contacts-container .company-contacts .contacts-container .contact:first-child {
  margin-bottom: 24px;
}
.company-contacts-container .company-contacts .contacts-container .opening-hours {
  margin-bottom: 6px;
}
.company-contacts-container .company-contacts .contacts-container .phone,
.company-contacts-container .company-contacts .contacts-container .email {
  display: inline-block;
  margin-bottom: 6px;
  min-width: 150px;
}
.company-contacts-container .company-contacts .contacts-container .phone:not(:last-of-type),
.company-contacts-container .company-contacts .contacts-container .email:not(:last-of-type) {
  margin-right: 24px;
}
.company-contacts-container .company-contacts .contacts-container .phone::before,
.company-contacts-container .company-contacts .contacts-container .email::before {
  content: " ";
  display: inline-block;
  height: 2rem;
  margin-right: 8px;
  vertical-align: middle;
  width: 2rem;
}
.company-contacts-container .company-contacts .contacts-container .phone::before {
  background: url("/assets/icon/headset.svg") 50% 50% no-repeat transparent;
}
.company-contacts-container .company-contacts .contacts-container .email::before {
  background: url("/assets/icon/envelope.svg") 50% 50% no-repeat transparent;
}

@media (min-width: 640px) {
  .company-contacts-container .phone .company-contacts .contacts-container,
  .company-contacts-container .email .company-contacts .contacts-container {
    display: block;
  }
}
@media (min-width: 940px) {
  .company-contacts-container .columns-container {
    display: flex;
  }
  .company-contacts-container .columns-container .column {
    margin-left: 2%;
    width: 49%;
  }
  .company-contacts-container .columns-container .column:nth-child(2n+1) {
    margin-left: 0;
  }
  .company-contacts-container .maps-container .image:first-of-type {
    margin-bottom: 0;
  }
  .company-contacts-container .company-contacts {
    display: flex;
  }
  .company-contacts-container .company-contacts .contacts-container:first-of-type {
    border-right: 2px solid #ededed;
    width: 55%;
  }
  .company-contacts-container .company-contacts .contacts-container:nth-of-type(2) {
    margin-left: 5%;
    width: 40%;
  }
  .company-contacts-container .company-contacts .contacts-container .phone,
  .company-contacts-container .company-contacts .contacts-container .email {
    display: block;
  }
  .company-contacts-container .company-contacts .contacts-container .phone:not(:last-of-type),
  .company-contacts-container .company-contacts .contacts-container .email:not(:last-of-type) {
    margin-right: 0;
  }
}
.gdpr-cookies-policy-header p {
  margin-bottom: 0;
  margin-top: 24px;
}

.gdpr-cookies-policy-content h2 {
  color: #ff3f2d;
  font-size: 20px;
}
.gdpr-cookies-policy-content h2:not(:first-child) {
  margin-top: 24px;
}
.gdpr-cookies-policy-content h3 {
  font-size: 16px;
}
.gdpr-cookies-policy-content .button-primary {
  margin-bottom: 48px;
}
.gdpr-cookies-policy-content label {
  display: inline;
}
.gdpr-cookies-policy-content .table-container {
  overflow: auto;
  width: 100%;
}
.gdpr-cookies-policy-content .table-container .cookies-table {
  border: 1px solid #ededed;
  border-collapse: collapse;
  margin: 12px 0 24px;
  width: 100%;
}
.gdpr-cookies-policy-content .table-container .cookies-table th,
.gdpr-cookies-policy-content .table-container .cookies-table td {
  border: 1px solid #ededed;
  padding: 4px 8px;
}
.gdpr-cookies-policy-content .table-container .cookies-table th:last-of-type,
.gdpr-cookies-policy-content .table-container .cookies-table td:last-of-type {
  min-width: 300px;
}
.gdpr-cookies-policy-content .browsers {
  list-style: square inside;
}
.gdpr-cookies-policy-content .browsers li {
  margin-bottom: 0;
}
.gdpr-cookies-policy-content .gdpr-cookies-policy-options {
  list-style: none outside;
  margin-bottom: 0;
  margin-top: 12px;
}
.gdpr-cookies-policy-content .gdpr-cookies-policy-options li label {
  font-weight: 700 !important;
  line-height: 1.45;
}

.price-offers-comparator {
  margin-bottom: 48px;
}
.price-offers-comparator .sticky-table-header {
  background-color: #ededee;
  border-bottom: 3px solid #ededed;
  display: block;
  padding-left: 120px;
  position: fixed;
  top: 0;
  transform: translateY(-110%);
  transition: transform 0.3s ease;
  width: 100%;
  will-change: transform;
  z-index: 598;
}
.price-offers-comparator .sticky-table-header > .inner {
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  width: inherit;
}
.price-offers-comparator .sticky-table-header.show {
  transform: translateY(0);
}
.price-offers-comparator .sticky-table-header .column {
  border-left: 1px solid #ededed;
  flex: none;
  padding: 8px 12px;
  text-align: center;
  width: 200px;
}
.price-offers-comparator .sticky-table-header .column:last-child {
  border-right: 1px solid #ededed;
}
.price-offers-comparator .sticky-table-header .column .image {
  border-radius: 10px;
  display: none;
  height: 50px;
  margin: 0 auto 6px;
  object-fit: cover;
  object-position: center center;
  width: 100px;
}
.price-offers-comparator .sticky-table-header .column .price {
  font-weight: 500;
}
.price-offers-comparator .table-container {
  margin-left: 120px;
  margin-top: 12px;
  overflow-x: auto;
}
.price-offers-comparator .table-container .labels {
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}
.price-offers-comparator .comparator-table-container {
  background-color: #ededee;
  padding-bottom: 54px;
  position: relative;
  width: 100%;
}
.price-offers-comparator .comparator-table-container + .no-content {
  display: none;
}
.price-offers-comparator .comparator-table-container .car-offer-info-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.price-offers-comparator .comparator-table-container .car-image {
  display: block;
  height: 115px;
  margin: 12px auto;
  max-width: 230px;
  position: relative;
  width: 100%;
}
.price-offers-comparator .comparator-table-container .img-inner {
  border-radius: 10px;
}
.price-offers-comparator .comparator-table-container .price-offer-price {
  margin-bottom: 12px;
}
.price-offers-comparator .comparator-table-container .button-light {
  margin-bottom: 12px;
}
.price-offers-comparator .comparator-table-container .price-vat .price:first-of-type {
  color: #000000;
  display: block;
}
.price-offers-comparator .comparator-table-container .price-vat .price:nth-of-type(2) {
  font-weight: 400;
}
.price-offers-comparator .comparator-table-container .car-offer-title {
  flex-grow: 1;
  margin-bottom: 12px;
}
.price-offers-comparator .comparator-table-container .subtitle {
  font-weight: 400;
}
.price-offers-comparator .comparator-table-container .yes-label {
  color: #2dbd58;
  text-transform: uppercase;
}
.price-offers-comparator .comparator-table-container .no-label {
  color: #ec5340;
  text-transform: uppercase;
}
.price-offers-comparator .comparator-table-container .comparator-remove-all-offers,
.price-offers-comparator .comparator-table-container .remove-offer {
  color: #ff3f2d;
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;
}
.price-offers-comparator .comparator-table-container .comparator-remove-all-offers::before,
.price-offers-comparator .comparator-table-container .remove-offer::before {
  background: url("/assets/icon/cross-no.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.5rem;
  margin-right: 4px;
  text-decoration: none;
  vertical-align: middle;
  width: 1.5rem;
}
.price-offers-comparator .comparator-table-container .car-offer-equipment-list {
  text-align: left;
  list-style: none;
  margin: 0;
}
.price-offers-comparator .comparator-table-container .car-offer-equipment-list li {
  margin: 0 0 1rem 2.8rem;
}
.price-offers-comparator .comparator-table-container .car-offer-equipment-list li::before {
  color: transparent;
  display: inline-block;
  height: 1.5rem;
  vertical-align: middle;
  width: 1.5rem;
  background: url("/assets/icon/check-ok.svg") 0 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  margin: -0.4rem 0.8rem -0.2rem -2.4rem;
}
.price-offers-comparator .comparator-table-container table {
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  border-left: 0;
  min-width: 400px;
  table-layout: fixed;
  width: 100%;
}
.price-offers-comparator .comparator-table-container table .head-col {
  font-weight: 700;
  text-align: right;
}
.price-offers-comparator .comparator-table-container table .equipment-head-col {
  align-items: center;
  display: flex;
  font-size: 85%;
  height: 44px;
  justify-content: flex-end;
  line-height: 1.3;
  padding: 8px 12px;
  text-align: right;
}
.price-offers-comparator .comparator-table-container th {
  background-color: #ffffff;
  font-weight: 700;
}
.price-offers-comparator .comparator-table-container th.headline {
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-size: 20px;
}
@media (min-width: 640px) {
  .price-offers-comparator .comparator-table-container th.headline {
    font-size: 24px;
  }
}
.price-offers-comparator .comparator-table-container th.headline .comparator-remove-all-offers {
  font-size: 14px;
  margin-bottom: 24px;
}
.price-offers-comparator .comparator-table-container td {
  position: relative;
}
.price-offers-comparator .comparator-table-container td,
.price-offers-comparator .comparator-table-container th {
  display: table-cell;
  padding: 12px;
  vertical-align: top;
  width: 200px;
}
.price-offers-comparator .comparator-table-container td + td,
.price-offers-comparator .comparator-table-container th + th {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  display: table-cell;
  height: 44px;
  text-align: center;
  width: 200px;
}
.price-offers-comparator .comparator-table-container th + th {
  border-top: 1px solid #ededed;
  padding: 12px;
  vertical-align: bottom;
}
.price-offers-comparator .comparator-table-container .separator td {
  background-color: #ffffff;
  border: 0;
  white-space: nowrap;
}
.price-offers-comparator .comparator-table-container .separator td::before {
  border-top: 1px solid #ededed;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
}
.price-offers-comparator .comparator-table-container .separator td::after {
  border-bottom: 1px solid #ededed;
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
}
.price-offers-comparator .comparator-table-container tr {
  background-color: #ffffff;
}
.price-offers-comparator .comparator-table-container tr:nth-child(even) {
  background-color: #ededee;
}
.price-offers-comparator .comparator-table-container tr td:first-of-type,
.price-offers-comparator .comparator-table-container tr th:first-of-type {
  left: 0;
  min-height: 44px;
  position: absolute;
  width: 120px;
  z-index: 2;
}
.price-offers-comparator .comparator-table-container .autospace {
  margin: 0;
  padding: 0 12px;
  vertical-align: middle;
  width: 100%;
}
.price-offers-comparator .arrow-navigation {
  bottom: 6px;
  display: flex;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  z-index: 610;
}
.price-offers-comparator .arrow-navigation:not(.fixed) {
  position: absolute;
}
.price-offers-comparator .arrow-navigation .nav {
  align-items: center;
  background-color: rgba(67, 76, 95, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: opacity 0.3s, transform 0.3s;
  vertical-align: middle;
  width: 40px;
  will-change: opacity, transform;
}
.price-offers-comparator .arrow-navigation .nav::after {
  background: url("/assets/icon/right-arrow-white.svg") 50% 50% no-repeat transparent;
  background-size: 100%;
  content: " ";
  display: inline-block;
  height: 1.3rem;
  width: 1.3rem;
}
.price-offers-comparator .arrow-navigation .nav.disable {
  opacity: 0.3;
  pointer-events: none;
}
.price-offers-comparator .arrow-navigation .right {
  margin-left: 12px;
}
.price-offers-comparator .arrow-navigation .top {
  margin-left: 12px;
  transform: rotate(-90deg);
}
.price-offers-comparator .arrow-navigation .top.disable {
  opacity: 0;
}
.price-offers-comparator .arrow-navigation .left {
  transform: rotate(180deg);
}
.price-offers-comparator .comparator-more-offers .listing-link {
  display: block;
  margin: 0 auto 12px;
  text-decoration: none;
  width: fit-content;
}
.price-offers-comparator .comparator-more-offers .listing-link::before {
  -webkit-mask-image: url("/assets/icon/plus.svg");
  background-color: #ff3f2d;
  content: " ";
  display: inline-block;
  height: 24px;
  mask-image: url("/assets/icon/plus.svg");
  transform: scale(1.5);
  width: 24px;
  transition: transform 0.3s ease;
  will-change: transform;
}

@media (min-width: 375px) {
  .price-offers-comparator .sticky-table-header {
    padding-left: 150px;
  }
  .price-offers-comparator .table-container {
    margin-left: 150px;
  }
  .price-offers-comparator .comparator-table-container th.headline {
    font-size: 20px;
  }
}
@media (min-width: 375px) and (min-width: 640px) {
  .price-offers-comparator .comparator-table-container th.headline {
    font-size: 24px;
  }
}
@media (min-width: 375px) {
  .price-offers-comparator .comparator-table-container tr td:first-of-type,
  .price-offers-comparator .comparator-table-container tr th:first-of-type {
    width: 150px;
  }
}
@media (min-width: 640px) {
  .price-offers-comparator .sticky-table-header {
    padding-left: 200px;
  }
  .price-offers-comparator .sticky-table-header .column {
    width: 230px;
  }
  .price-offers-comparator .sticky-table-header .column .image {
    display: block;
  }
  .price-offers-comparator .table-container {
    margin-left: 200px;
  }
  .price-offers-comparator .comparator-table-container td + td,
  .price-offers-comparator .comparator-table-container th + th {
    width: 230px;
  }
  .price-offers-comparator .comparator-table-container tr td:first-of-type,
  .price-offers-comparator .comparator-table-container tr th:first-of-type {
    width: 200px;
  }
}
@media (min-width: 940px) {
  .price-offers-comparator {
    margin-bottom: 72px;
  }
  .price-offers-comparator .sticky-table-header {
    padding-left: 230px;
  }
  .price-offers-comparator .table-container {
    margin-left: 230px;
    margin-top: 0;
  }
  .price-offers-comparator .table-container.mouse-drag {
    cursor: move;
  }
  .price-offers-comparator .table-container.active {
    user-select: none;
  }
  .price-offers-comparator .comparator-table-container {
    padding-bottom: 84px;
  }
  .price-offers-comparator .comparator-table-container tr td:first-of-type,
  .price-offers-comparator .comparator-table-container tr th:first-of-type {
    width: 230px;
  }
  .price-offers-comparator .arrow-navigation {
    bottom: 18px;
  }
  .price-offers-comparator .arrow-navigation.fixed {
    bottom: 24px;
  }
  .price-offers-comparator .arrow-navigation .nav {
    height: 50px;
    width: 50px;
  }
  .price-offers-comparator .arrow-navigation .nav::after {
    height: 1.5rem;
    width: 1.5rem;
  }
  .price-offers-comparator .arrow-navigation .right:hover {
    transform: scale(1.2);
  }
  .price-offers-comparator .arrow-navigation .top:hover {
    transform: scale(1.2) rotate(-90deg);
  }
  .price-offers-comparator .arrow-navigation .left:hover {
    transform: scale(1.2) rotate(180deg);
  }
}
.registration-finished-header,
.confirmation-finished-header,
.add-email-header {
  margin-top: 24px;
}
.registration-finished-header .headline,
.confirmation-finished-header .headline,
.add-email-header .headline {
  font-size: 24px;
}
@media (min-width: 640px) {
  .registration-finished-header .headline,
  .confirmation-finished-header .headline,
  .add-email-header .headline {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.registration-finished-header .description,
.confirmation-finished-header .description,
.add-email-header .description {
  font-size: 115%;
}

.registration-finished .registration-benefits,
.confirmation-finished .registration-benefits,
.add-email-container .registration-benefits,
.password-reset-container .registration-benefits {
  list-style: none;
  margin: 0;
  margin-bottom: 24px;
}
.registration-finished .registration-benefits li,
.confirmation-finished .registration-benefits li,
.add-email-container .registration-benefits li,
.password-reset-container .registration-benefits li {
  display: flex;
  margin: 0 0 6px 2.8rem;
}
.registration-finished .registration-benefits li::before,
.confirmation-finished .registration-benefits li::before,
.add-email-container .registration-benefits li::before,
.password-reset-container .registration-benefits li::before {
  background: url("/assets/icon/check-ok-green.svg") 20% 50% no-repeat transparent;
  background-size: contain;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  flex: none;
  height: 2.4rem;
  margin: 0 8px 0 -24px;
  vertical-align: middle;
  width: 2.4rem;
}

.registration-finished .headline,
.confirmation-failed .headline,
.confirmation-finished .headline {
  font-size: 20px;
}
@media (min-width: 640px) {
  .registration-finished .headline,
  .confirmation-failed .headline,
  .confirmation-finished .headline {
    font-size: 24px;
  }
}
.registration-finished .button-primary,
.confirmation-failed .button-primary,
.confirmation-finished .button-primary {
  margin-top: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  width: fit-content;
}
.registration-finished .button-primary::after,
.confirmation-failed .button-primary::after,
.confirmation-finished .button-primary::after {
  background: 50% 50% no-repeat transparent;
  background-size: 1.3rem auto;
  background-image: url("/assets/icon/right-arrow-white.svg");
  content: " ";
  display: inline-block;
  height: 1.3rem;
  margin-left: 12px;
  width: 1.3rem;
}

@media (min-width: 940px) {
  .add-email-container form,
  .password-reset-container form {
    max-width: 50%;
  }
}

@media (min-width: 940px) {
  .registration-finished-header,
  .confirmation-finished-header,
  .add-email-header {
    margin-top: 48px;
  }
}
.terms-and-conditions {
  position: relative;
}
.terms-and-conditions .valid-title {
  border-bottom: 1px solid #ededed;
  margin-bottom: 48px;
  padding-bottom: 12px;
}
.terms-and-conditions__section {
  margin-left: 24px;
}
.terms-and-conditions__section:first-of-type {
  margin-bottom: 120px;
}
.terms-and-conditions .car-rent-terms-conditions {
  margin-top: 48px;
}
.terms-and-conditions .terms-list {
  list-style: none;
}
.terms-and-conditions .company-identification {
  margin-bottom: 24px;
}
.terms-and-conditions .company-identification .company-name {
  color: #ff3f2d;
}
.terms-and-conditions .terms-section-heading {
  color: #ff3f2d;
  font-size: 135%;
  margin-bottom: 12px;
}
.terms-and-conditions .terms-section:not(:last-of-type) {
  margin-bottom: 24px;
}
.terms-and-conditions .terms-section:not(:last-of-type) ul {
  list-style: outside square;
}
.terms-and-conditions ol.terms-section {
  counter-increment: section-counter;
  list-style-type: none;
}
.terms-and-conditions ol.terms-section > li {
  counter-increment: step-counter;
  margin-left: 36px;
}
.terms-and-conditions ol.terms-section > li::before {
  content: counters(section-counter, ".") "." counters(step-counter, ".") ".";
  font-weight: 700;
  left: 0;
  position: absolute;
}
.terms-and-conditions ol.alphabetically-ordered-list {
  list-style: lower-alpha outside;
}

.password-reset-link-header {
  margin-top: 24px;
}
.password-reset-link-header .headline {
  font-size: 24px;
}
@media (min-width: 640px) {
  .password-reset-link-header .headline {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.password-reset-link-header .description {
  font-size: 115%;
}

.password-reset-link-sent .button-primary {
  margin-top: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  width: fit-content;
}
.password-reset-link-sent .button-primary::after {
  background: 50% 50% no-repeat transparent;
  background-size: 1.3rem auto;
  background-image: url("/assets/icon/right-arrow-white.svg");
  content: " ";
  display: inline-block;
  height: 1.3rem;
  margin-left: 12px;
  width: 1.3rem;
}

@media (min-width: 940px) {
  .password-reset-link-header {
    margin-top: 48px;
  }
  #reset-password-form .form-control {
    max-width: 400px;
  }
}
.most-frequent-questions {
  background-color: #ffffff;
  counter-reset: boxes-counter;
}
.most-frequent-questions.dashboard {
  margin-bottom: 48px;
}
.most-frequent-questions .show-all {
  margin-top: 24px;
  text-align: center;
}
.most-frequent-questions .question-container {
  border-radius: 10px;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #ededed;
  transition: border-color 0.3s ease;
}
.most-frequent-questions .question-container:hover {
  border-color: #c6cad2;
}
.most-frequent-questions .question-container:not(:last-of-type) {
  margin-bottom: 12px;
}
.most-frequent-questions .question-container .content {
  width: 100%;
}
.most-frequent-questions .question-container .content h5 {
  margin-bottom: 0;
  width: 70%;
}
.most-frequent-questions .question-container .content .question {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 12px;
  width: 100%;
}
.most-frequent-questions .question-container .content .question .title {
  font-size: 16px;
  font-weight: 700;
}
.most-frequent-questions .question-container .content .answer {
  counter-reset: boxes-counter;
  display: none;
  padding: 0 12px 12px;
}
.most-frequent-questions .question-container .content .answer h6 {
  font-size: 16px;
}
.most-frequent-questions .question-container .content .answer h6::before {
  content: counter(boxes-counter) ".";
  counter-increment: boxes-counter;
  display: inline-block;
  margin-right: 6px;
}
.most-frequent-questions .question-container .show-answer {
  height: 15px;
  min-width: 15px;
  position: relative;
  width: 15px;
}
.most-frequent-questions .question-container .show-answer::before, .most-frequent-questions .question-container .show-answer::after {
  background-color: #ff3f2d;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: 50%;
  transition: 0.3s;
  width: 100%;
}
.most-frequent-questions .question-container .show-answer::before {
  transform: translatey(-50%);
}
.most-frequent-questions .question-container .show-answer::after {
  transform: translatey(-50%) rotate(90deg);
}
.most-frequent-questions .question-container.active .show-answer::before {
  opacity: 0;
  transform: translatey(-50%) rotate(-90deg);
}
.most-frequent-questions .question-container.active .show-answer::after {
  transform: translatey(-50%) rotate(0);
}

@media (min-width: 640px) {
  .most-frequent-questions .question-container .show-answer {
    flex: inherit;
    margin-left: 24px;
    white-space: nowrap;
  }
  .most-frequent-questions .question-container .content .question {
    min-height: 80px;
    padding: 24px;
  }
  .most-frequent-questions .question-container .content .answer {
    padding: 0 24px 24px;
  }
  .most-frequent-questions .question-container::before {
    align-items: flex-start;
    border-right: 1px solid #ededed;
    color: #dee0e2;
    content: counter(boxes-counter);
    counter-increment: boxes-counter;
    display: flex;
    font-size: 3rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    padding: 24px 12px;
    text-align: center;
    width: 60px;
  }
  .most-frequent-questions .question-container.active .show-answer::before {
    line-height: 0.6;
  }
}
@media (min-width: 940px) {
  .landing-page-most-frequent-questions {
    padding: 72px 0;
  }
  .most-frequent-questions.dashboard {
    margin-bottom: 72px;
  }
  .most-frequent-questions .show-all {
    margin-top: 48px;
  }
  .most-frequent-questions .container {
    max-width: 800px;
  }
  .most-frequent-questions .question-container {
    display: flex;
  }
  .most-frequent-questions .question-container .show-answer {
    height: 25px;
    min-width: 25px;
    width: 25px;
  }
  .most-frequent-questions .question-container::before {
    padding: 24px;
    width: 80px;
  }
}
.tyre-services-page-content .search-input {
  margin-bottom: 24px;
}
.tyre-services-page-content .table-container {
  overflow: auto;
  width: 100%;
}

.subscription-faq-container {
  margin-bottom: 24px;
}
.subscription-faq-container #subscription-faq-tabs .tabs-navigation {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item {
  display: inherit;
  margin-right: 8px;
}
.subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item .tab-item-link {
  background-color: #ededee;
  border-radius: 24px;
  color: #3c3f44;
  font-weight: 700;
  line-height: 1.2;
  padding: 12px 18px;
  text-decoration: none;
}
.subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item .icon {
  display: inline-block;
  height: 1.7rem;
  margin-right: 6px;
  margin-top: -0.3rem;
  width: 1.7rem;
}
.subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item.active .tab-item-link {
  background-color: #ededee;
  color: #ff3f2d;
}
.subscription-faq-container #subscription-faq-tabs .tabs-content .tab-content:not(.active) {
  display: none;
}

@media (min-width: 940px) {
  .subscription-faq-container {
    margin-bottom: 72px;
  }
  .subscription-faq-container #subscription-faq-tabs {
    display: flex;
    width: 100%;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-navigation {
    flex-flow: nowrap column;
    list-style: none;
    margin-right: 3%;
    position: relative;
    width: 30%;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item {
    margin-bottom: 0;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item .tab-item-link {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    display: flex;
    font-size: 16px;
    line-height: 1.2;
    padding: 18px 24px;
    text-decoration: none;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item .tab-item-link:hover {
    background-color: #ededee;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item.active .tab-item-link::after {
    margin-left: auto;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-navigation .tab-item .icon {
    fill: #ff3f2d;
    margin-right: 24px;
  }
  .subscription-faq-container #subscription-faq-tabs .tabs-content {
    width: 67%;
  }
}
#document-preview img {
  max-height: 50vh;
  max-width: 50vw;
}

.document-upload-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}