@mixin video-play-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    min-height: 100px;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;

    &::before {
        background: url("/assets/icon/video-play.svg") 55% 50% no-repeat $brandColor;
        background-size: 35%;
        border-radius: 50%;
        content: " ";
        height: 5rem;
        left: 50%;
        opacity: 0.9;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity $defaultTransitionTime ease, transform $defaultTransitionTime ease;
        width: 5rem;
        will-change: opacity, transform;
        z-index: 2;

        @include desktop {
            height: 7rem;
            width: 7rem;
        }
    }

    &::after {
        background-color: $videoBackgroundColor;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include own-transition(background-color);
    }

    &:hover {
        &::before {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.1);
        }

        &::after {
            background-color: $videoBackgroundHoverColor;
        }
    }
}
