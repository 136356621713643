@use "sass:math";

.social-share {
    position: relative;

    &::before {
        background: url("/assets/icon/share-icon.svg") 50% 50% no-repeat transparent;
        background-size: 100%;
        content: " ";
        display: inline-block;
        height: 2rem;
        width: 2rem;
    }

    .social-share-popup {
        padding: $defaultMargin;
    }

    .social-share-title {
        color: $defaultTextColor;
        display: inline-block;
        margin-bottom: $defaultMargin * 0.25;
    }

    .form-flex {
        margin-bottom: $defaultMargin;

        input,
        .button {
            font-size: $defaultFontSize;
            width: 100%;
        }
    }

    .social-share-links {
        @include flex-grid;

        .social-share-link {
            @include boxes-per-row(2);

            .share-link {
                display: inline-block;
            }
        }

        .social-icon {
            fill: $inverseTextColor;
            height: 3rem;
            margin-right: $defaultMargin * 0.5;
            padding: math.div($defaultMargin, 6);
            width: 3rem;

            &.facebook {
                background-color: $facebookColor;
            }

            &.twitter {
                background-color: $twitterColor;
            }
        }
    }

    label {
        &.open-popup-label {
            align-items: center;
            background-color: $defaultBackgroundColor;
            color: $brandColor;
            cursor: pointer;
            display: flex;
            line-height: 1;
            text-decoration: underline;
        }
    }

    .close-popup {
        margin-bottom: $defaultMargin;
    }
}

@include mobile-tablet {
    .social-share {
        .social-share-popup {
            @include popup-slide-from-left;
        }

        .open-popup-checkbox {
            &:checked + .social-share-popup {
                left: 0;
            }
        }
    }
}

@media (max-width: 759px) {
    .social-share {
        align-items: center;
        display: flex;
    }
}

@include tablet-desktop {
    .social-share {
        .open-popup-label {
            span {
                margin-left: math.div($defaultMargin, 6);
            }
        }

        .form-flex {
            display: flex;

            input {
                border-right: 0;
                width: 70%;
            }

            .button {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                letter-spacing: 0;
                padding: $defaultMargin * 0.25 $defaultMargin * 0.5;
                width: 35%;
            }
        }
    }
}

@include desktop {
    .social-share {
        .social-share-popup {
            cursor: auto;
            display: none;
            margin-top: $defaultMargin * 0.5;
            position: absolute;
            right: 0;
            top: 100%;
            width: 380px;
            z-index: $headerZIndex - 1;
            @include fade-in-from-none;
            @include own-box-shadow(2px, 5px);
            @include box-border(1px);

            &::after {
                border-bottom: ($boxTriangleArrowSize - 2) solid $defaultBackgroundColor;
                border-left: ($boxTriangleArrowSize - 2) solid transparent;
                border-right: ($boxTriangleArrowSize - 2) solid transparent;
                bottom: 100%;
                content: "";
                position: absolute;
                right: 42px;
            }

            &::before {
                border-bottom: $boxTriangleArrowSize solid $borderLightColor;
                border-left: $boxTriangleArrowSize solid transparent;
                border-right: $boxTriangleArrowSize solid transparent;
                bottom: 100%;
                content: "";
                position: absolute;
                right: 40px;
            }
        }

        .open-popup-checkbox {
            &:checked + .social-share-popup {
                display: block;
            }
        }

        .close-popup {
            display: none;
        }
    }
}
