@use "sass:math";

#authentication-form {
    margin-bottom: $defaultMargin;
    position: relative;
    z-index: 15;

    .facebook-button {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;

        .social-icon {
            height: 2rem;
            margin-top: -0.5rem;
            width: 2rem;
        }
    }

    .forgotten-password-link {
        color: $brandColor;
        display: block;
        margin: $defaultMargin * 0.5 0;
        text-align: center;
    }

    #login-form,
    #registration-form {
        margin: 0 auto;
        max-width: 350px;

        .terms-agreement {
            margin-bottom: $defaultMargin;
            text-align: center;
        }
    }

    .form-separator {
        margin: $defaultMargin * 0.75 0;
        position: relative;
        text-align: center;

        &::before {
            border-top: 1px solid $borderLightColor;
            bottom: 0;
            content: " ";
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: 50%;
            z-index: -1;
        }

        span {
            background-color: $defaultBackgroundColor;
            padding: 0 $defaultMargin * 0.5;
        }
    }

    .login-error {
        margin-bottom: $defaultMargin * 0.5;
    }

    .terms-agreement,
    .privacy-agreement {
        label {
            align-items: flex-start;
            cursor: pointer;
            display: flex;
            line-height: $defaultLineHeight;

            &::before {
                margin-right: math.div($defaultMargin, 3);
                margin-top: math.div($defaultMargin, 8);
            }
        }

        .privacy-policy {
            font-size: $smallFontSize;
            font-style: italic;
            margin-top: math.div($defaultMargin, 6);
        }
    }

    button {
        &[type="submit"] {
            margin-bottom: $defaultMargin * 0.5;
            width: 100%;
        }
    }
}

@media (max-width: 480px) {
    #authentication-form {
        .form-separator {
            margin: $defaultMargin * 0.5 0;
        }
    }
}
