.car-description {
    &.detail {
        margin-bottom: $defaultMargin;
    }

    @include desktop {
        font-size: $slightlyBiggerFontSize;

        &.detail {
            margin-bottom: $smallScreenMargin;
        }
    }
}
