.inquiry-thank-you-page {
    padding-bottom: $defaultMargin;
    text-align: center;

    h1,
    h2 {
        text-align: center;
    }

    h1 {
        font-size: $inquiryHeadlineFontSize;
    }

    p {
        margin-top: $defaultMargin;
        text-align: center;

        & > .email {
            font-weight: $boldFontWeight;
        }
    }

    .check-ok-icon {
        height: 5rem;
        margin-bottom: $defaultMargin;
        width: 5rem;
    }
}

.back-to-inquiry-offer {
    margin-top: $defaultMargin;
    text-align: center;
}


@media (max-width: 599px) {
    .inquiry-thank-you-page {
        h1 {
            font-size: $inquiryHeadlineSmallerFontSize;
        }
    }
}

@include desktop {
    .inquiry-thank-you-page {
        padding-bottom: $smallScreenMargin;
    }
}

@include desktop-large {
    .inquiry-thank-you-page {
        margin: 0 auto;
        max-width: 600px;
    }
}
