@use "sass:math";

#inquiry-form-box {
    margin: $defaultMargin 0;

    input {
        &[type="checkbox"] + label {
            cursor: pointer;
            display: inline;
            line-height: $defaultLineHeight;
        }
    }

    .radio {
        display: inline-block;
        margin-right: math.div($defaultMargin, 3);
    }

    .inquiry-form {
        .form-row {
            &.radio-driven-display {
                display: none;
            }
        }

        &.selected-lessee-type-company {
            .form-row {
                &.display-for-company {
                    display: block;
                }
            }
        }

        &.selected-lessee-type-sole-proprietor {
            .form-row {
                &.display-for-sole-proprietor {
                    display: block;
                }
            }
        }

        .inner-addon {
            position: relative;

            &.left-addon {
                input {
                    padding-left: 6rem;
                }
            }

            .phone-prefix {
                align-items: center;
                border-right: 1px solid $formInputBorderColor;
                color: $formPlaceholderTextColor;
                display: flex;
                font-weight: $boldFontWeight;
                height: 39px;
                padding: 0 0.8rem;
                position: absolute;
                top: 1px;
            }
        }

        .inquiry-send-button {
            margin: $defaultMargin * 0.25 auto 0;

            &:disabled {
                cursor: auto;
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .form-flex-row {
            .form-row {
                margin-bottom: 0;
                position: relative;
                width: 100%;

                @include mobile {
                    &:first-child {
                        margin-bottom: $defaultMargin * 0.5;
                    }
                }
            }
        }

        .terms-agreement {
            .age-execution-agreement {
                font-weight: $mediumFontWeight;
            }

            .tooltip {
                display: block;
                margin: 0 auto;
                width: fit-content;

                &::after {
                    display: none;
                }

                .mobile-only {
                    display: inline-block;
                    margin-top: math.div($defaultMargin, 6);
                    padding: $defaultMargin * 0.125 0;
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (max-width: 599px) {
    #inquiry-form-box {
        .inquiry-form {
            .inquiry-send-button {
                width: 100%;
            }

            .phone-prefix {
                line-height: 1.7;
            }
        }
    }
}

@include tablet-desktop {
    #inquiry-form-box {
        .inquiry-form {
            .terms-agreement {
                .tooltip {
                    &.tooltip-open {
                        &::before {
                            bottom: 100%;
                            left: 50%;
                            top: auto;
                            transform: translateX(-50%);
                            width: 275px;
                        }
                    }
                }
            }

            .form-flex-row {
                .form-row {
                    &:nth-child(1) {
                        width: 60%;
                    }

                    &:nth-child(2) {
                        margin-left: 1%;
                        width: 39%;
                    }
                }
            }
        }
    }
}

@include desktop {
    #inquiry-form-box {
        .inquiry-form {
            .inner-addon {
                .phone-prefix {
                    height: 36px;
                }
            }

            .terms-agreement {
                .tooltip {
                    display: inline-block;
                    margin: 0;
                    width: auto;

                    &::after {
                        display: inline-block;
                    }

                    &.tooltip-open {
                        &::before {
                            margin-bottom: $defaultMargin * 0.25;
                            transform: translateX(-70%);
                        }
                    }

                    .mobile-only {
                        display: none;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
